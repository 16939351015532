<template>
  <div v-if="module.accessible" class="module" @click.stop="navigateToModule">
    <v-icon color="primary">
      {{ module.icon }}
    </v-icon>
    <div class="module-name">
      {{ module.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModuleTile',
  props: {
    module: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  methods: {
    navigateToModule() {
      if (this.module.accessible) {
        this.$emit('moduleClicked');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.module {
  height: 50px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 200ms ease;

  &:hover {
    background: #f2f2f2;
  }

  .module-name {
    font-size: 12px;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.7);
  }
}
</style>
