import httpClient from '../httpClient';

const getProjectWorkflows = (projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/workflows`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createWorkflowProject = (projectId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/project/${projectId}/workflow`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateProjectWorkflowName = (projectId, workflowId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/project/${projectId}/workflow/${workflowId}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteProjectWorkflow = (projectId, projectWorkflowId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/project/${projectId}/workflow/${projectWorkflowId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getProjectWorkflow = (projectId, projectWorkflowId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/workflow/${projectWorkflowId}`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getWorkflowsInLicense = (projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/workflows/inLicense`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getProjectWorkflowPermissions = (projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/workflows/permissions`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export {
  getProjectWorkflows,
  getWorkflowsInLicense,
  createWorkflowProject,
  updateProjectWorkflowName,
  deleteProjectWorkflow,
  getProjectWorkflow,
  getProjectWorkflowPermissions,
};
