<template>
  <div :class="{ 'message-wrapper--unread': isUnread }" class="message-wrapper">
    <div class="unread-dot" @click="markRead" />
    <div class="message d-flex justify-space-between ant-border-bottom">
      <div class="d-flex flex-column mr-4">
        <div class="message-text">
          <component
            :is="renderer"
            v-if="renderer"
            :data="data.data"
            @action="markRead"
          />
          <span v-else>Text of notification.</span>
        </div>
        <div class="fs-12">
          <span>{{ formatDate }}</span>
        </div>
      </div>
      <div class="d-flex justify-center align-center">
        <div class="icon-wrapper">
          <v-icon color="white" small>{{ notificationIcon }}</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import TaskWasAssigned from '@/components/Notifications/NotificationMessages/TaskWasAssigned';
import TaskWasReAssigned from '@/components/Notifications/NotificationMessages/TaskWasReAssigned';
import TaskUpdated from '@/components/Notifications/NotificationMessages/TaskUpdated';
import TaskNewComment from '@/components/Notifications/NotificationMessages/TaskNewComment';
import DownloadReady from '@/components/Notifications/NotificationMessages/DownloadReady';
import DefaultMessage from '@/components/Notifications/NotificationMessages/DefaultMessage.vue';

export default {
  name: 'NotificationItem',
  components: {
    TaskWasAssigned,
    TaskWasReAssigned,
    TaskUpdated,
    TaskNewComment,
    DownloadReady,
    DefaultMessage,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formatDate() {
      return moment(this.data.created_at).format('YYYY-MM-DD | HH:mm');
    },
    renderer() {
      switch (this.data.type) {
        case 'App\\Notifications\\DefaultMessage':
        case 'App\\Notifications\\TaskWasAssigned':
        case 'App\\Notifications\\TaskWasReAssigned':
        case 'App\\Notifications\\TaskUpdated':
        case 'App\\Notifications\\TaskNewComment':
        case 'App\\Notifications\\DownloadReady':
        case 'App\\Notifications\\NewsUpdated':
          return this.data.type.split('\\').slice(-1)[0];
        default:
          return null;
      }
    },
    notificationIcon() {
      switch (this.data.type) {
        case 'App\\Notifications\\DefaultMessage':
          switch (this.data.data.status) {
            case 'success':
              return 'mdi-bell-check';
            case 'error':
              return 'mdi-bell-cancel';
            case 'info':
              return 'mdi-bell';
            case 'warning':
              return 'mdi-bell-alert';
            default:
              return 'mdi-bell';
          }
        case 'App\\Notifications\\TaskWasAssigned':
        case 'App\\Notifications\\TaskWasReAssigned':
        case 'App\\Notifications\\TaskUpdated':
        case 'App\\Notifications\\TaskNewComment':
          return 'mdi-clipboard-multiple';
        case 'App\\Notifications\\DownloadReady':
          return 'mdi-download';
        case 'App\\Notifications\\NewsUpdated':
          return 'mdi-star-box';
        default:
          return null;
      }
    },
    isUnread() {
      return this.data.read_at === null;
    },
  },
  methods: {
    markRead() {
      this.$store.dispatch('notifications/markAsRead', this.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.message-wrapper {
  position: relative;
  padding: 0 20px;

  .message {
    padding: 10px 0;
  }

  .unread-dot {
    display: none;
    position: absolute;
    top: 16px;
    left: 4px;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: var(--v-primary-base);

    &:hover {
      cursor: pointer;
    }
  }

  &--unread {
    .unread-dot {
      display: block;
    }
  }

  .message-text {
    font-size: 15px;
    color: #2e2e2e;
    font-weight: 400;
    max-width: 400px;
    overflow: hidden;
    margin-bottom: 6px;
    line-height: 1.3;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    height: 40px;
    width: 40px;
    justify-content: center;
    border-radius: 100%;
    padding: 10px;
    margin-right: 4px;
    background-color: var(--v-primary-base);
  }
}
</style>
