<template>
  <div
    :class="{ 'sidebar-open': shown }"
    class="ant-glass-background radius-0 ant-border-left d-flex sidebar-container overflow-hidden"
    style="width: 450px"
  >
    <div class="d-flex flex-column flex-1">
      <slot name="header" />
      <div class="d-flex flex-1 overflow-y-auto">
        <div class="d-flex align-center">
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon class="ant-icon" dense @click="close" v-on="on">
                mdi-chevron-right
              </v-icon>
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>
        <slot name="default" />
      </div>
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  props: {
    shown: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    shown(state) {
      if (state) {
        setTimeout(() => {
          this.$emit('on-shown');
        }, 300);
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-container {
  transform: translateX(100%);
  transition: 200ms ease-out;

  &.sidebar-open {
    transform: translateX(0);
  }
}
</style>
