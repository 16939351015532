import { getModuleData } from '@/services/api/module.api';
import {
  createRecord,
  deleteRecord,
  updateRecord,
} from '@/services/api/record.api';
import { waitForVuex } from '@/utils/vuex';
import { queryTablesV2 } from '@/services/api/v2/connect_v2';

const state = {
  moduleData: {
    CFFA_SM_STAKEHOLDERS: {
      id: null,
      records: [],
    },
    CFFA_SM_STAKEHOLDERS_REMARKS: {
      id: null,
      records: [],
    },
  },
  moduleStatus: '',
  selectedStakeholder: null,
};

const mutations = {
  module_stake_holders_set_select_stakeholder(state, stakeholder) {
    state.selectedStakeholder = stakeholder;
  },
  module_stake_holders_request(state) {
    state.moduleStatus = 'loading';
  },
  module_stake_holders_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  module_stake_holders_create_success(state, record) {
    state.moduleData.CFFA_SM_STAKEHOLDERS.records.push(record);
    state.selectedStakeholder = record;
  },
  module_stake_holders_create_remark_success(state, record) {
    state.moduleData.CFFA_SM_STAKEHOLDERS_REMARKS.records.push(record);
  },
  module_stake_holders_update_success(state, record) {
    Object.assign(
      state.moduleData.CFFA_SM_STAKEHOLDERS.records.find(
        (item) => item.id === record.id
      ),
      record
    );
    if (state.selectedStakeholder.id === record.id) {
      state.selectedStakeholder = record;
    }
  },
  module_stake_holders_delete_success(state, deletedId) {
    state.moduleData.CFFA_SM_STAKEHOLDERS.records =
      state.moduleData.CFFA_SM_STAKEHOLDERS.records.filter(
        (record) => record.id !== deletedId
      );
  },
};

const actions = {
  async loadModuleData({ commit }, { projectId, moduleId, sessionId }) {
    commit('module_stake_holders_request');
    const data = await getModuleData(
      projectId,
      moduleId,
      sessionId,
      false
    ).catch((error) => {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
      throw error;
    });
    commit('module_stake_holders_success', data);

    const { CFFA_SM_STAKEHOLDERS } = await queryTablesV2({
      tables: [
        {
          name: 'CFFA_SM_STAKEHOLDERS',
          project: projectId,
          as: 'CFFA_SM_STAKEHOLDERS',
          sortBy: 'name',
          columns: [
            'name',
            'organisation',
            'website',
            'role',
            'contact_name',
            'contact_email',
            'contact_phone',
            'contact_linkedIn',
            'SBS',
            'influence',
            'interest',
            'image_base64',
          ].map((name) => ({ name })),
        },
      ],
    });

    commit('module_stake_holders_success', {
      ...state.moduleData,
      CFFA_SM_STAKEHOLDERS,
    });
  },
  toggleStakeholder: waitForVuex(
    'module.stakeHolders.remarks.get',
    async ({ state, commit, rootGetters }, stakeholder) => {
      if (stakeholder?.id !== state.selectedStakeholder?.id) {
        commit('module_stake_holders_set_select_stakeholder', stakeholder);
        const { CFFA_SM_STAKEHOLDERS_REMARKS } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_SM_STAKEHOLDERS_REMARKS',
              project: rootGetters.project.id,
              as: 'CFFA_SM_STAKEHOLDERS_REMARKS',
              sortBy: 'created_at',
              columns: [
                {
                  name: 'stakeholder_id',
                  conditions: [{ operator: '=', value: stakeholder.id }],
                },
                {
                  name: 'user_name',
                },
                {
                  name: 'user_email',
                },
                {
                  name: 'remark',
                },
              ],
            },
          ],
        });
        commit('module_stake_holders_success', {
          ...state.moduleData,
          CFFA_SM_STAKEHOLDERS_REMARKS,
        });
      } else {
        commit('module_stake_holders_set_select_stakeholder', null);
      }
    }
  ),
  createStakeholder({ state, commit, rootGetters }, name) {
    return new Promise(async (resolve, reject) => {
      let record = await createRecord({
        project: {
          id: rootGetters.project.id,
        },
        table: {
          id: state.moduleData.CFFA_SM_STAKEHOLDERS.id,
        },
        record: {
          name: name,
          interest: 5,
          influence: 5,
        },
      });
      commit('module_stake_holders_create_success', record);
      resolve(record);
    });
  },
  async updateStakeholder({ state, commit, getters, rootGetters }, body) {
    let record = await updateRecord(getters.selectedStakeholder.id, {
      project: {
        id: rootGetters.project.id,
      },
      table: {
        id: state.moduleData.CFFA_SM_STAKEHOLDERS.id,
      },
      record: body,
    }).catch((error) => {
      commit(
        'showNotification',
        {
          content: error.message,
          color: 'error',
        },
        { root: true }
      );
      throw error;
    });
    commit('module_stake_holders_update_success', record);
  },
  deleteStakeholder({ state, commit, rootGetters }, recordId) {
    return new Promise(async (resolve, reject) => {
      let record = await deleteRecord(recordId, {
        project: {
          id: rootGetters.project.id,
        },
        table: {
          id: state.moduleData.CFFA_SM_STAKEHOLDERS.id,
        },
      });
      commit('module_stake_holders_delete_success', recordId);
      resolve(record);
    });
  },

  /**
   * Remarks actions
   */
  async createStakeholderRemark({ state, commit, getters, rootGetters }, body) {
    let record = await createRecord({
      project: {
        id: rootGetters.project.id,
      },
      table: {
        id: state.moduleData.CFFA_SM_STAKEHOLDERS_REMARKS.id,
      },
      record: {
        ...body,
        user_name: rootGetters.authenticatedUser.name,
        user_email: rootGetters.authenticatedUser.email,
      },
    }).catch((error) => {
      commit(
        'showNotification',
        {
          content: error.message,
          color: 'error',
        },
        { root: true }
      );
      throw error;
    });
    commit('module_stake_holders_create_remark_success', record);
    return record;
  },
};

const getters = {
  data: (state) => state.moduleData,
  status: (state) => state.moduleStatus,
  stakeholders: (state) => state.moduleData.CFFA_SM_STAKEHOLDERS.records,
  stakeholderRemarks: (state, getters, rootState, rootGetters) => {
    return state.moduleData.CFFA_SM_STAKEHOLDERS_REMARKS.records
      .filter((r) => r.stakeholder_id === state.selectedStakeholder?.id)
      .reverse();
  },
  selectedStakeholder: (state) => state.selectedStakeholder,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
