import { executeCustomModuleCall } from '@/services/api/module.api';

import { queryTablesV2 } from '@/services/api/v2/connect_v2';

const state = {
  moduleData: {},
  status: '',
  moduleId: null,
  halls: [],
  focusedHall: null,
  tacts: [],
  tactTableId: null,
  activeHallLocations: [],
  hallLocations: [],
  hallLanes: [],
  hallProjects: [],
  configurationSteps: [],
};
const mutations = {
  module_assembly_hall_dashboard_request(state) {
    state.status = 'loading';
  },
  set_halls(state, halls) {
    state.halls = halls;
    state.status = 'success';
  },
  set_module_id(state, moduleId) {
    state.moduleId = moduleId;
  },
  focus_on_hall(state, hall) {
    state.focusedHall = hall;
  },
  set_tacts(state, { tacts, tableId }) {
    state.tacts = tacts;
    state.tactTableId = tableId;
  },
  set_hall_locations(state, locations) {
    state.hallLocations = locations;
  },
  set_hall_lanes(state, lanes) {
    state.hallLanes = lanes;
  },
  set_hall_steps(state, step) {
    state.configurationSteps = step;
  },
  set_active_locations(state, locations) {
    state.activeHallLocations = locations;
  },
  set_projects(state, projects) {
    state.hallProjects = projects;
  },
  create_tact_success(state, tact) {
    state.tacts.push(tact);
  },
  reset_hall(state) {
    state.activeHallLocations = [];
    state.hallLocations = [];
    state.hallLanes = [];
    state.hallProjects = [];
  },
};
const actions = {
  async fetchHalls({ commit, state, rootGetters }) {
    commit('set_module_id', state.moduleId);
    commit('module_assembly_hall_dashboard_request');

    const { halls } = await queryTablesV2({
      tables: [
        {
          name: 'CFFA_DHME_ASSEMBLY_HALLS',
          project: rootGetters.project.id,
          as: 'halls',
          sortBy: 'title',
          columns: [
            {
              name: 'hall',
              as: 'title',
            },
            {
              name: 'type',
            },
            {
              name: 'automated',
            },
          ],
        },
      ],
    });

    commit('set_halls', halls.records);
  },
  async fetchHallTacts({ commit, rootGetters }) {
    const { tacts } = await queryTablesV2({
      tables: [
        {
          name: 'CFFA_DHME_TACTS',
          project: rootGetters.project.id,
          as: 'tacts',
          sortBy: 'tact_start',
          columns: [
            {
              name: 'mes_id',
            },
            {
              name: 'tact_start',
            },
            {
              name: 'tact_end',
            },
          ],
        },
      ],
    });
    commit('set_tacts', { tacts: tacts.records, tactTableId: tacts.id });
  },
  async addTactToAnt({ commit, state, rootGetters }, data) {
    try {
      const record = await createRecord({
        project: { id: rootGetters.id },
        table: { id: state.tactTableId },
        record: data,
      });
      commit('create_tact_success', record);
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
    }
  },
  async fetchHallProjects({ commit, state, rootGetters }) {
    try {
      let projects = await executeCustomModuleCall(
        rootGetters.project.id,
        state.moduleId,
        'fetchHallProjects',
        { hall: state.focusedHall.id }
      );
      commit('set_projects', projects);
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
    }
  },

  async selectAssemblyHall({ commit, rootGetters, state }, hall) {
    try {
      commit('reset_hall');
      commit('focus_on_hall', hall);

      const { locations } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_LOCATIONS',
            project: rootGetters.project.id,
            as: 'locations',
            sortBy: 'order',
            columns: [
              {
                name: 'assembly_hall',
                conditions: [
                  {
                    operator: '=',
                    value: hall.id,
                  },
                ],
              },
              {
                name: 'assembly_lane',
              },
              {
                name: 'assembly_location',
              },
              {
                name: 'description',
              },
              {
                name: 'hall_configuration',
              },
              {
                name: 'order',
              },
            ],
          },
        ],
      });

      const { lanes, steps } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_LANES',
            project: rootGetters.project.id,
            as: 'lanes',
            sortBy: 'order',
            columns: [
              {
                name: 'configuration',
                conditions: [
                  {
                    operator: '=',
                    values: [
                      ...new Set(
                        locations.records.map((l) => l.hall_configuration)
                      ),
                    ],
                  },
                ],
              },
              {
                name: 'title',
              },
              {
                name: 'color',
              },
              {
                name: 'order',
              },
            ],
          },
          {
            name: 'CFFA_DHME_ASSEMBLY_STEPS',
            project: rootGetters.project.id,
            as: 'steps',
            sortBy: 'order',
            columns: [
              {
                name: 'configuration',
                conditions: [
                  {
                    operator: '=',
                    values: [
                      ...new Set(
                        locations.records.map((l) => l.hall_configuration)
                      ),
                    ],
                  },
                ],
              },
              {
                name: 'title',
              },
              {
                name: 'order',
              },
            ],
          },
        ],
      });

      const tasks = await executeCustomModuleCall(
        rootGetters.project.id,
        state.moduleId,
        'fetchActiveLocationIds',
        {
          HallId: hall.id,
        }
      );

      let activeLocations = [];

      tasks.forEach((t) => {
        if (
          t.task_type.location &&
          !activeLocations.some((x) => x.location.id === t.task_type.location)
        ) {
          activeLocations.push({
            location: {
              ...locations.records.find((l) => l.id === t.task_type.location),
            },
            project: t.task_project,
          });
        }
      });

      commit('set_active_locations', activeLocations);
      commit('set_hall_locations', locations.records);
      commit('set_hall_lanes', lanes.records);
      commit('set_hall_steps', steps.records);
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    }
  },
};
const getters = {
  moduleId: (state) => state.moduleId,
  status: (state) => state.status,
  halls: (state) => state.halls,
  focusedHall: (state) => state.focusedHall,
  tacts: (state) => state.tacts,
  tactTableId: (state) => state.tactTableId,
  activeHallLocations: (state) => state.activeHallLocations,
  hallProjects: (state) => state.hallProjects,
  hallLocations: (state) => state.hallLocations,
  hallLanes: (state) => state.hallLanes,
  configurationSteps: (state) => state.configurationSteps,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
