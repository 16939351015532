import { getWorkflow } from '@/services/api/workflows.api';
import {
  createWorkflowBlock,
  deleteWorkflowBlock,
  getWorkflowBlocks,
  updateWorkflowBlock,
} from '@/services/api/blocks.api';
import {
  createWorkflowLink,
  deleteWorkflowLink,
  getWorkflowLinks,
} from '@/services/api/links.api';
import {
  createWorkflowNode,
  deleteWorkflowNode,
  getWorkflowNodes,
} from '@/services/api/nodes.api';
import WorkflowHelper from '@/services/workflow-helper';

const state = {
  builder: {
    workflow: undefined,
    blocks: [],
    nodes: [],
    links: [],
    flow: [],
    status: '',
  },
};
const mutations = {
  get_workflow_success(state, workflow) {
    state.builder.workflow = workflow;
  },
  update_workflow_success(state, workflow) {
    state.builder.workflow = Object.assign({}, workflow);
  },
  workflow_builder_request(state) {
    state.builder.status = 'loading';
  },
  workflow_builder_block_create_success(state, block) {
    state.builder.blocks.push(block);
    state.builder.status = 'success';
  },
  workflow_builder_block_update_success(state, block) {
    if (state.builder.blocks.length > 0) {
      Object.assign(
        state.builder.blocks.find((item) => item.id === block.id),
        block
      );
    }

    // update nodeId list
    state.builder.nodes.forEach((node) => {
      if (node.block !== null) {
        if (node.block.id === block.id) {
          Object.assign(node.block, block);
        }
      }
    });
    state.builder.status = 'success';
  },
  workflow_builder_block_delete_success(state, deletedBlockId) {
    // update nodeId list
    state.builder.blocks = [
      ...state.builder.blocks.filter((block) => block.id !== deletedBlockId),
    ];

    // update builderBlock in node list
    state.builder.nodes.forEach((node, index) => {
      if (node.block !== null) {
        if (node.block.id === deletedBlockId) {
          state.builder.nodes.splice(index, 1);
        }
      }
    });
    state.builder.status = 'success';
  },
  workflow_builder_node_create_success(state, node) {
    state.builder.nodes.push(node);
    state.builder.status = 'success';
  },
  workflow_builder_node_delete_success(state, deletedNodeId) {
    state.builder.nodes = [
      ...state.builder.nodes.filter((node) => node.id !== deletedNodeId),
    ];
    state.builder.status = 'success';
  },
  create_link_success(state, link) {
    state.builder.links.push(link);
    state.builder.status = 'success';
  },
  remove_link_success(state, deletedLinkId) {
    state.builder.links = [
      ...state.builder.links.filter((link) => link.id !== deletedLinkId),
    ];
  },
  calculate_flow(state) {
    state.builder.flow = WorkflowHelper.calculateBuilderFlow(
      state.builder.nodes,
      state.builder.links
    );
    state.builder.status = 'success';
  },
  workflow_data_request(state) {
    state.builder.status = 'loading';
  },
  workflow_data_success(state, [workflow, blocks, links, nodes]) {
    state.builder.workflow = workflow;
    state.builder.blocks = blocks;
    state.builder.links = links;
    state.builder.nodes = nodes;
    state.builder.status = 'success';
  },
};
const actions = {
  createBlock({ commit }, { workflowId, block }) {
    commit('workflow_builder_request');
    return new Promise((resolve, reject) => {
      createWorkflowBlock(workflowId, block)
        .then((block) => {
          commit('workflow_builder_block_create_success', block);
          commit('calculate_flow');
          resolve(block);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  updateBlock({ commit }, { workflowId, blockId, body }) {
    commit('workflow_builder_request');
    return new Promise((resolve, reject) => {
      updateWorkflowBlock(workflowId, blockId, body)
        .then((block) => {
          commit('workflow_builder_block_update_success', block);
          this.commit('block_saved_success');
          resolve(block);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  removeBlock({ commit }, { workflowId, blockId }) {
    commit('workflow_builder_request');
    return new Promise((resolve, reject) => {
      deleteWorkflowBlock(workflowId, blockId)
        .then((response) => {
          commit('workflow_builder_block_delete_success', blockId);
          resolve(response);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  createNode({ commit }, { workflowId, node }) {
    commit('workflow_builder_request');
    return createWorkflowNode(workflowId, node)
      .then((node) => {
        commit('workflow_builder_node_create_success', node);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  removeWorkflowNode({ commit }, { workflowId, nodeId }) {
    commit('workflow_builder_request');
    return new Promise((resolve, reject) => {
      deleteWorkflowNode(workflowId, nodeId)
        .then((response) => {
          commit('workflow_builder_node_delete_success', nodeId);
          resolve(response);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  createLink({ commit }, { workflowId, link }) {
    return createWorkflowLink(workflowId, link)
      .then((link) => {
        commit('create_link_success', link);
        commit('calculate_flow');
        this.commit('showNotification', {
          content: 'Link created',
          color: 'success',
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  removeLink({ commit }, { workflowId, linkId }) {
    return new Promise((resolve, reject) => {
      deleteWorkflowLink(workflowId, linkId)
        .then((response) => {
          commit('remove_link_success', linkId);
          commit('calculate_flow');
          resolve(response);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  fetchWorkflowData({ commit }, workflowId) {
    commit('workflow_data_request');
    return Promise.all([
      getWorkflow(workflowId),
      getWorkflowBlocks(workflowId),
      getWorkflowLinks(workflowId),
      getWorkflowNodes(workflowId),
    ])
      .then((responses) => {
        commit('workflow_data_success', responses);
        commit('calculate_flow');
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: 'Something went wrong while fetching nodeId data',
          color: 'error',
        });
      });
  },
};
const getters = {
  builderWorkflow: (state) => state.builder.workflow,
  builderBlocks: (state) => state.builder.blocks,
  builderLinks: (state) => state.builder.links,
  builderNodes: (state) => state.builder.nodes,
  builderFlow: (state) => state.builder.flow,
  builderStatus: (state) => state.builder.status,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
