import httpClient from '../httpClient';

const createUser = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/user`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const addUserToLicense = (data, licenseId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/license/${licenseId}/user`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getUsersFromLicenses = (licenseId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/license/${licenseId}/users`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getUserEmails = () => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/users`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateUser = (userId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/user/${userId}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const requestPasswordReset = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post('/user/password/forgot', data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const resetPassword = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/user/password/reset`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  createUser,
  addUserToLicense,
  getUsersFromLicenses,
  updateUser,
  requestPasswordReset,
  resetPassword,
  getUserEmails,
};
