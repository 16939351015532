import { getModuleData } from '@/services/api/module.api';
import {
  createRecord,
  deleteRecord,
  updateRecord,
} from '@/services/api/record.api';

const state = {
  moduleData: undefined,
  moduleStatus: '',
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
};
const mutations = {
  module_fourD_request(state) {
    state.moduleStatus = 'loading';
  },
  module_fourD_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  module_fourD_create_planning_success(state, record) {
    state.moduleData.CFFA_FORGE_PLANNING.records.push(record);
  },
  module_fourD_delete_planning_success(state, recordIdd) {
    state.moduleData.CFFA_FORGE_PLANNING.records =
      state.moduleData.CFFA_FORGE_PLANNING.records.filter(
        (item) => item.id !== recordIdd
      );
  },
  module_fourD_create_activity_success(state, record) {
    state.moduleData.CFFA_FORGE_PLANNING_ACTIVITIES.records.push(record);
  },
  module_fourD_update_activity_success(state, record) {
    Object.assign(
      state.moduleData.CFFA_FORGE_PLANNING_ACTIVITIES.records.find(
        (item) => item.id === record.id
      ),
      record
    );
  },
  module_fourD_delete_activity_success(state, recordIdd) {
    state.moduleData.CFFA_FORGE_PLANNING_ACTIVITIES.records =
      state.moduleData.CFFA_FORGE_PLANNING_ACTIVITIES.records.filter(
        (item) => item.id !== recordIdd
      );
  },
};
const actions = {
  loadFourDModuleData({ commit }, { projectId, moduleId, sessionId }) {
    commit('module_fourD_request');
    getModuleData(projectId, moduleId, sessionId)
      .then((data) => {
        commit('module_fourD_success', data);
        return Promise.resolve(data);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
        return Promise.reject(error);
      });
  },

  async createPlanning({ commit, getters, state }, data) {
    state.isCreating = true;
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.fourDPlanningsTable,
      },
      record: data,
    };

    try {
      const record = await createRecord(body);
      commit('module_fourD_create_planning_success', record);
      this.commit('showNotification', {
        content: `Successfully created planning`,
        color: 'success',
      });
      state.isCreating = false;
      return Promise.resolve(record);
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
      state.isCreating = false;
      return Promise.reject(error);
    }
  },

  async fourDDeletePlanning({ commit, getters, state }, data) {
    state.isDeleting = true;
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.fourDPlanningsTable,
      },
    };

    try {
      await deleteRecord(data.id, body);
      commit('module_fourD_delete_planning_success', data.id);
      this.commit('showNotification', {
        content: `Planning deleted`,
        color: 'success',
      });
      state.isDeleting = false;
      return Promise.resolve();
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
      state.isDeleting = false;
      return Promise.reject(error);
    }
  },

  async fourDCreateActivity({ commit, getters, state }, data) {
    state.isCreating = true;
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.fourDActivitiesTable,
      },
      record: data,
    };

    try {
      const record = await createRecord(body);
      commit('module_fourD_create_activity_success', record);
      this.commit('showNotification', {
        content: `Successfully created activity`,
        color: 'success',
      });
      state.isCreating = false;
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
      state.isCreating = false;
    }
  },

  async fourDUpdateActivity({ commit, getters, state }, data) {
    state.isUpdating = true;
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.fourDActivitiesTable,
      },
      record: data,
    };

    try {
      const record = await updateRecord(data.id, body);
      commit('module_fourD_update_activity_success', record);
      this.commit('showNotification', {
        content: `Successfully updated activity`,
        color: 'success',
      });
      state.isUpdating = false;
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
      state.isUpdating = false;
    }
  },

  async fourDDeleteActivity({ commit, getters, state }, data) {
    state.isDeleting = true;
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.fourDActivitiesTable,
      },
    };

    try {
      await deleteRecord(data.id, body);
      commit('module_fourD_delete_activity_success', data.id);
      state.isDeleting = false;
      this.commit('showNotification', {
        content: `Activity deleted`,
        color: 'success',
      });
      return Promise.resolve();
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
      state.isDeleting = false;
      return Promise.reject(error);
    }
  },
};
const getters = {
  fourDData: (state) => state.moduleData,
  fourDStatus: (state) => state.moduleStatus,
  fourDPlannings: (state) =>
    state.moduleData?.CFFA_FORGE_PLANNING.records ?? [],
  fourDActivities: (state) =>
    state.moduleData?.CFFA_FORGE_PLANNING_ACTIVITIES.records ?? [],
  fourDActivitiesTable: (state) =>
    state.moduleData?.CFFA_FORGE_PLANNING_ACTIVITIES.id ?? null,
  fourDPlanningsTable: (state) =>
    state.moduleData?.CFFA_FORGE_PLANNING.id ?? null,
  fourDClient: (state) => state.moduleData?.CFFA_FORGE_CLIENT.records[0] ?? [],
  fourDModels: (state) => state.moduleData?.CFFA_FORGE_MODELS.records ?? [],
  fourDisUpdating: (state) => state.isUpdating,
  fourDisDeleting: (state) => state.isDeleting,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
