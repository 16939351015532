<template>
  <vue-markdown :source="source" :table-class="tableClass" />
</template>

<script>
export default {
  name: 'MarkdownRenderer',
  props: {
    source: {
      type: String,
      required: true,
    },
    tableClass: {
      type: String,
      default: 'ant-markup-table',
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ant-markup-table {
  border-collapse: collapse;

  td,
  th {
    border-bottom: 1px solid lightgrey;
    padding: 4px 8px;
  }
}
</style>
