<template>
  <router-link
    :class="[animate ? 'animate' : '']"
    :target="target"
    :to="path"
    class="ant-glass-background session-wrapper"
  >
    <v-progress-circular
      :rotate="360"
      :value="getSessionProgress(session)"
      :width="4"
      color="primary"
      size="40"
      style="margin-right: 10px"
    >
      <div style="font-size: 10px">{{ getSessionProgress(session) }}%</div>
    </v-progress-circular>
    <div class="d-flex flex-column">
      <div class="d-flex">
        <div v-if="session.sbs_code">
          {{ session.sbs_code }}
        </div>
        <v-divider v-if="session.sbs_code" class="mx-2" vertical />
        <div>
          {{ session.name }}
        </div>
      </div>
      <div
        class="font-italic overflow-hidden text-ellipsis"
        style="font-size: 12px; max-width: 200px"
      >
        {{ session.workflow_name }}
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';
import { getSessionProgress } from '@/utils';

export default {
  name: 'SessionItem',
  props: {
    session: {
      type: Object,
      required: true,
    },
    target: {
      type: String,
      default: '_self',
    },
    animate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['project']),
    path() {
      return `/project/${this.project.slug}/workflows/${this.session?.workflow_id}/sessions/${this.session?.id}`;
    },
  },
  methods: {
    getSessionProgress,
  },
};
</script>

<style lang="scss" scoped>
.session-wrapper {
  margin: 10px 0;
  padding: 10px;
  display: flex;
  transition: 200ms ease-out;
  cursor: pointer;
  text-decoration: inherit;
  color: inherit;

  &:hover {
    color: var(--v-primary-base);
  }

  &.animate:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    transform: translateX(7px);
  }
}
</style>
