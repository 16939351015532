<template>
  <div class="pa-2 ant-border-bottom">
    <img v-if="item.image" :alt="item.title" :src="item.image" width="100%" />
    <div class="mt-1 fs-16 font-weight-bold">
      <span>{{ item.title }}</span>
    </div>
    <div class="fs-14">
      <span>{{ formatDate }}</span>
    </div>
    <div v-if="tags?.length" class="my-2 d-flex flex-wrap">
      <div v-for="(tag, index) in tags" :key="index" class="mr-1 mb-1">
        <v-chip color="success" small>
          <span style="color: #ffffff; font-weight: 500">{{ tag }}</span>
        </v-chip>
      </div>
    </div>
    <div class="mt-1">
      <markdown-renderer :source="item.description" />
    </div>
    <div v-if="item.button_url" class="pa-1 mt-2">
      <a :href="item.button_url" class="d-inline-flex" target="_blank">
        <v-btn
          class="d-block flex-grow-1"
          color="primary"
          elevation="0"
          small
          tile
        >
          {{ item.button_title || 'Learn more' }}
        </v-btn>
      </a>
    </div>
  </div>
</template>

<script>
import MarkdownRenderer from '@/components/MarkdownRenderer.vue';
import moment from 'moment/moment';

export default {
  name: 'NewsItem',
  components: { MarkdownRenderer },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formatDate() {
      return moment(this.item.updated_at).format('MMM DD, YYYY');
    },
    tags() {
      return JSON.parse(this.item.tags) || [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
