import { getModuleData } from '@/services/api/module.api';
import {
  clearAllRecords,
  createRecord,
  recordsSearch,
  updateRecord,
} from '../../services/api/record.api';
import { getRevisions } from '@/services/api/revisions.api';

const state = {
  moduleData: undefined,
  moduleStatus: '',
  buildOrderRevisions: [],
};
const mutations = {
  module_build_order_request(state) {
    state.moduleStatus = 'loading';
  },
  module_build_order_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  add_build_order_record(state, record) {
    state.moduleData.CFFA_FORGE_BUILD_ORDER.records.push(record);
  },
  update_build_order_record(state, record) {
    let index = state.moduleData.CFFA_FORGE_BUILD_ORDER.records.findIndex(
      (module) => module.id === record.id
    );
    state.moduleData.CFFA_FORGE_BUILD_ORDER.records[index] = Object.assign(
      {},
      record
    );
    state.moduleData.CFFA_FORGE_BUILD_ORDER.records = [
      ...state.moduleData.CFFA_FORGE_BUILD_ORDER.records,
    ];
  },
  module_build_order_revisions_success(state, revisions) {
    state.buildOrderRevisions = revisions;
  },
  module_build_order_revision_data_success(state, records) {
    state.moduleData.CFFA_FORGE_BUILD_ORDER.records = records.records;
  },
  reset_build_order_table_success(state) {
    state.moduleData.CFFA_FORGE_BUILD_ORDER.records = [];
  },
};
const actions = {
  fetchBuildOrderModuleData(
    { commit, dispatch },
    { projectId, moduleId, sessionId }
  ) {
    commit('module_build_order_request');
    getModuleData(projectId, moduleId, sessionId)
      .then((data) => {
        commit('module_build_order_success', data);
        dispatch('fetchBuildOrderRevisions');
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  fetchBuildOrderRevisions({ commit, getters, state }) {
    return getRevisions(
      getters.project.id,
      state.moduleData.CFFA_FORGE_BUILD_ORDER.id
    ).then((revisions) => {
      commit('module_build_order_revisions_success', revisions);
    });
  },
  fetchBuildOrderRevisionData({ commit, getters, state }, { revision }) {
    const body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: state.moduleData.CFFA_FORGE_BUILD_ORDER.id,
      },
      revision: revision,
    };
    return recordsSearch(body).then((records) => {
      commit('module_build_order_revision_data_success', records);
    });
  },
  updateBuildOrderRecord({ commit }, { recordId, body }) {
    return updateRecord(recordId, body)
      .then((record) => {
        commit('update_build_order_record', record);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  setSBSOrder({ commit, getters, state }, sbscode) {
    let records = state.moduleData.CFFA_FORGE_BUILD_ORDER.records;

    let record = records.find((record) => record.SBS === sbscode);

    let body = {
      project: {
        id: getters.project,
      },
      table: {
        id: state.moduleData.CFFA_FORGE_BUILD_ORDER.id,
      },
      record: {
        SBS: sbscode,
      },
    };

    let order = state.moduleData.CFFA_FORGE_BUILD_ORDER.records
      .filter((record) => record.build_order !== null)
      .filter((record) => record.build_order !== undefined)
      .map((record) => record.build_order)
      .sort(function (a, b) {
        return a - b;
      });

    let columnOrder;
    if (order[order.length - 1] + 1 > 0) {
      columnOrder = parseInt(order[order.length - 1]) + 10;
    } else {
      columnOrder = 10;
    }
    body.record.build_order = columnOrder;

    if (record !== undefined) {
      this.commit('showNotification', {
        content: `${record.SBS} already has ${record.build_order} as order value`,
        color: 'error',
      });
    } else {
      // create record
      return createRecord(body)
        .then((record) => {
          commit('add_build_order_record', record);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  },
  resetBuildOrder({ commit, getters }) {
    return clearAllRecords(
      getters.project.id,
      state.moduleData.CFFA_FORGE_BUILD_ORDER.id
    )
      .then(() => {
        commit('reset_build_order_table_success');
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
};
const getters = {
  buildOrderData: (state) => state.moduleData,
  buildOrderStatus: (state) => state.moduleStatus,
  buildOrderModuleOrderRecords: (state) =>
    state.moduleData.CFFA_FORGE_BUILD_ORDER.records,
  buildOrderRevisions: (state) => state.buildOrderRevisions,
  buildOrderClient: (state) =>
    state.moduleData.CFFA_FORGE_CLIENT.records[0] ?? null,
  buildOrderModels: (state) => state.moduleData.CFFA_FORGE_MODELS.records,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
