import { getModuleData } from '@/services/api/module.api';

const state = {
  moduleData: undefined,
  moduleStatus: '',
};
const mutations = {
  module_point_cloud_request(state) {
    state.moduleStatus = 'loading';
  },
  module_point_cloud_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
};
const actions = {
  loadPointCloudModuleData({ commit }, { projectId, moduleId, sessionId }) {
    commit('module_point_cloud_request');
    getModuleData(projectId, moduleId, sessionId)
      .then((data) => {
        commit('module_point_cloud_success', data);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
};
const getters = {
  pointCloudData: (state) => state.moduleData,
  pointCloudStatus: (state) => state.moduleStatus,
  pointCloudProperties: (state) =>
    state.moduleData.CFFA_PC_PROPERTIES.records[0],
  pointCloudLayers: (state) => state.moduleData.CFFA_PC_LAYERS.records,
  pointCloudSceneLayers: (state) =>
    state.moduleData.CFFA_PC_SCENE_LAYERS.records,
  pointCloudBuildingSceneLayers: (state) =>
    state.moduleData.CFFA_PC_BUILDING_SCENE_LAYERS.records,
  pointCloudGroupLayers: (state) =>
    state.moduleData.CFFA_PC_GROUP_LAYERS.records,
  pointCloudFeatureLayers: (state) =>
    state.moduleData.CFFA_PC_FEATURE_LAYERS.records,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
