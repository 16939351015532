import Vue from 'vue';
import VueCookies from 'vue-cookies-reactive';

export const ANT_NAV_PIN = 'ant-nav-pin';
export const ANT_DASH_STATE = 'project-view-state';
export const ANT_LICENSE = 'license';

Vue.use(VueCookies);

const CookieService = {
  _getCookie(key) {
    return Vue.$cookies.get(key);
  },
  _setCookie(key, value, expire) {
    Vue.$cookies.set(key, value, expire);
  },
  _removeCookie(key) {
    Vue.$cookies.remove(key);
  },
  hasCookie(key) {
    return Vue.$cookies.isKey(key);
  },

  getNavPin() {
    return this._getCookie(ANT_NAV_PIN);
  },
  setNavPin(value, expire) {
    this._setCookie(ANT_NAV_PIN, value, expire);
  },
  removeNavPin() {
    this._removeCookie(ANT_NAV_PIN);
  },

  getProjectViewState() {
    return this._getCookie(ANT_DASH_STATE);
  },
  setProjectViewState(value, expire) {
    this._setCookie(ANT_DASH_STATE, value, expire);
  },
  removeProjectViewState() {
    this._removeCookie(ANT_DASH_STATE);
  },
};

export default CookieService;
