import {
  executeCustomModuleCall,
  getModuleData,
} from '@/services/api/module.api';
import { waitForVuex } from '@/utils/vuex';
import { deleteTasks, importTasks } from '@/services/api/task.api';

const state = {
  moduleData: {},
  pickOrderTasks: [],
};
const mutations = {
  module_pick_order_management_success(state, data) {
    state.moduleData = data;
  },
  module_pick_order_management_tasks_success(state, tasks) {
    state.pickOrderTasks = tasks;
  },
  module_pick_order_management_tasks_create_success(state, task) {
    state.pickOrderTasks.push(task);
  },
  module_pick_order_management_tasks_delete_success(state, taskId) {
    state.pickOrderTasks = state.pickOrderTasks.filter(
      (item) => item.id !== taskId
    );
  },
};
const actions = {
  fetchDhmePickOrderManagementData: waitForVuex(
    'dhme.pick-order-management.get',
    ({ commit, getters }, { moduleId, sessionId }) => {
      return new Promise((resolve, reject) => {
        return getModuleData(getters.project.id, moduleId, sessionId)
          .then((response) => {
            commit('module_pick_order_management_success', response);
            resolve(response);
          })
          .catch((error) => {
            this.commit('showNotification', {
              content: error.message,
              color: 'error',
            });
            reject(error);
          });
      });
    }
  ),
  fetchDhmePickOrderTasks: waitForVuex(
    'dhme.pick-order-management.get',
    ({ commit, getters }, { moduleId }) => {
      return new Promise((resolve, reject) => {
        return executeCustomModuleCall(
          getters.project.id,
          moduleId,
          'fetchPickOrderTasks'
        )
          .then((response) => {
            commit('module_pick_order_management_tasks_success', response);
            resolve(response);
          })
          .catch((error) => {
            this.commit('showNotification', {
              content: error.message,
              color: 'error',
            });
            reject(error);
          });
      });
    }
  ),
  updatePickOrderTasks({ commit, getters }, { moduleId }) {
    return new Promise((resolve, reject) => {
      return executeCustomModuleCall(
        getters.project.id,
        moduleId,
        'fetchPickOrderTasks'
      ).then((response) => {
        commit('module_pick_order_management_tasks_success', response);
      });
    });
  },
  createPickOrderTasks: waitForVuex(
    'dhme.pick-order-management.create',
    ({ commit }, body) => {
      return new Promise((resolve, reject) => {
        return importTasks(body)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.commit('showNotification', {
              content: error.message,
              color: 'error',
            });
            reject(error);
          });
      });
    }
  ),
  deletePickOrderTask: waitForVuex(
    'dhme.pick-order-management.delete',
    ({ commit }, body) => {
      return new Promise((resolve, reject) => {
        return deleteTasks(body)
          .then((response) => {
            commit(
              'module_pick_order_management_tasks_delete_success',
              body[0].id
            );
            resolve(response);
          })
          .catch((error) => {
            this.commit('showNotification', {
              content: error.message,
              color: 'error',
            });
            reject(error);
          });
      });
    }
  ),
};
const getters = {
  dhmePickOrderManagementAssemblyHalls: (state) =>
    state.moduleData.CFFA_DHME_ASSEMBLY_HALLS?.records ?? [],
  dhmePickOrderManagementStorageLocations: (state) =>
    state.moduleData.CFFA_DHME_STORAGE_LOCATIONS?.records ?? [],
  dhmePickOrderManagementStorageBoxes: (state) =>
    state.moduleData.CFFA_DHME_STORAGE_BOX?.records ?? [],
  dhmePickOrderManagementTasks: (state) => state.pickOrderTasks,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
