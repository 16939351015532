import moment from 'moment';
import TaskHelper from '@/services/task-helper';
import { queryTasksV2 } from '@/services/api/task.api';
import { queryTablesV2 } from '@/services/api/v2/connect_v2';

const state = {
  projectAssemblyTasks: [],
  projectAssemblyTasksStatus: '',
  moduleStatus: '',
  assemblyHall: null,
  assemblyConfiguration: null,
  assemblyLocations: null,
  assemblyLanes: null,
  modules: null,
  calendarItems: null,
};
const mutations = {
  dhme_module_data_request(state) {
    state.moduleStatus = 'loading';
  },
  dhme_module_data_success(
    state,
    { hall, config, locations, lanes, modules, calendarItems }
  ) {
    state.assemblyHall = hall;
    state.assemblyConfiguration = config;
    state.assemblyLocations = locations;
    state.assemblyLanes = lanes;
    state.modules = modules;
    state.calendarItems = calendarItems;
    state.moduleStatus = 'success';
  },
  dhme_assembly_tasks_update(state, tasks) {
    tasks.forEach((t1) => {
      Object.assign(
        state.projectAssemblyTasks.find((t2) => t2.id === t1.id),
        t1
      );
    });
  },
  dhme_assembly_tasks_request(state) {
    state.projectAssemblyTasksStatus = 'loading';
  },
  dhme_assembly_tasks_success(state, tasks) {
    state.projectAssemblyTasks = TaskHelper.mapDefaultTreeItems(tasks);
    state.projectAssemblyTasksStatus = 'success';
  },
};
const actions = {
  async fetchDhmeAssemblyPlanningTasks({ commit, getters, dispatch }) {
    commit('dhme_assembly_tasks_request');
    const response = await queryTasksV2(getters.selectedLicense.id, [
      {
        column: 'project',
        operator: '=',
        values: [getters.project.id],
      },
      {
        column: 'type',
        operator: '=',
        values: ['dhme-assembly-phase', 'dhme-module-assembly'],
      },
    ]);
    const tasks = response.tasks;
    commit('dhme_assembly_tasks_success', tasks);
    if (
      tasks.filter((t) => t.task_type.type === 'dhme-assembly-phase').length ===
      0
    ) {
      commit('showNotification', {
        content:
          'No assembly phase found, please add an assembly phase in the project planning app',
        color: 'error',
      });
    }
    if (
      tasks.filter((t) => t.task_type.type === 'dhme-assembly-phase').length > 1
    ) {
      commit('showNotification', {
        content: 'Only 1 assembly phase is allowed per project',
        color: 'error',
      });
    }

    dispatch('fetchDhmeAssemblyPlanningData');
  },
  async fetchDhmeAssemblyPlanningData({ commit, rootGetters, getters }) {
    commit('dhme_module_data_request');
    const { halls, configurations, locations, lanes, modules, calendarItems } =
      await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_CALENDAR',
            project: rootGetters.project.master_id,
            as: 'calendarItems',
            columns: [
              {
                name: 'label',
              },
              {
                name: 'date',
              },
            ],
          },
          {
            name: 'CFFA_DHME_ASSEMBLY_HALLS',
            project: rootGetters.project.master_id,
            as: 'halls',
            records: [getters.dhmeProjectAssemblyPhase?.task_type.custom_3],
            columns: [
              {
                name: 'hall',
                as: 'title',
              },
              {
                name: 'type',
              },
              {
                name: 'automated',
              },
            ],
          },
          {
            name: 'CFFA_DHME_ASSEMBLY_HALL_CONFIGURATIONS',
            project: rootGetters.project.master_id,
            as: 'configurations',
            records: [getters.dhmeProjectAssemblyPhase?.task_type.custom_4],
            columns: [
              {
                name: 'title',
              },
            ],
          },
          {
            name: 'CFFA_DHME_ASSEMBLY_LOCATIONS',
            project: rootGetters.project.master_id,
            as: 'locations',
            sortBy: 'order',
            columns: [
              {
                name: 'assembly_hall',
                conditions: [
                  {
                    operator: '=',
                    value: getters.dhmeProjectAssemblyPhase?.task_type.custom_3,
                  },
                ],
              },
              {
                name: 'assembly_lane',
              },
              {
                name: 'assembly_location',
              },
              {
                name: 'description',
              },
              {
                name: 'order',
              },
              {
                name: 'manual_default',
              },
              {
                name: 'hall_configuration',
                as: 'configuration',
                conditions: [
                  {
                    operator: '=',
                    value: getters.dhmeProjectAssemblyPhase?.task_type.custom_4,
                  },
                ],
              },
            ],
          },
          {
            name: 'CFFA_DHME_ASSEMBLY_LANES',
            project: rootGetters.project.master_id,
            as: 'lanes',
            sortBy: 'order',
            columns: [
              {
                name: 'title',
              },
              {
                name: 'color',
              },
              {
                name: 'order',
              },
              {
                name: 'configuration',
                conditions: [
                  {
                    operator: '=',
                    value: getters.dhmeProjectAssemblyPhase?.task_type.custom_4,
                  },
                ],
              },
            ],
          },
          {
            name: 'CFFA_DHME_MODULES',
            project: rootGetters.project.id,
            as: 'modules',
            sortBy: 'assembly_sequence',
            columns: [
              {
                name: 'module_type',
              },
              {
                name: 'module_id',
              },
              {
                name: 'build_nr',
              },
              {
                name: 'assembly_sequence',
              },
            ],
          },
        ],
      });

    commit('dhme_module_data_success', {
      hall: halls.records[0] ?? null,
      config: configurations.records[0] ?? null,
      locations: locations.records,
      lanes: lanes.records,
      modules: modules.records,
      calendarItems: calendarItems.records,
    });
  },
};
const getters = {
  dhmeProjectAssemblyPlanningStatus: (state) => state.moduleStatus,
  dhmeProjectAssemblyPlanningModules: (state) => state.modules,
  dhmeProjectAssemblyPlanningCalendar: (state) => {
    return state.calendarItems.map((item) => {
      item.date = moment(item.date).format('YYYY-MM-DD');
      return item;
    });
  },
  dhmeProjectAssemblyHallLocations: (state) => state.assemblyLocations,
  dhmeProjectAssemblyHallLanes: (state) => state.assemblyLanes,
  dhmeProjectAssemblyHallTime: (state, getters) => {
    return getters.dhmeProjectAssemblyPhase?.task_type.custom_5;
  },
  dhmeProjectAssemblyHallSpeed: (state, getters) => {
    return getters.dhmeProjectAssemblyPhase?.task_type.custom_6;
  },
  dhmeProjectAssemblyHallConfiguration: (state) => state.assemblyConfiguration,
  dhmeProjectAssemblyHall: (state) => state.assemblyHall,
  dhmeProjectAssemblyTasks: (state) =>
    state.projectAssemblyTasks.filter(
      (task) => task.task_type.type === 'dhme-module-assembly'
    ),
  dhmeProjectAssemblyPhase: (state) =>
    state.projectAssemblyTasks.find(
      (task) => task.task_type.type === 'dhme-assembly-phase'
    ),
  dhmeProjectAssemblyTasksStatus: (state) => state.projectAssemblyTasksStatus,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
