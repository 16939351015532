import {
  clearSbsTable,
  createSbsRecord,
  createSbsVersion,
  deleteMultipleSbsRecords,
  deleteSbsRecord,
  deleteSbsVersion,
  getSbsRecord,
  getSbsRecordChildren,
  getSbsRecords,
  getSbsTree,
  getSbsVersions,
  importSbsBatch,
  searchSbsRecords,
  searchSbsTree,
  updateSbsRecord,
} from '@/services/api/sbs.api';

const state = {
  sbsRecords: [],
  sbsRecordsStatus: '',
  sbsTree: [],
  sbsTreeStatus: '',
  sbsVersions: [],
  sbsVersionsStatus: '',
  sbsSearchRecords: [],
  selectedSbsObject: undefined,
  sbsSidebarToggle: false,
  sbsSidebarWidth: 0,
  sbsRenderEvent: 0,
  selectedSbsVersion: null,
  selected3DSbsObject: '',
};
const mutations = {
  select_3d_sbs_object(state, sbscode) {
    state.selected3DSbsObject = sbscode;
  },
  sbs_records_request(state) {
    state.sbsRecordsStatus = 'loading';
  },
  sbs_records_success(state, sbsRecords) {
    state.sbsRecords = sbsRecords;
    state.sbsRecordsStatus = 'success';
  },
  sbs_record_create_success(state, sbsRecord) {
    state.sbsRecords.push(sbsRecord);
    state.sbsRecordsStatus = 'success';
  },
  sbs_record_update_success(state, sbsRecord) {
    Object.assign(
      state.sbsRecords.find((record) => record.id === sbsRecord.id),
      sbsRecord
    );
    state.sbsRecordsStatus = 'success';
  },
  sbs_record_delete_success(state, deletedSbsId) {
    state.sbsRecords = [
      ...state.sbsRecords.filter((record) => record.id !== deletedSbsId),
    ];
    state.sbsRecordsStatus = 'success';
  },
  sbs_tree_request(state) {
    state.sbsTreeStatus = 'loading';
  },
  sbs_tree_success(state, tree) {
    tree.map((item) => {
      if (item.hasChildren) {
        item.children = [];
        return item;
      }
    });
    state.sbsTree = tree;
    state.sbsTreeStatus = 'success';
  },
  sbs_versions_request(state) {
    state.sbsVersionsStatus = 'loading';
  },
  sbs_versions_success(state, versions) {
    state.sbsVersions = versions;
    state.sbsVersionsStatus = 'success';
  },
  sbs_versions_create_success(state, version) {
    state.sbsVersions.push(version);
  },
  sbs_versions_delete_success(state, deletedVersionId) {
    state.sbsVersions = state.sbsVersions.filter(
      (version) => version.id !== deletedVersionId
    );
  },
  sbs_search_success(state, records) {
    state.sbsSearchRecords = records;
    state.sbsTreeStatus = 'success';
  },
  sbs_search_clear(state) {
    state.sbsSearchRecords = [];
  },
  select_sbs_object(state, item) {
    state.selectedSbsObject = Object.assign({}, item);
  },
  deselect_sbs_object(state) {
    state.selectedSbsObject = undefined;
  },
  toggle_sbs_sidebar(state) {
    state.sbsSidebarToggle = !state.sbsSidebarToggle;
  },
  open_sbs_sidebar(state) {
    state.sbsSidebarToggle = true;
  },
  close_sbs_sidebar(state) {
    state.sbsSidebarToggle = false;
  },
  update_sbs_render(state) {
    state.sbsRenderEvent++;
  },
  select_sbs_version(state, version) {
    state.selectedSbsVersion = version;
  },
  sbs_clear_table_success(state) {
    state.sbsRecords = [];
    state.sbsTree = [];
  },
  sbs_sidebar_width_success(state, width) {
    state.sbsSidebarWidth = width;
  },
};
const actions = {
  loadSbsRecords({ commit, state }, { projectId }) {
    commit('sbs_records_request');
    let filters = [];
    if (state.selectedSbsVersion) {
      filters['version'] = state.selectedSbsVersion.id;
    }
    return getSbsRecords(projectId, filters)
      .then((sbsRecords) => {
        commit('sbs_records_success', sbsRecords);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  loadSbsTree({ commit, state }, { projectId }) {
    commit('sbs_tree_request');
    let filters = [];
    if (state.selectedSbsVersion) {
      filters['version'] = state.selectedSbsVersion.id;
    }
    return getSbsTree(projectId, filters)
      .then((tree) => {
        commit(
          'sbs_tree_success',
          tree.sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
          )
        );
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  loadSbsVersions({ commit, state }, { projectId }) {
    commit('sbs_versions_request');
    return getSbsVersions(projectId)
      .then((versions) => {
        commit('sbs_versions_success', versions);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  searchSbsRecords({ commit, getters }, { searchValue }) {
    commit('sbs_records_request');
    let filters = [];
    if (state.selectedSbsVersion) {
      filters['version'] = state.selectedSbsVersion.id;
    }
    return searchSbsRecords(getters.project.id, searchValue, filters)
      .then((records) => {
        commit('sbs_records_success', records);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  searchSbsTree({ commit, state, getters }, { searchValue }) {
    commit('sbs_tree_request');
    let filters = [];
    if (state.selectedSbsVersion) {
      filters['version'] = state.selectedSbsVersion.id;
    }
    return searchSbsTree(getters.project.id, searchValue, filters)
      .then((records) => {
        commit('sbs_search_success', records);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  fetchSbsChildren({ commit, state }, { projectId, sbs }) {
    let filters = [];
    if (state.selectedSbsVersion) {
      filters['version'] = state.selectedSbsVersion.id;
    }
    return getSbsRecordChildren(projectId, sbs.id, filters)
      .then((children) => {
        return children;
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  importSbsRecords({ commit }, { projectId, body }) {
    commit('sbs_records_request');
    return importSbsBatch(projectId, body)
      .then((response) => {
        this.commit('showNotification', {
          content: response.message,
          color: 'success',
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  createSbsRecord({ commit, dispatch }, { projectId, data }) {
    commit('sbs_records_request');
    return createSbsRecord(projectId, data)
      .then((sbsObject) => {
        commit('sbs_record_create_success', sbsObject);
        dispatch('loadSbsTree', { projectId });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  updateSbsRecord({ commit, dispatch }, { projectId, sbsId, data }) {
    commit('sbs_records_request');
    return updateSbsRecord(projectId, sbsId, data)
      .then((record) => {
        commit('sbs_record_update_success', record);
        dispatch('loadSbsTree', { projectId });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  deleteSbsRecord({ commit, dispatch }, { projectId, sbsId }) {
    commit('sbs_records_request');
    return deleteSbsRecord(projectId, sbsId)
      .then((sbsObject) => {
        commit('sbs_record_delete_success', sbsId);
        dispatch('loadSbsTree', { projectId });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  deleteMultipleSbsRecords({ commit, dispatch }, { projectId, body }) {
    return deleteMultipleSbsRecords(projectId, body)
      .then((response) => {
        this.commit('showNotification', {
          content: response.message,
          color: 'success',
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  clearSbsTable({ commit, getters }) {
    return clearSbsTable(getters.project.id)
      .then((response) => {
        commit('sbs_clear_table_success');
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  createSbsVersion({ commit }, { projectId, body }) {
    return createSbsVersion(projectId, body)
      .then((version) => {
        commit('sbs_versions_create_success', version);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  deleteSbsVersion({ commit }, { projectId, versionId }) {
    return deleteSbsVersion(projectId, versionId)
      .then((response) => {
        commit('sbs_versions_delete_success', versionId);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  selectSBSObject({ commit, getters }, { sbsObject }) {
    return getSbsRecord(getters.project.id, sbsObject.id)
      .then((object) => {
        commit('select_sbs_object', object);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  deselectSBSObject({ commit }) {
    commit('deselect_sbs_object');
  },
};
const getters = {
  sbsRecords: (state) => state.sbsRecords,
  sbsRecordsStatus: (state) => state.sbsRecordsStatus,
  sbsTree: (state) => state.sbsTree,
  sbsTreeStatus: (state) => state.sbsTreeStatus,
  sbsVersions: (state) => state.sbsVersions,
  sbsVersionsStatus: (state) => state.sbsVersionsStatus,
  sbsSearchRecords: (state) => state.sbsSearchRecords,
  selectedSbsObject: (state) => state.selectedSbsObject,
  sbsSidebarToggle: (state) => state.sbsSidebarToggle,
  sbsRenderEvent: (state) => state.sbsRenderEvent,
  selectedSbsVersion: (state) => state.selectedSbsVersion,
  selected3DSbsObject: (state) => state.selected3DSbsObject,
  sbsSidebarWidth: (state) => state.sbsSidebarWidth,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
