import { waitForVuex } from '@/utils/vuex';
import { getColumns } from '@/services/api/column.api';
import {
  clearAllRecords,
  createRecord,
  deleteRecord,
  deleteRecords,
  getFilteredRecords,
  getFilteredRecordsWithMeta,
  getRecords,
  importRecords,
  recordsSearch,
  updateRecord,
  verifyRecords,
} from '@/services/api/record.api';
import DataHelper from '@/services/data-helper';
import {
  createRevision,
  getRevisions,
  restoreRevision,
} from '@/services/api/revisions.api';

const state = {
  tableColumns: [],
  tableMetadata: {
    count: 1,
    limit: 1,
    offset: 0,
  },
  tableRecords: [],
  tableRevisions: [],

  tableRevisionCompare: {
    old: {
      revision: undefined,
      records: [],
    },
    new: {
      revision: undefined,
      records: [],
    },
  },
};
const mutations = {
  table_success(state, [columns, recordsResponse]) {
    state.tableColumns = columns;
    const { records, metadata } = recordsResponse;
    state.tableRecords = records;
    state.tableMetadata = metadata;
  },

  table_metadata_success(state, metadata) {
    state.tableMetadata = metadata;
  },

  records_success(state, records) {
    state.tableRecords = records;
  },

  record_create_success(state, record) {
    state.tableRecords.push(record);
  },

  record_update_success(state, updatedRecord) {
    Object.assign(
      state.tableRecords.find((record) => record.id === updatedRecord.id),
      updatedRecord
    );
  },

  record_delete_success(state, deletedRecordId) {
    state.tableRecords = [
      ...state.tableRecords.filter((record) => record.id !== deletedRecordId),
    ];
  },

  table_revisions_success(state, revisions) {
    state.tableRevisions = revisions;
  },
  revision_create_success(state, revision) {
    state.tableRevisions.push(revision);
  },

  set_old_revision_records(state, [records, revision]) {
    state.tableRevisionCompare.old.revision = revision;
    state.tableRevisionCompare.old.records = records.records;
  },
  set_new_revision_records(state, [records, revision]) {
    state.tableRevisionCompare.new.revision = revision;
    state.tableRevisionCompare.new.records = records.records;
  },
  set_actual_as_old_revision(state, records) {
    state.tableRevisionCompare.old.revision = { id: '-1', name: 'Actual' };
    state.tableRevisionCompare.old.records = records;
  },
  set_actual_as_new_revision(state, records) {
    state.tableRevisionCompare.new.revision = { id: '-1', name: 'Actual' };
    state.tableRevisionCompare.new.records = records;
  },
  reset_revision(state) {
    state.tableRevisionCompare = {
      old: {
        revision: undefined,
        records: [],
      },
      new: {
        revision: undefined,
        records: [],
      },
    };
  },
  table_clear_success(state) {
    state.tableRecords = [];
  },
};
const actions = {
  fetchTable: waitForVuex(
    'table.get',
    (
      { commit, dispatch },
      { projectId, tableId, filters, sortBy = {}, loadRecords = true }
    ) => {
      return Promise.all([
        dispatch('fetchTableInfo', { projectId, tableId }),
        loadRecords
          ? dispatch('fetchFilteredRecordsWithMeta', {
              projectId,
              tableId,
              filters,
              sortBy,
            })
          : Promise.resolve([]),
      ]).then((responses) => {
        commit('table_success', responses);
      });
    }
  ),
  fetchFilteredRecordsWithMeta: waitForVuex(
    'table.records.get',
    async ({ commit }, { projectId, tableId, filters, sortBy = {} }) => {
      return getFilteredRecordsWithMeta({
        projectId,
        tableId,
        filters,
        sortBy,
      })
        .then((response) => {
          commit('records_success', response.records);
          commit('table_metadata_success', response.metadata);
          return response;
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
  fetchTableInfo: waitForVuex(
    'table.records.get',
    async ({ commit }, { projectId, tableId }) => {
      return getColumns(projectId, tableId).catch((error) => {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
    }
  ),
  fetchRecordsInSession: waitForVuex(
    'table.records.get',
    async ({ commit }, { projectId, tableId, filters }) => {
      try {
        const records = await getFilteredRecords(projectId, tableId, filters);
        if (records) {
          commit('records_success', records);
        }
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
  fetchRecordsOnTimestamp: waitForVuex(
    'table.records.get',
    async ({ commit }, { body }) => {
      try {
        const { records } = await recordsSearch(body);
        commit('records_success', records);
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
  clearTable: waitForVuex(
    'table.clear',
    ({ commit }, { projectId, tableId }) => {
      return clearAllRecords(projectId, tableId)
        .then(() => {
          commit('table_clear_success');
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
  createRecord: waitForVuex(
    'table.record.create',
    async ({ commit }, { body }) => {
      try {
        const record = await createRecord(body);
        commit('record_create_success', record);
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
  updateRecord: waitForVuex(
    'table.record.update',
    async ({ commit }, { recordId, body }) => {
      try {
        const record = await updateRecord(recordId, body);
        commit('record_update_success', record);
        commit('showNotification', {
          content: 'Record updated successfully',
          color: 'success',
        });
        return record;
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
  deleteRecord: waitForVuex(
    'table.record.delete',
    ({ commit }, { recordId, body }) => {
      return deleteRecord(recordId, body)
        .then(() => {
          commit('record_delete_success', recordId);
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
  deleteRecords: waitForVuex(
    'table.records.delete',
    async ({ commit, state }, body) => {
      return deleteRecords(body)
        .then(() => {
          commit('showNotification', {
            content: `Successfully deleted records`,
            color: 'success',
          });
        })
        .catch((error) => {
          console.error(error);
          commit('showNotification', {
            content: `Failed to delete record`,
            color: 'error',
          });
        });
    }
  ),
  importRecords: waitForVuex(
    'table.records.import',
    ({ getters, commit, dispatch }, { tableId, data }) => {
      return importRecords({
        project: {
          id: getters.project.id,
        },
        table: {
          id: tableId,
        },
        records: data.records,
      })
        .then((response) => {
          commit('showNotification', {
            content: response.message,
            color: 'success',
          });
        })
        .catch((error) => {
          verifyRecords(data).then((response) => {
            commit('showNotification', {
              content: response.errors,
              color: 'error',
            });
          });
        });
    }
  ),
  fetchTableRevisions: waitForVuex(
    'revisions.table.get',
    async ({ commit }, { projectId, tableId }) => {
      try {
        const revisions = await getRevisions(projectId, tableId);
        commit('table_revisions_success', revisions);
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
  storeRevision: waitForVuex(
    'revisions.revision.create',
    async ({ commit }, body) => {
      try {
        const revision = await createRevision(body);
        commit('revision_create_success', revision);
      } catch (error) {
        console.error(error);
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
  restoreRevision: waitForVuex(
    'revisions.revision.restore',
    async ({ commit }, { revisionId, body }) => {
      try {
        const revision = await restoreRevision(revisionId, body);
        commit('revision_create_success', revision);
      } catch (error) {
        console.error(error);
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),

  revisionOldRecords: waitForVuex(
    'revisions.records.old',
    async ({ commit }, { body, revision }) => {
      try {
        const records = await recordsSearch(body);
        commit('set_old_revision_records', [records, revision]);
      } catch (error) {
        console.error(error);
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
  revisionNewRecords: waitForVuex(
    'revisions.records.new',
    async ({ commit }, { body, revision }) => {
      try {
        const records = await recordsSearch(body);
        commit('set_new_revision_records', [records, revision]);
      } catch (error) {
        console.error(error);
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
  compareClearDataSet: waitForVuex(
    'revisions.records.clear',
    ({ commit }, { set }) => {
      switch (set) {
        case 'old':
          commit('set_old_revision_records', [{ records: [] }, undefined]);
          break;
        case 'new':
          commit('set_new_revision_records', [{ records: [] }, undefined]);
          break;
      }
    }
  ),
  setActualDataSetAsCompare: waitForVuex(
    'revisions.records.get',
    async ({ commit, getters }, { set }) => {
      try {
        const records = await getRecords(
          getters.selectedProjectTable.project,
          getters.selectedProjectTable.id
        );
        switch (set) {
          case 'old':
            commit('set_actual_as_old_revision', records);
            break;
          case 'new':
            commit('set_actual_as_new_revision', records);
            break;
        }
      } catch (error) {
        console.error(error);
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
  searchInTable: waitForVuex(
    'table.records.search',
    async ({ commit }, { body }) => {
      try {
        const { records } = await recordsSearch(body);
        commit('records_success', records);
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
};
const getters = {
  tableRecords: (state, getters) => {
    if (getters.selectedSbsObject) {
      let sbsColumn = state.tableColumns.find(
        (column) => column.type === 'sbscode'
      );
      if (sbsColumn) {
        return state.tableRecords.filter(
          (record) => record[sbsColumn.name] === getters.selectedSbsObject.code
        );
      }
    }
    return state.tableRecords;
  },
  tableMetadata: (state) => state.tableMetadata,
  tableColumns: (state) => state.tableColumns,
  tableHeaders: (state) => DataHelper.getTableColumns(state.tableColumns, true),
  tableHeadersSlots: (state) =>
    DataHelper.getTableColumns(state.tableColumns, true, true),
  tableRevisions: (state) => state.tableRevisions,
  tableRevisionCompare: (state) => state.tableRevisionCompare,
  tableRevisionRecords: (state) => {
    let comparedRecords = [];

    state.tableRevisionCompare.old.records.forEach((record) => {
      comparedRecords.push({
        old: record,
        new: null,
      });
    });

    state.tableRevisionCompare.new.records.forEach((record) => {
      let index = comparedRecords.findIndex((item) => {
        return item.old?.id === record.id;
      });
      if (index === -1) {
        comparedRecords.push({
          old: null,
          new: record,
        });
      } else {
        comparedRecords[index].new = record;
      }
    });

    return comparedRecords;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
