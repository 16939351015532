export const projectDashboardTour = (lan) => {
  if (lan === 'en') {
    return [
      {
        header: {
          title: 'Your project Dashboard',
        },
        target: '[data-v-step="ProjectDash0"]',
        content:
          'This small tutorial will help you through the first steps of ANT',
      },
      {
        target: '[data-v-step="ProjectDash1"]',
        header: {
          title: 'Your project tasks',
        },
        content: `These are your project tasks! Tasks from workflows or from other project members.`,
        params: {
          placement: 'left',
        },
      },
      {
        target: '[data-v-step="ProjectDash4"]',
        header: {
          title: 'Your workflows',
        },
        content: `Here you can contribute to the workflows of the project.`,
        params: {
          placement: 'left',
        },
      },
      {
        target: '[data-v-step="ProjectDash5"]',
        header: {
          title: 'Project Tables',
        },
        content: `With project tables, you can create your own data-models and share information betweeen project members, your own apps and others.`,
        params: {
          placement: 'left',
        },
      },
      {
        target: '[data-v-step="ProjectDash6"]',
        header: {
          title: 'Your project tasks',
        },
        content: `These are your project tasks! Tasks from workflows or from other project members.`,
        params: {
          placement: 'left',
        },
      },
      {
        target: '[data-v-step="ProjectDash7"]',
        header: {
          title: 'Your project tasks',
        },
        content: `These are your project tasks! Tasks from workflows or from other project members.`,
        params: {
          placement: 'left',
        },
      },
      {
        target: '[data-v-step="ProjectDash8"]',
        header: {
          title: 'Project settings',
        },
        content: `Here you can find the project settings`,
        params: {
          placement: 'left',
        },
      },
      {
        target: '[data-v-step="ProjectDash2"]',
        header: {
          title: 'Sessions',
        },
        content: `These the sessions in this project. When they are not completed, you can find out what needs to be done by whom by clicking on the session card`,
        params: {
          placement: 'top',
        },
      },
      {
        target: '[data-v-step="ProjectDash3"]',
        header: {
          title: 'Project apps',
        },
        content: `Your apps are visible in this overview. Mostly, you will be asked via the workflow sessions to do a task within an app, but sometimes you just want to use the app stand-alone`,
        params: {
          placement: 'top',
        },
      },
    ];
  }
};

export const dashboardTour = (lan) => {
  if (lan === 'en') {
    return [
      {
        header: {
          title: 'Welcome to ANT!',
        },
        target: '[data-v-step="Dash_0"]',
        content:
          'This small tutorial will help you through the first steps of ANT',
      },
      {
        target: '.v-step-Dash_1',
        header: {
          title: 'Your projects',
        },
        content: `These are your projects, you can select an app, or click on the image to go to the project Dashboard`,
        params: {
          placement: 'bottom',
        },
      },
      {
        header: {
          title: 'Search projects',
        },
        target: '[data-v-step="Dash_2"]',
        content: 'You can search through your projects in this searchbar',
      },
      {
        header: {
          title: '📃 Your tasks',
        },
        target: '[data-v-step="Dash_3"]',
        content:
          'Here you find all your tasks. You can comment and close tasks when you click on them!',
        params: {
          placement: 'left',
        },
      },
      {
        header: {
          title: '⚒️ Your Workflows',
        },
        target: '[data-v-step="Dash_4"]',
        content:
          'Here you find all your workflows. Create or edit the workflows to make them awesome💪',
        params: {
          placement: 'left',
        },
      },
      {
        header: {
          title: '👩‍🦰 Your profile',
        },
        target: '[data-v-step="Dash_5"]',
        content:
          'Edit your ANT Profile. Things like language, password, profile pictures to really make it yours.',
        params: {
          placement: 'left',
        },
      },
      {
        header: {
          title: '🛟 Support',
        },
        target: '[data-v-step="Dash_6"]',
        content:
          'If you need any help, please feel free to contact us by leaving a ticket in our support portal.',
        params: {
          placement: 'left',
        },
      },
    ];
  }
};

export const DHME_WVBTour = (lan) => {
  if (lan === 'en') {
    return [
      {
        header: {},
        title: 'Building phases',
        target: '[data-v-step="DHME_WV0"]',
        content: 'In this importTab, you can add modules to building phases',
      },
      {
        target: '[data-v-step="DHME_WV1"]',
        header: {
          title: 'Configure the building order',
        },
        content: `You can add your building order in this tab. In here, you can also add additional data to modules such as a house number`,
      },
      {
        target: '[data-v-step="DHME_WV2"]',
        header: {
          title: 'Configure the production Order',
        },
        content: `In this tab, you can configure the order in which the modules must be produced`,
      },
      // {
      //   target: '[data-v-step="DHME_WV0"]',
      //   header: {
      //     title: "First step",
      //   },
      //   content: `Go to this importTab, to add modules to the correct phases`,
      // },
    ];
  }
};

export const fourDTour = (lan) => {
  if (lan === 'en') {
    return [
      {
        header: {
          title: '4D Player',
        },
        target: '[data-v-step="FourD_S0"]',
        content: 'Click ‘4D Planning’ to open or close the 4D player',
      },
      {
        target: '[data-v-step="FourD_S1"]',
        header: {
          title: '4D Player',
        },
        content: `Click ‘Planning’ to select the planning we want to view`,
      },
    ];
  }
};
