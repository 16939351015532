import { httpClientV2 } from '@/services/httpClient';

export function unwrapV2(newPromise) {
  return new Promise((resolve, reject) => {
    Promise.resolve(httpClientV2)
      .then(newPromise)
      .then(
        (response) => resolve(response?.data ?? response),
        (error) => reject(error?.response?.data ?? error?.response ?? error)
      );
  });
}

// project intake uses v2 api due to Commercie rol in new RBAC system (OS)
export const fetchProjectV2 = (projectId) =>
  unwrapV2((axios) => axios.get(`projects/${projectId}`));
export const createProjectV2 = (data) =>
  unwrapV2((axios) => axios.post(`projects`, data));
export const updateProjectV2 = (projectId, data) =>
  unwrapV2((axios) => axios.put(`projects/${projectId}`, data));

// The below api calls need to both check old and new RBAC roles in backend
export const queryTablesV2 = (data) =>
  unwrapV2((axios) => axios.post(`/tables/query`, data));
export const importTasksV2 = (data) =>
  unwrapV2((axios) => axios.post(`/tasks`, data));

/* IMPORTANT!!!! DO NOT ADD MORE V2 API CALLS TO LAUNCHER. V1 (Launcher) and V2 (OS)
need to be fully seperated permission wise except for the above api calls*/
