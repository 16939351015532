import { getModuleData } from '@/services/api/module.api';
import { getTasks, queryTasksV2 } from '@/services/api/task.api';

const state = {
  moduleData: {},
  moduleStatus: '',
  qualityControlPhaseTasks: [],
};
const mutations = {
  dhme_module_quality_control_request(state) {
    state.moduleStatus = 'loading';
  },
  dhme_module_quality_control_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  dhme_module_quality_control_phase_tasks(state, tasks) {
    state.qualityControlPhaseTasks = tasks;
  },
};
const actions = {
  fetchQualityAssuranceTasks({ commit, getters }) {
    getTasks(
      {
        'filter[license]': getters.selectedLicense.id,
      },
      {
        advanced_filters: [
          {
            column: 'project',
            operator: '=',
            values: [getters.project.id],
          },
          {
            column: 'type',
            operator: '=',
            values: ['dhme-quality-assurance'],
          },
        ],
      }
    ).then((tasks) => {
      commit('dhme_module_quality_control_phase_tasks', tasks);
    });
  },
  async fetchQualityControlPhaseTasks({ commit, getters }) {
    let { tasks } = await queryTasksV2(getters.selectedLicense.id, [
      {
        column: 'project',
        operator: '=',
        values: [getters.project.id],
      },
      {
        column: 'type',
        operator: '=',
        values: [
          'dhme-init-phase',
          'dhme-structure-design-phase',
          'dhme-temporary-design-phase',
          'dhme-definitive-design-phase',
          'dhme-environmental-permit-phase',
          'dhme-contract-phase',
          'dhme-technical-design-phase',
          'dhme-execution-design-phase',
          'dhme-production-phase',
          'dhme-assembly-phase',
          'dhme-construction-site-phase',
          'dhme-production-drawing-phase',
        ],
      },
    ]);
    commit('dhme_module_quality_control_phase_tasks', tasks);
  },
  fetchQualityControlData(
    { commit, dispatch },
    { projectId, moduleId, sessionId }
  ) {
    commit('dhme_module_quality_control_request');
    dispatch('fetchQualityControlPhaseTasks');
    return new Promise((resolve, reject) => {
      getModuleData(projectId, moduleId, sessionId)
        .then((data) => {
          commit('dhme_module_quality_control_success', data);
          resolve(data);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
};
const getters = {
  qualityControlData: (state) => state.moduleData,
  qualityControlStatus: (state) => state.moduleStatus,
  qualityControlPhaseTasks: (state) => state.qualityControlPhaseTasks,
  qualityControlRisks: (state) => state.moduleData.CFFA_DHME_WKB_RISKS.records,
  qualityControlChapters: (state) =>
    state.moduleData.CFFA_DHME_WKB_CHAPTERS.records,
  qualityControlMeasurements: (state) =>
    state.moduleData.CFFA_DHME_WKB_MEASUREMENTS.records,
  qualityControlMeasurementTasks: (state) =>
    state.moduleData.CFFA_DHME_WKB_MEASUREMENT_TASKS.records,
  qualityControlMeasurementForms: (state) =>
    state.moduleData.CFFA_DHME_WKB_MEASUREMENT_FORMS.records,
  qualityControlMeasurementDocuments: (state) =>
    state.moduleData.CFFA_DHME_WKB_MEASUREMENT_DOCUMENTS.records,
  qualityControlProjectMeasurements: (state) =>
    state.moduleData.CFFA_DHME_WKB_PROJECT_RISKS_MEASURES.records,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
