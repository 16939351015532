import httpClient from '../httpClient';

const getTasks = (filters, advanced = {}) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/tasks`, advanced, {
        params: filters,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getTask = (taskId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/tasks/${taskId}`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createTask = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/tasks-create`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const cancelTask = (taskId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/tasks/${taskId}/cancel`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const closeTask = (taskId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/tasks/${taskId}/close`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const reopenTask = (taskId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/tasks/${taskId}/reopen`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateTask = (taskId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/tasks/${taskId}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteTask = (taskId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/tasks/${taskId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteTasks = (tasks) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/tasks/delete`, { tasks })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const uploadTaskAppendix = (taskId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/tasks/${taskId}/appendixes`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const downloadTaskAppendix = (taskId, appendix) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/tasks/${taskId}/appendixes/${appendix}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteTaskAppendix = (taskId, appendix) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/tasks/${taskId}/appendixes/${appendix}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createTaskMessage = (taskId, message) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/tasks/${taskId}/messages`, {
        message: message,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createTaskCheck = (taskId, check) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/tasks/${taskId}/checks`, check)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateTaskCheck = (taskId, checkId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/tasks/${taskId}/checks/${checkId}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteTaskCheck = (taskId, checkId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/tasks/${taskId}/checks/${checkId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createTaskLabel = (taskId, labelId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/tasks/${taskId}/labels/${labelId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteTaskLabel = (taskId, labelId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/tasks/${taskId}/labels/${labelId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const importTasks = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/tasks-import`, {
        tasks: data,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createTaskRelation = (previousId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/tasks/${previousId}/relations`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteTaskRelation = (taskId, relationId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/tasks/${taskId}/relations/${relationId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const executeTask = (taskId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/tasks/${taskId}/execute`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export {
  getTasks,
  getTask,
  closeTask,
  cancelTask,
  reopenTask,
  createTask,
  updateTask,
  deleteTask,
  deleteTasks,
  createTaskMessage,
  uploadTaskAppendix,
  downloadTaskAppendix,
  deleteTaskAppendix,
  createTaskCheck,
  updateTaskCheck,
  deleteTaskCheck,
  createTaskLabel,
  deleteTaskLabel,
  createTaskRelation,
  deleteTaskRelation,
  importTasks,
  executeTask,
};

// moved from v2 to v1
export const queryTasksV2 = (license, filters, depth = undefined) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`licenses/${license}/tasks/query`, {
        filters: filters,
        depth: depth,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};
