const messages = {
  en: {
    weekDays: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    general: {
      close: 'Close',
      open: 'Open',
      cancel: 'Cancel',
      save: 'Save',
      delete: 'Delete',
      edit: 'Edit',
      insert: 'Insert',
      generate: 'Generate',
      switch: 'switch',
      start: 'Start',
      add: 'Add',
      update: 'Update',
      date: 'Date',
      week: 'Week',
      today: 'Today',
      hour: 'Hour',
      import: 'Import',
      numberOfAbbreviation: 'pcs.',
      openInNewTab: 'Open in new tab',
      code: 'Code',
      label: 'Label',
      tables: 'tables',
      version: 'version',
      create: 'create',
      title: 'title',
      type: 'type',
      status: 'status',
      priority: 'priority',
      description: 'description',
    },
    system: {
      apps: {
        single: 'App',
        multi: 'App',
      },
      model: {
        single: 'Model',
        multi: 'Models',
      },
      licenses: {
        single: 'License',
        multi: 'Licenses',
        selectMsg: 'Please select your license',
      },
      sessions: {
        single: 'session',
        multi: 'sessions',
      },
      tasks: {
        single: 'Task',
        multi: 'tasks',
        overdue: 'This task is overdue',
        unplannedTasks: 'Unplanned tasks',
        due: 'Due',
        dueDate: 'Due date',
        notifications: {
          noAutomationId: 'Automation id not set within task',
          noProject: 'It has no project selected',
          fillTitle: 'Please fill in the tasks title',
          addUser: 'Please add an assigned user',
          createRfi: `Successfully created rfi '%{title}'`,
          updateRfi: `Successfully updated rfi '%{title}'`,
          createTask: `Successfully created task '%{title}'`,
          updateTask: `Successfully updated task '%{title}'`,
        },
        close: 'Close tasks',
        createdBy: 'Created By',
        createNew: 'Created new task',
        current: 'Current tasks',
        executedBy: 'Executed by',
        assignedUser: 'Assigned User',
        status: 'Status',
        dueDateChanged: 'Due date changed from %{A} too %{B}',
        statusChanged: 'Status changed from %{A} to %{B}',
        assignedUserChanged: 'Assigned user changed from %{A} to %{B}',
        newTaskButton: 'New Task',
        noTasksYet: 'No tasks yet',
        deleteConfirm: 'Are you sure want to delete %{msg}?',
        deleteCountTasks: 'no tasks | task | {count} tasks',
        chooseMethod:
          'Please choose how exactly you want to delete tasks which contain subtasks',
        withSub: 'With subtasks',
        deleteSelectedTasks: 'Delete selected tasks',
        addDescription: 'Please add a task description here',
        createRFI: 'Create RFI option',
        createDrawing: 'Create Drawing',
        rfi: {
          single: 'RFI',
          multi: "RFI's",
        },
      },
      projects: {
        single: 'Project',
        multi: 'Projects',
        tables: {
          revisionNotFound: 'Revision not found',
          revisionCompareNotFound: 'Revision to compare not found',
          revisionRestoreConfirmTitle:
            'Are you sure you want to restore this revision?',
          revisionRestoreConfirmDescription:
            "Current revision would be saved as new version. Selected revision's records would restored.",
        },
      },
      appBar: {
        projectOverview: 'Project Overview',
        ProjectAppsMenu: 'Project apps menu',
        unpin: 'Unpin navigation bar',
        pin: 'Pin navigation bar',
        changeEnv: 'Change Environment',
        profSett: 'Profile settings',
        accAdmin: 'Account admin',
        WF: 'Workflows',
        Support: 'Support',
        buildInfo: 'Build information',
        logOut: 'Logout',
        knowledgeBase: 'Knowledge base',
        whatsNew: "What's new",
        documentation: 'Documentation',
      },
      buildInfo: {
        RlNr: 'Release Number',
        RlDate: 'Release Date',
        CV: 'Commit Version',
      },
      taskOverview: {
        error: {
          getUsers: "Something went wrong retrieving project's users",
        },
        myTasks: 'My Tasks',
        filters: 'Task filters',
        filterTasks: 'Filter tasks',
        reload: 'Reload tasks',
        close: 'Close tasks',
        overview: 'Task overview',
      },
      profile: {
        currentPW: 'Current password',
        EnterCurrentPW: 'Enter current password',
        newPW: 'New password',
        enterNewPW: 'Enter new password',
        repeatNewPW: 'Repeat new password',
        firstName: 'Firstname',
        lastName: 'Lastname',
        email: 'Email',
        descFirst: 'Enter your firstname',
        descLast: 'Enter your lastname',
        descEmail: 'Enter your business email',
        taskDigestInfo: 'Enable it to receive your tasks digest every morning',
        notifications: {
          match: 'Password must match',
          differentPW:
            'New password must be different from the current password',
          pWChanged: 'Password has been changed',
          noPWChanged: 'Password has not been changed',
          pwChallenge:
            'Min. 8 characters with at least one capital letter, a number and a special character and no white spaces.',
          pwExpires:
            'Your password expires in %{passwordExpires} day(s), please reset your password',
        },
        language: 'Language',
        company: 'Company',
        languages: {
          EN: 'English',
          NL: 'Dutch',
          DE: 'German',
        },
        tokens: {
          newToken: 'Token',
          addNewToken: 'Add new Token',
          appName: 'App name',
          notifications: {
            confirmRemoveToken:
              'Are you sure you want to remove the Token for app: %{token}?',
          },
          table: {
            name: 'Application',
            cId: 'Client ID',
            cSecret: 'Client Secret',
          },
        },
      },
      sbsTree: {
        sbsObj: 'SBS Objects',
        noSessions: 'No sessions connected to this sbs object',
        toggleSidebar: 'Toggle SBS sidebar',
      },
      sbsOverview: {
        reload: 'Reload {item}',
        selectSBS: 'Select SBS object in sidebar',
        notSelected: 'SBSObject not selected',
        noFound: 'No %{item} found for selected SBS object',
      },
      maintenance: {
        main: 'ANT is under maintenance',
        tuning: 'We are currently tuning a few bits here and there.',
        fixUp: "All will be up in a few minutes, so don't worry.",
        auth: 'Maintenance Authentication',
        secret: 'Enter the maintenance secret',
      },
    },
    modules: {
      stationDashboard: {
        notApplicable: 'n/a',
        issue: 'Issue',
        issues: 'Issues',
        finish: 'Finish',
        dialogTitle: 'New Issue',
        captures: 'Image Captures',
      },
      forge: {
        toggleGhosting: 'Toggle Ghosting',
      },
      gisViewer: {
        chooseSketchColor: 'Choose sketch color',
      },
      fitGap: {
        reload: 'Reload Data',
        createNewGap: 'Create new gap',
        noRecords: 'No records',
      },
      projectAssemblyPlanning: {
        assemblyHall: 'Assembly hall',
        assemblySpeed: 'Assembly location workload',
        assemblyStartTime: 'Assembly start hour',
        excelExport: 'Generate excel',
        submitRequest: 'Request planning',
        generateTasks: 'Auto generate',
        generateNormal: 'Normal',
        generatePendulum: 'Pendulum',
      },
      assemblyPlanning: {
        sidebasrHeader: 'Assembly Requests',
        generateOption: 'Generate option',
        selectPlanningOption: 'Select planning option',
        normal: 'Normal',
        pendulum: 'Pendulum',
        unitsPerDay: 'Units per day',
        laneId: 'Lane %{lane}',
        switchModules: 'Switch Modules',
        switchModuleWith: 'Switch module: %{moduleA} with module %{moduleB}',
        switchModeActive: 'Switch mode active',
        submitAssemblyPlanningRequest: 'Submit request',
        moduleId: 'Module %{moduleId}',
        moduleStartPrevWeek: 'Module started previous week',
        moduleFinsihedNextWeek: 'Module finishes next week',
        workload: 'Assembly Workload',
        PS: 'Placement Sequence',
        AS: 'Assembly Sequence',
        modulePropsOf: `Module Properties of {0}`,
        week: `week {0}`,
        assemblyWeek: 'Assembly Week',
        assemblySpeed: 'Assembly location workload',
        autoSchedule: 'Auto Schedule',
        generateExcel: 'Generate Excel',
        duplicateLaneWarning: '{0} is within the same start_hour schedule',
        duplicateTitle: 'Modules within the same start/end time',
        overflowWarning: 'Module moves to next week',
        incorrectAssemblySequenceValue: 'Has incorrect assembly sequence value',
        incorrectAssemblyWorkloadValue: 'Has incorrect assembly workload value',
        incorrectDataBtn: 'Move to WVB',
        loadViewer: 'Load 3D viewer',
        asDialog: {
          title: 'Overwriting Schedule',
          description: 'Be aware, all your made changes will be overwritten!',
          cancel: 'Cancel',
          schedule: 'Schedule',
        },
      },
      projectGantt: {
        headers: {
          number: 'Number',
          title: 'Title',
          duration: 'Days',
          status: 'Status',
          startDate: 'Start',
          endDate: 'End',
          party: 'Party',
          predecessors: 'Predecessors',
          successors: 'Successors',
          parent: 'Parent',
        },
        filters: {
          allTasks: 'All tasks',
          myTasks: 'My tasks',
        },
        ganttZoomLevels: {
          yearly: 'Yearly',
          monthly: 'Monthly',
          weekly: 'Weekly',
          daily: 'Daily',
        },
      },
      projectPlanning: {
        phases: 'Phases',
        reset: 'Reset phases',
        addPhase: 'Add phase',
        editPhase: 'Edit phase',
        phaseType: 'Phase type',
        phaseName: 'Phase name',
        phaseNumber: 'Phase number',
        start: 'Start',
        end: 'End',
        notifications: {
          confirmDelete: 'Are you sure you want to remove %{task}',
          confirmReset: 'Are you sure you want to reset all phases?',
        },
        phaseTypes: {
          wvb: 'Work preparation',
          prd: 'Production',
          assembly: 'Assembly',
          placement: 'Placement',
        },
      },
      projectsPlanning: {
        comments: 'Comments',
        customer: 'Customer',
        general: 'General',
        details: 'Project details',
        planningOverview: 'Planning overview',
        progress: 'Progress',
        roles: 'Roles',
        redFlags: 'Red Flags',
        fieldNames: {
          customerCompany: 'Company',
          customerContact: 'SAP Nr',
          buildSystem: 'Build system',
          modules: 'Modules',
          temporary: 'Temporary', //Tijdelijk
          type: 'Type',
          userFunction: 'User function',
          status: 'Status',
          moduleState: 'Module state',
          budgetForecast: 'Forecasted budget',
          permitResponsible: 'Responsible permit', //Verantwoordelijk vergunning
        },
      },
      BillOfMaterial: {
        container: {
          element: 'Elements',
          object: 'Objects',
          group: 'Groups',
          addGroup: 'Add Group',
          unlinked: 'Unlinked',
          groupHeader: 'Groups',
          newGroup: 'Add group',
        },
        groups: {
          elements: 'Elements',
          objects: 'Objects',
          oAndE: 'Objects and Elements',
          addToS: 'Add %{type} to %{supplier}',
          addTo: 'Add to',
          notifications: {
            confirmDelete:
              'Are you sure you want to remove item %{item} from this supplier list?',
          },
          table: {
            elementType: 'Element Type',
            objectType: 'Object Type',
            name: 'Name',
            desc: 'Description',
            actions: 'Actions',
          },
        },
        elementSchedule: {
          createWorkPackage: 'Create work package',
          WorkPackageSubmitted: 'Work package submitted',
          exportWithdrawalStatement: 'Export withdrawal statement',
          exportWeeklyStatement: 'Export weekly statement',
          exportWeeklyPlanning: 'Export weekly planning',
          exportModuleContents: 'Export module contents',
          exportGroupQrCodes: 'Download QR codes',
          downloadToXlsx: 'Download content to xlsx',
          title: 'Element Schedule',
          module: 'Module',
          incorrectObjectsDataSetWarning:
            '{0} elements have an incorrect data set (missing column values: module_id, element_id, element_type, object_type)',
          headers: {
            elements: `Elements (total: {0})`,
            week: `Week {0} (total: {1})`,
          },
          qs: 'Quantity statement %{name}',
          production: 'Production',
          placement: 'Placement',
          quant: 'Quantities',
          show3D: 'Show in 3D model',
          table: {
            elementType: 'Element type',
            category: 'Category',
            total: 'Total',
          },
        },
        objectSchedule: {
          title: 'Object Schedule',
          downloadContent: 'Download content to xlsx',
          downloadObjects: 'Download objects to xlsx',
          week: `Week {0} (total: {1})`,
          weekValue: `week_{0}`,
          Objectcode: 'Code',
          name: 'Name',
          description: 'Description',
          Breedte: 'Width',
          Lengte: 'Length',
          Hoogte: 'Height',
          Oppervlakte: 'Surface',
          count: 'Total',
          element: 'Element',
          exportSpecification: 'Export Specification',
          exportSpecificationPerElement: 'Export Specification per element',
          incorrectObjectsDataSetWarning:
            '{0} objects missing (correct) data (missing column values: module_id, element_id, element_type, object_type)',
          table: {
            objectType: 'Object Type',
            NLSFB: 'NLSFB',
            numberOf: 'number of',
            total: 'Total',
          },
        },
        groupName: 'Requirement Groups',
        groupNameShort: 'Groups',
        mrpShort: 'PAP',
        groupItemName: 'Objects and Elements',
        objectTable: {
          headers: {
            code: 'Code',
            name: 'Name',
            desc: 'Description',
          },
        },
        addNewGroup: 'New Group',
        addNewItem: 'New Item',
        importItems: 'Import',
      },
      moduleDashboard: {
        phases: 'Building Phases',
        colorPhases: 'Color phases in model',
        types: 'Module types',
        colorTypes: 'Color types in model',
        togglePhases: 'Toggle Phases',
        toggleModuleTypes: 'toggleModuleTypes',
        scanQR: 'Scan QR code',
        twoD: 'Open 2D Drawings',
      },
      wvb: {
        importTitle: 'Import',
        importText: 'Do you want to import %{count} modules',
        importNonText: 'Do you want to import %{count} non module objects',
        importBtn: 'import',
        modelImportWarningModules:
          'Imported %{modules} modules and found %{modelModules} in model',
        modelImportWarningNonModules:
          'Imported %{modules} non modules and found %{modelModules} in model',
        moduleItems: 'Module Types',
        phases: 'Phases',
        placementSequence: 'Placement sequence',
        assemblySequence: 'Assembly sequence',
        switchUp: 'Switch upwards',
        switchDown: 'Switch downwards',
        colorPhases: 'Color phases within model',
        isolateModules: 'Isolate modules without a phase',
        isolateNonModules: 'Isolate non module objects',
        resetPhase: 'Reset %{phase}',
        colorModules: 'Color module types within model',
        colorGradient: 'Color %{mode} within model',
        configure: 'Configure %{mode}',
        clearPlacementSequence: 'Clear placement sequence',
        clearAssemblySequence: 'Clear assembly sequence',
        rebasePlacementSequence: 'Rebase placement sequence',
        rebaseAssemblySequence: 'Rebase assembly sequence',
        clearPhase: 'Clear {0}',
        clearPhases: 'ClearPhases',
        toggleModuleTypes: 'Toggle module types',
        insert: 'Insert module on position',
        insertModule: 'Insert moduleId on position',
        switchModule: 'Switch module',
        switchModuleOn: 'Switch %{moduleId} on position',
        switchModuleWithinModel: 'Switch modules within model',
        undoPS: 'Undo highest placement sequence ( %{moduleId} )',
        undoAS: 'Undo highest assembly sequence ( %{moduleId} )',
        playerToggle: 'Toggle player',
        exportExcel: 'Export to excel',
        notifications: {
          confirmResetAS:
            'Are you sure you want to reset the assembly sequence?',
          successResetPhase: 'Successfully reset %{phaseName}',
          moduleSwitchedPosition:
            'Module: %{module_id} switched to position %{position}',
          moduleInsertedPosition:
            'Module: %{module_id} inserted to position %{position}',
          confirmResetPhase:
            'Are you sure you want to reset the placement sequence for phase: %{phase}?',
          confirmResetProductionOrder:
            'Are you sure you want to reset the assembly sequence?',
          buildOrderReset: 'Placement sequence reset',
          productionOrderReset: 'Assembly sequence reset',
          moduleSetOnPosition:
            'Module %{module_id} set on position %{position}',
          cannotBeLower: 'Order cannot be less than 1',
          importSuccess:
            'Successfully imported %{modules} modules and %{nonModules} non module objects',
          rebaseAssemblySequence: 'Successfully rebased the assembly sequence',
          rebasePlacementSequence:
            'Successfully rebased the placement sequence',
        },
      },
      bokPlanning: {
        navigation: {
          overview: 'Overview',
          modules: 'Modules',
          bokIndeling: 'Trestle Planning',
        },
        player: {
          colorElementSequence: 'Color sequence in model',
          configureElementSequence: 'Configure element sequence',
        },
        table: {
          resetElementSequence: 'Reset element sequence',
        },
        notifications: {
          confirmResetElementSequence:
            'Are you sure you want to reset the element sequence for this module',
        },
        schedule: {
          week: `week {0}`,
          assemblyWeek: 'Assembly Week',
          assemblySpeed: 'Assembly location workload',
          autoSchedule: 'Auto Schedule',
          confirmGenerate:
            'Are you sure you want to auto generate the entire planning?',
          removeData: 'This will remove all current data',
        },
        detailDialog: {
          layout: 'Layout',
          projectName: 'Project name',
          projectNumber: 'Project number',
          entryDate: 'Entry date',
          exitDate: 'Exit date',
          assemblyHall: 'Assembly hall',
          trestles: '{0} of {1}',
          header: 'Trestle layout',
          saveLayout: 'Save Trestle Layout',
          loadViewer: 'Load viewer',
          clearSearch: 'Clear Search',
          modules: 'Modules',
        },
        modules: {
          ASHall: 'Assembly Hall',
          ASLane: 'Assembly Lane',
          ASLocation: 'Assembly location',
          ASStart: 'Assembly start date',
          ASEnd: 'Assembly end date',
          module: 'Module',
          elementId: 'Element ID',
          elementType: 'Element Type',
          AS: 'Assembly Sequence',
        },
      },
    },
  },
  nl: {
    weekDays: {
      monday: 'Maandag',
      tuesday: 'Dinsdag',
      wednesday: 'Woensdag',
      thursday: 'Donderdag',
      friday: 'Vrijdag',
      saturday: 'Zaterdag',
      sunday: 'Zondag',
    },
    general: {
      close: 'Sluit',
      open: 'Open',
      cancel: 'Cancel',
      save: 'Opslaan',
      delete: 'Verwijderen',
      edit: 'Aanpassen',
      insert: 'Invoegen',
      generate: 'Genereren',
      switch: 'Wisselen',
      start: 'Start',
      add: 'Toevoegen',
      update: 'Update',
      date: 'Datum',
      week: 'Week',
      today: 'Vandaag',
      hour: 'Uur',
      import: 'Importeer',
      numberOfAbbreviation: 'st.',
      openInNewTab: 'Openen in nieuw tabblad',
      code: 'Code',
      label: 'Label',
      tables: 'tabellen',
      version: 'version',
      create: 'aanmaken',
      title: 'titel',
      type: 'type',
      status: 'status',
      priority: 'prioriteit',
      description: 'omschrijving',
    },
    system: {
      apps: {
        single: 'App',
        multi: 'App',
      },
      model: {
        single: 'Model',
        multi: 'Models',
      },
      licenses: {
        single: 'Licentie',
        multi: 'Licenties',
        selectMsg: 'Selecteer uw licentie',
      },
      sessions: {
        single: 'sessie',
        multi: 'sessies',
      },
      tasks: {
        single: 'Taak',
        multi: 'taken',
        overdue: 'Deze taak is te laat',
        due: 'Vervalt',
        dueDate: 'deadline',
        notifications: {
          noAutomationId: 'Automation id niet gevonden in taak',
          noProject: 'Geen project geselecteerd',
          fillTitle: 'Voeg titel toe',
          addUser: 'Voeg een gebruiker toe',
          createRfi: `RFI '%{title}' aangemaakt`,
          createTask: `Successfully created task '%{title}'`,
          updateRfi: `RFI '%{title}' geupdate`,
          updateTask: `Successfully updated task '%{title}'`,
        },
        close: 'Close tasks',
        createdBy: 'Gemaakt door',
        createNew: 'Created new task',
        current: 'Current tasks',
        executedBy: 'Uitgevoerd door',
        unplannedTasks: 'Ongeplande taken',
        assignedUser: 'Toegekend aan',
        status: 'Status',
        dueDateChanged: 'Einddatum veranderd van %{A} naar %{B}',
        statusChanged: 'Status veranderd van %{A} naar %{B}',
        assignedUserChanged:
          'Toegewezen gebruiker veranderd van %{A} naar %{B}',
        newTaskButton: 'Nieuwe taak',
        noTasksYet: 'No tasks yet',
        deleteConfirm: 'Weet je het zeker dat je %{msg} wilt verwijderen?',
        deleteCountTasks: 'no taken | taak | {count} taken',
        chooseMethod:
          'Maak een keuze tussen het verwijderen van de taken en eventueel hun subtaken',
        withSub: 'Met subtaken',
        deleteSelectedTasks: 'Verwijder geselecteerde taken',
        addDescription: 'Please add a task description here',
        createRFI: 'RFI-optie maken',
        createDrawing: 'Create Drawing',
        rfi: {
          single: 'RFI',
          multi: "RFI's",
        },
      },
      projects: {
        single: 'Project',
        multi: 'Projecten',
        tables: {
          revisionNotFound: 'Revisie niet gevonden',
          revisionCompareNotFound: 'Revisie om te vergelijken niet gevonden',
          revisionRestoreConfirmTitle:
            'Weet je zeker dat je de tabel wilt terugzetten naar deze revisie?',
          revisionRestoreConfirmDescription:
            'Huidige revisie wordt opgeslagen als nieuwe revisied. Geselecteerde revisie records worden teruggezet.',
        },
      },
      appBar: {
        projectOverview: 'Project overzicht',
        ProjectAppsMenu: 'Project apps menu',
        unpin: 'Unpin navigatiebalk',
        pin: 'Pin navigatiebalk',
        changeEnv: 'Wissel omgeving',
        profSett: 'Profiel instellingen',
        accAdmin: 'Gebruikersbeheer',
        knowledgeBase: 'Kennis bank',
        WF: 'Workflows',
        Support: 'Ondersteuning',
        buildInfo: 'Versie information',
        logOut: 'Uitloggen',
        whatsNew: "What's new",
        documentation: 'Documentatie',
      },
      buildInfo: {
        RlNr: 'Versienummer',
        RlDate: 'Uitgebracht op',
        CV: 'Intern versienummer',
      },
      taskOverview: {
        error: {
          getUsers:
            'Er is iets fout gegaan bij het ophalen van projectgebruikers',
        },
        myTasks: 'Mijn taken',
        filters: 'Taak filters',
        filterTasks: 'Filter taken',
        reload: 'Herlaad taken',
        close: 'Sluit taken',
        overview: 'Taak overzicht',
      },
      profile: {
        currentPW: 'Huidig wachtwoord',
        EnterCurrentPW: 'Vul huidig wachtwoord in',
        newPW: 'Nieuw wachtwoord',
        enterNewPW: 'Vul nieuw wachtwoord in',
        repeatNewPW: 'Herhaal nieuw wachtwoord',
        firstName: 'Voornaam',
        lastName: 'Achternaam',
        email: 'Email',
        descFirst: 'Uw voornaam',
        descLast: 'Uw achternaam',
        descEmail: 'Uw werkemail',
        taskDigestInfo:
          'Schakel in om dagelijks een takenoverzicht te ontvangen per email.',
        notifications: {
          match: 'Wachtwoorden moeten overenkomen',
          differentPW:
            'Neuw wachtwoord moet anders zijn dan uw huidige wachtwoord',
          pWChanged: 'Wachtwoord is veranderd',
          noPWChanged: 'Wachtwoord is niet veranderd',
          pwChallenge:
            'Min. 8 karakters met ten minste een hoofdletter, een nummer en een speciaal karakter en geen spaties',
          pwExpires:
            'Je wachtwoord verloopt in %{passwordExpires} dag(en), reset je wachtwoord nu',
        },
        language: 'Taal',
        company: 'Bedrijf',
        languages: {
          EN: 'Engels',
          NL: 'Nederlands',
          DE: 'Duits',
        },
        tokens: {
          newToken: 'Nieuwe forgeAccessToken',
          addNewToken: 'Voeg nieuwe forgeAccessToken toe',
          appName: 'App naam',
          notifications: {
            confirmRemoveToken:
              'Weet u zeker dat u de forgeAccessToken wilt verwijderen voor app: %{forgeAccessToken}?',
          },
          table: {
            name: 'Applicatie',
            cId: 'Client ID',
            cSecret: 'Client Secret',
          },
        },
      },
      sbsTree: {
        sbsObj: 'SBS Objecten',
        noSessions: 'Er zijn geen sessies verbonden aan dit SBS object',
        toggleSidebar: 'Schakel SBS sidebar',
      },
      sbsOverview: {
        reload: 'Reload {item}',
        selectSBS: 'Selecteer SBS object in de sidebar',
        notSelected: 'Geen SBS object geselecteerd',
        noFound: 'Geen {item} gevonden als geselecteerd SBS object',
      },
      maintenance: {
        main: 'ANT wordt onderhouden',
        tuning: 'We zijn bezig met het tweaken van het systeem.',
        fixUp: 'Alles zal weer werken in een paar minuten, geen zorgen',
        auth: 'Onderhouds authenticatie',
        secret: 'Voer onderhouds "secret" in',
      },
    },
    modules: {
      stationDashboard: {
        notApplicable: 'nvt',
        issue: 'Nee',
        issues: 'Opmerkingen',
        finish: 'Ja',
        dialogTitle: 'Probleem melden',
        captures: "Foto's",
      },
      forge: {
        toggleGhosting: 'Wissel doorzichtigheid',
      },
      gisViewer: {
        chooseSketchColor: 'Kies kleur',
      },
      fitGap: {
        reload: 'Herlaad data',
        createNewGap: 'Create new gap',
        noRecords: 'Geen items gevonden',
      },
      assemblyPlanning: {
        sidebasrHeader: 'Assemblage aanvraag',
        generateOption: 'Genereer optie',
        selectPlanningOption: 'Selecteer planning optie',
        normal: 'Normaal',
        pendulum: 'Pendulum',
        unitsPerDay: 'Units per dag',
        laneId: 'Lijn %{lane}',
        switchModules: 'Wissel Modules',
        switchModuleWith: 'Wissel module: %{moduleA} met module %{moduleB}',
        switchModeActive: 'Wissel modus activief',
        submitAssemblyPlanningRequest: 'Doe aanvraag',
        generateExcel: 'Genereer Excel',
        moduleId: 'Module %{moduleId}',
        moduleStartPrevWeek: 'Module is vorige week gestart',
        moduleFinsihedNextWeek: 'Module volgende week klaar',
        workload: 'Assemblage tijd',
        PS: 'Plaatings volgorde',
        AS: 'Assemblage volgorde',
        loadViewer: 'Laad 3D viewer',
      },
      projectGantt: {
        headers: {
          number: 'Nummer',
          title: 'Titel',
          duration: 'Dagen',
          status: 'Status',
          startDate: 'Start',
          endDate: 'Eind',
          party: 'Partij',
          predecessors: 'Voorgangers',
          successors: 'Opvolgers',
          parent: 'Bovenliggende',
        },
        filters: {
          allTasks: 'Alle taken',
          myTasks: 'Mijn taken',
        },
        ganttZoomLevels: {
          yearly: 'Jaarlijks',
          monthly: 'Maandelijks',
          weekly: 'Weekelijks',
          daily: 'Dagelijks',
        },
      },
      projectPlanning: {
        phases: 'Fases',
        reset: 'Reset Fases',
        addPhase: 'Voeg fase toe',
        editPhase: 'Pas fase aan',
        phaseType: 'Fase type',
        phaseName: 'Fase naam',
        phaseNumber: 'Fase nummer',
        start: 'Start',
        end: 'End',
        notifications: {
          confirmDelete: 'Weet u zeker dat u %{task} wilt verwijderen?',
          confirmReset: 'Weet u zeker dat u alle fases wilt terugzetten?',
        },
        phaseTypes: {
          wvb: 'Werkvoorbereiding',
          prd: 'Productie',
          assembly: 'Assemblage',
          placement: 'Plaatsing',
        },
      },
      projectsPlanning: {
        comments: 'Opmerkingen',
        customer: 'Klant',
        general: 'Algemeen',
        details: 'Project details',
        planningOverview: 'Planningsoverzicht',
        progress: 'Voortgang',
        roles: 'Rollen',
        redFlags: 'Red Flags',
        fieldNames: {
          customerCompany: 'Company',
          customerContact: 'SAP Nummer',
          buildSystem: 'Bouw systeem',
          modules: 'Modules',
          temporary: 'Tijdelijk',
          type: 'Type',
          userFunction: 'User function',
          budgetForecast: 'Forecasted budget',
          status: 'Status',
          moduleState: 'Module status',
          permitResponsible: 'Verantwoordelijk vergunning',
        },
      },
      BillOfMaterial: {
        container: {
          assembly: 'Assemblage',
          element: 'Elementen',
          object: 'Objecten',
          group: 'Groepen',
          addGroup: 'Nieuwe Groep',
          unlinked: 'Onverbonden',
          groupHeader: 'Groepen',
          newGroup: 'Voeg groep toe',
        },
        assemblySchedule: {
          modulePropsOf: `Module eigenschappen van {0}`,
          week: `Week {0}`,
          assemblyWeek: 'Productie Week',
          assemblySpeed: 'Productie Snelheid',
          autoSchedule: 'Automatisch inroosteren',
          duplicateLaneWarning: '{0} is binnen hetzelfde rooster',
          duplicateTitle: 'Modules binnen dezelfde start/eind tijd',
          overflowWarning: 'Module loopt door in volgende week',
          incorrectAssemblySequenceValue:
            'Heeft verkeerde assemblage volgorde waarde',
          incorrectAssemblyWorkloadValue:
            'Heeft verkeerde assemblage tijd waarde',
          incorrectDataBtn: 'Ga naar WVB',
          asDialog: {
            title: 'Overschrijven van rooster',
            description:
              'Wees gewaarschuwd, al uw gemaakte veranderingen worden overschreven!',
            cancel: 'Annuleer',
            schedule: 'Inroosteren',
          },
        },
        elementSchedule: {
          createWorkPackage: 'Maak werkpakket aan',
          WorkPackageSubmitted: 'Werkpakket aangevraagd',
          exportWithdrawalStatement: 'Exporteer uittrekstaat',
          exportWeeklyStatement: 'Exporteer weekstaat',
          exportWeeklyPlanning: 'Exporteer weekplanning',
          exportModuleContents: 'Exporteer module inhoud',
          exportGroupQrCodes: 'Download QR codes',
          downloadToXlsx: 'Download inhoud naar xlsx',
          title: 'Element Rooster',
          module: 'Module',
          incorrectObjectsDataSetWarning:
            '{0} elementen hebben een incorrecte data set (missende kolom waardes: module_id, element_id, element_type, object_type)',
          headers: {
            elements: `Elementen (totaal: {0})`,
            week: `Week {0} (totaal: {1})`,
          },
          qs: 'Hoeveelhedenstaat %{name}',
          production: 'Productie',
          placement: 'Plaatsing',
          quant: 'Hoeveelheden',
          show3D: 'Bekijk in 3D model',
          table: {
            elementType: 'Element type',
            category: 'Categorie',
            total: 'Totaal',
          },
        },
        objectSchedule: {
          title: 'Object Rooster',
          downloadContent: 'Download inhoud naar xlsx',
          downloadObjects: 'Download objecten naar xlsx',
          week: `Week {0} (totaal: {1})`,
          weekValue: `week_{0}`,
          Objectcode: 'Code',
          name: 'Naam',
          description: 'Omschrijving',
          Breedte: 'Breedte',
          Lengte: 'Lengte',
          Hoogte: 'Hoogte',
          Oppervlakte: 'Oppervlakte',
          count: 'Totaal',
          exportSpecification: 'Exporteer Specificatie blad',
          exportSpecificationPerElement:
            'Exporteer Specificatie blad per onderdeel',
          incorrectObjectsDataSetWarning:
            '{0} hebben een incorrecte data set (missende kolom waardes: module_id, element_id, element_type, object_type)',
          element: 'Element',
          table: {
            objectType: 'Object type',
            name: 'Name',
            desc: 'Beschrijving',
            numberOf: 'aantal',
            total: 'Totaal',
          },
        },
        groupName: 'Behoefte groepen',
        groupNameShort: 'Groepen',
        mrpShort: 'Leveringsplanning',
        groupItemName: 'Objecten en Elementen',
        objectTable: {
          headers: {
            code: 'Code',
            name: 'Naam',
          },
        },
        addNewGroup: 'Nieuwe Groep',
        addNewItem: 'Nieuw Item',
        importItems: 'Importeer',
      },
      moduleDashboard: {
        phases: 'Bouwfases',
        colorPhases: 'Kleur fases in mdoel',
        types: 'Module types',
        colorTypes: 'Kleur module types',
        togglePhases: 'Schakel fases in',
        toggleModuleTypes: 'Schakel module types in',
        scanQR: 'Scan QR code',
        twoD: 'Open 2D Tekeningen',
      },
      wvb: {
        importTitle: 'Importeer',
        importText: 'Wilt u %{count} modules importeren',
        importNonText: 'Wilt u %{count} niet module objecten importeren',
        importBtn: 'importeer',
        modelImportWarningModules:
          '%{modules} modules geimporteerd en %{modelModules} gevonden in model',
        modelImportWarningNonModules:
          '%{modules} niet-modules geimporteerd en %{modelModules} gevonden in model',
        modelFoundMessage:
          '%{modules} modules en %{nonModules} niet module objecten gevonden in model',
        moduleItems: 'Module Types',
        phases: 'Fases',
        placementSequence: 'Plaatsingsvolgorde',
        assemblySequence: 'Assemblage volgorde',
        switchUp: 'Naar boven wisselen',
        switchDown: 'Naar onder verschuiven',
        isolateModules: 'Isoleer modules zonder fase',
        isolateNonModules: 'Isoleer niet-module objecten',
        resetPhase: 'Zet  %{phase} terug',
        colorModules: 'Kleur module types in model',
        colorPhases: 'Kleur fasen in model model',
        colorGradient: 'Kleur %{mode} in model',
        configure: 'configureer %{mode}',
        clearPlacementSequence: 'Verwijder huidige plaatsingsvolgorde',
        clearAssemblySequence: 'Verwijder huidige assemblage volgorde',
        rebasePlacementSequence: 'Herstructureer plaatsingsvolgorde',
        rebaseAssemblySequence: 'Herstructureer assemblage volgorde',
        clearPhase: 'Schoon %{phase} op',
        clearPhases: 'Schoon bouwfases op',
        toggleModuleTypes: 'Wissel module types',
        insert: 'Voeg module op plek',
        insertModule: 'voeg moduleId in op position',
        switchModule: 'Wissel module',
        switchModuleOn: 'Wissel %{moduleId} op positie',
        switchModuleWithinModel: 'Wissel modules in model',
        undoPS: 'Maak hoogtste plaatingsvolgorde ongedaan ( %{moduleId} )',
        undoAS: 'Maak hoogste assemblagevolorde ongeddaan ( %{moduleId} )',
        playerToggle: 'Activeer speler',
        exportExcel: 'Exporteer naar excel',
        notifications: {
          confirmResetAS:
            'Weet u zeker dat u de assemblage volgorde wilt terugzetten?',
          successResetPhase: 'Succesvol fase %phaseName} teruggezet',
          moduleSwitchedPosition:
            'Module: %{module_id} verwisseld naar positie %{position}',
          moduleInsertedPosition:
            'Module: %{module_id} ingevoegd op positie %{position}',
          confirmResetPhase:
            'Weet u zeker dat u de plaatsingsvolgorde wilt opschonen voor bouwfase: %{phase}?',
          confirmResetProductionOrder:
            'Weet u zeker dat u de assemblagevolgorde wilt opschonen?',
          buildOrderReset: 'Plaatsingsvolgorde opgeschoont',
          productionOrderReset: 'Productie volgorde opgeschoont',
          moduleSetOnPosition:
            'Module %{module_id} op positie %{position} geplaatst',
          cannotBeLower: 'Volgorde kan niet lager zijn dan 1',
          importSuccess:
            '%{modules} modules en %{nonModules} niet module objecten geimporteerd',
          rebaseAssemblySequence: 'De assemblage volgorde is hergestructureerd',
          rebasePlacementSequence: 'De plaatsingsvolgorde is hergestructureerd',
        },
      },
      bokPlanning: {
        navigation: {
          overview: 'Overzicht',
          modules: 'Modules',
          bokIndeling: 'BOK planning',
        },
        player: {
          colorElementSequence: 'Kleur volgorde in model',
          configureElementSequence: 'Configureer element volgorde',
        },
        table: {
          resetElementSequence: 'Zet element volgorde terug',
        },
        notifications: {
          confirmResetElementSequence:
            'Weet u zeker dat u de element volgorde wilt terugzetten voor deze module?',
        },
        schedule: {
          week: `Week {0}`,
          assemblyWeek: 'Assemblage Week',
          assemblySpeed: 'Assemblage locatie werkbare tijd',
          autoSchedule: 'Automatisch plannen',
          confirmGenerate:
            'Weet u zeker dat u een nieuwe plannign wilt genereren?',
          removeData: 'Dit zal de oude data verwijderen',
        },
        detailDialog: {
          layout: 'Indeling',
          projectName: 'Project naam',
          projectNumber: 'Project nummer',
          entryDate: 'Start datum',
          exitDate: 'Eind datum',
          assemblyHall: 'Assemblage hal',
          trestles: '{0} van {1}',
          header: 'Bokindeling',
          saveLayout: 'Sla bokindeling op',
          loadViewer: 'Laad viewer',
          clearSearch: 'Nieuw',
          modules: 'Modules',
        },
        modules: {
          ASHall: 'Assemblage hal',
          ASLane: 'Assembly Lijn',
          ASLocation: 'Assemblage locatie',
          ASStart: 'Assemblage start datum',
          ASEnd: 'Assemblage eind datum',
          module: 'Module',
          elementId: 'Element ID',
          elementType: 'Element Type',
          AS: 'Assemblage volgorde',
        },
      },
    },
  },
};

export default messages;
