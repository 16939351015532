import httpClient from '../httpClient';

const getWorkflows = () => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/builder/workflows`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createWorkflow = (body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/builder/workflow`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateWorkflow = (workflowId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/builder/workflow/${workflowId}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteWorkflow = (workflowId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/builder/workflow/${workflowId}`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getWorkflow = (workflowId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/builder/workflow/${workflowId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const importWorkflow = (body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/builder/import/workflow`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export {
  getWorkflows,
  createWorkflow,
  updateWorkflow,
  deleteWorkflow,
  getWorkflow,
  importWorkflow,
};
