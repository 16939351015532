import Vue from 'vue';
import { getModuleData } from '@/services/api/module.api';
import { waitForVuex } from '@/utils/vuex';
import {
  createTask,
  createTaskRelation,
  deleteTask,
  deleteTaskRelation,
  deleteTasks,
  queryTasksV2,
  updateTask,
} from '@/services/api/task.api';

const state = {
  data: {},
  tasks: [],
  advancedTaskFilters: [],
};
const mutations = {
  set_module_data(state, data) {
    state.data = data;
  },
  set_tasks(state, data) {
    state.tasks = data;
  },
  tasks_add_advanced_filter(state, filter) {
    state.advancedTaskFilters.push(filter);
  },
  tasks_remove_advanced_filter(state, index) {
    state.advancedTaskFilters.splice(index, 1);
  },
  tasks_reset_advanced_filters(state) {
    state.advancedTaskFilters = [];
  },
  task_gantt_create_success(state, task) {
    state.tasks.push(task);
  },
  task_gantt_update_success(state, updatedTask) {
    const index = state.tasks.findIndex((t) => t.id === updatedTask.id);
    if (index !== -1) {
      Vue.set(state.tasks, index, {
        ...state.tasks[index],
        ...updatedTask,
      });
    }
  },
  task_delete_success(state, deletedId) {
    state.tasks = [...state.tasks.filter((task) => task.id !== deletedId)];
  },
};
const actions = {
  fetchModuleData: waitForVuex(
    'module.tasks-gantt.global.init',
    async ({ commit }, { projectId, moduleId, sessionId }) => {
      getModuleData(projectId, moduleId, sessionId)
        .then((data) => {
          commit('set_module_data', data);
        })
        .catch((error) => {
          this.commit(
            'showNotification',
            {
              content: error.message,
              color: 'error',
            },
            { root: true }
          );
        });
    }
  ),
  fetchTasks: waitForVuex(
    'module.tasks-gantt.tasks.get',
    async ({ commit, getters, rootGetters }, filters = []) => {
      try {
        const response = await queryTasksV2(rootGetters.selectedLicense.id, [
          ...[
            {
              column: 'project',
              operator: '=',
              values: [rootGetters.project.id],
            },
          ],
          ...filters,
        ]);
        commit('set_tasks', response.tasks);
      } catch (e) {
        commit(
          'showNotification',
          {
            content: e.message,
            color: 'error',
          },
          { root: true }
        );
      }
    }
  ),
  createTask: waitForVuex(
    'module.tasks-gantt.tasks.create',
    ({ commit }, { body }) => {
      return new Promise((resolve, reject) => {
        return createTask(body)
          .then((response) => {
            commit('task_gantt_create_success', response);
            resolve(response);
          })
          .catch((error) => {
            commit(
              'showNotification',
              {
                content: error.message,
                color: 'error',
              },
              { root: true }
            );
            reject(error);
          });
      });
    }
  ),
  updateTask: waitForVuex(
    'module.tasks-gantt.tasks.update',
    ({ commit }, { taskId, body }) => {
      return new Promise((resolve, reject) => {
        return updateTask(taskId, body)
          .then((response) => {
            commit('task_gantt_update_success', response);
            commit(
              'showNotification',
              {
                content: `Successfully updated task`,
                color: 'success',
              },
              { root: true }
            );
            resolve(response);
          })
          .catch((error) => {
            commit(
              'showNotification',
              {
                content: error.message,
                color: 'error',
              },
              { root: true }
            );
            reject(error);
          });
      });
    }
  ),
  deleteTask({ commit }, { taskId }) {
    return new Promise((resolve, reject) => {
      return deleteTask(taskId)
        .then(() => {
          commit('task_delete_success', taskId);
          resolve();
        })
        .catch((error) => {
          commit(
            'showNotification',
            {
              content: error.message,
              color: 'error',
            },
            { root: this }
          );
          reject();
        });
    });
  },
  deleteTasks: waitForVuex('tasks.gantt.delete', ({ commit }, { tasks }) => {
    return new Promise((resolve, reject) => {
      return deleteTasks(tasks)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          commit(
            'showNotification',
            {
              content: error.message,
              color: 'error',
            },
            { root: true }
          );
          reject(false);
        });
    });
  }),
  createTaskRelation: waitForVuex(
    'task.gantt.relation.create',
    ({ commit }, { previousId, taskId }) => {
      return new Promise((resolve, reject) => {
        return createTaskRelation(previousId, {
          task: taskId,
        })
          .then((task) => {
            commit('task_gantt_update_success', task);
            resolve(task);
          })
          .catch((error) => {
            commit(
              'showNotification',
              {
                content: error.message,
                color: 'error',
              },
              { root: true }
            );
          });
      });
    }
  ),
  deleteTaskRelation: waitForVuex(
    'task.gantt.relation.delete',
    ({ commit }, { taskId, relationId }) => {
      return new Promise((resolve, reject) => {
        return deleteTaskRelation(taskId, relationId)
          .then((task) => {
            commit('task_gantt_update_success', task);
            resolve(task);
          })
          .catch((error) => {
            commit(
              'showNotification',
              {
                content: error.message,
                color: 'error',
              },
              { root: true }
            );
          });
      });
    }
  ),
};
const getters = {
  data: (state) => state.data,
  tasks: (state) => state.tasks,
  advancedTaskFilters: (state) => state.advancedTaskFilters,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

function createAdvancedFilterBody(filters) {
  let body = {
    advanced_filters: [],
  };
  filters.forEach((filter) => {
    let values = [];
    if (
      ['assigned_to', 'project', 'label', 'created_by'].includes(
        filter.selectedOption.value
      )
    ) {
      values = filter.selectedValues.map((item) => item.id);
    } else if (['priority', 'status'].includes(filter.selectedOption.value)) {
      values = filter.selectedValues.map((item) => item.title);
    } else {
      values = filter.selectedValues;
    }
    body.advanced_filters.push({
      column: filter.selectedOption.value,
      operator: filter.selectedOperator,
      values: values,
    });
  });
  return body;
}
