<template>
  <div class="px-1">
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-btn
          :style="{ cursor: loading ? 'default' : 'pointer' }"
          color="#ffa900"
          icon
          small
          v-bind="attrs"
          v-on="on"
          @click.stop="onChange"
        >
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'AntFavoriteAction',
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSelected: false,
    };
  },
  computed: {
    icon() {
      return this.isSelected ? 'mdi-star' : 'mdi-star-outline';
    },
    tooltipText() {
      return this.isSelected ? 'Remove from favorites' : 'Add to favorites';
    },
  },
  watch: {
    selected: {
      handler(value) {
        this.isSelected = value;
      },
      immediate: true,
    },
  },
  methods: {
    onChange() {
      if (this.loading) return;
      this.$emit('on-change', this.isSelected);
      this.isSelected = !this.isSelected;
    },
  },
};
</script>

<style scoped></style>
