import httpClient from '../httpClient';

const getWorkflowBlocks = (workflowId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/builder/workflow/${workflowId}/blocks`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createWorkflowBlock = (workflowId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/builder/workflow/${workflowId}/block`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateWorkflowBlock = (workflowId, blockId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/builder/workflow/${workflowId}/block/${blockId}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteWorkflowBlock = (workflowId, blockId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/builder/workflow/${workflowId}/block/${blockId}`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getWorkflowBlock = (workflowId, blockId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/builder/workflow/${workflowId}/block/${blockId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getWorkflowBlockDocument = (workflowId, blockId, documentId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(
        `/builder/workflow/${workflowId}/block/${blockId}/document/${documentId}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export {
  getWorkflowBlocks,
  createWorkflowBlock,
  updateWorkflowBlock,
  deleteWorkflowBlock,
  getWorkflowBlock,
  getWorkflowBlockDocument,
};
