<template>
  <transition-group class="d-flex flex-1" name="fade" tag="div">
    <div key="content" class="d-flex flex-1">
      <div v-if="!$wait.is('project.get')" class="project-overview-container">
        <div v-if="project.generalPermissions.workflows" class="left-container">
          <div class="project-sessions">
            <div class="ant-header mb-0">Sessions</div>
            <div class="sessions-container" data-v-step="ProjectDash2">
              <div v-if="filteredSessions.length > 0">
                <session-item
                  v-for="session in filteredSessions"
                  :key="session.id"
                  :session="session"
                  animate
                />
              </div>
              <div
                v-else
                style="text-align: center; font-style: italic; font-size: 12px"
              >
                No Sessions found within project
              </div>
            </div>
          </div>
        </div>
        <div class="middle-container">
          <div class="project-modules">
            <div class="d-flex">
              <v-text-field
                v-model="moduleSearch"
                clearable
                dense
                filled
                flat
                hide-details
                placeholder="Type here to search"
                prepend-inner-icon="mdi-magnify"
              />
            </div>
            <div
              class="mt-5 overflow-y-auto overflow-x-hidden"
              data-v-step="ProjectDash3"
            >
              <v-expansion-panels
                v-if="!moduleSearch"
                v-model="modulePanels"
                accordion
                flat
                multiple
                tile
              >
                <v-expansion-panel
                  v-for="(category, index) in getModuleCategories"
                  :key="index"
                  class="bg-transparent"
                >
                  <v-expansion-panel-header :ripple="false">
                    {{ category }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="project-modules-container">
                      <ant-module-tile
                        v-for="module in modules.filter(
                          (item) => item.category === category
                        )"
                        :key="module.id"
                        :module="module"
                        @click="navigateToModule(module)"
                      >
                      </ant-module-tile>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel
                  v-if="
                    $can('access', 'project-links') ||
                    project.external_links.length > 0
                  "
                  class="bg-transparent"
                >
                  <v-expansion-panel-header :ripple="false">
                    Links
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="project-modules-container">
                      <ant-module-tile
                        v-if="$can('create', 'project-links')"
                        :module="{
                          icon: 'mdi-link-variant-plus',
                          name: 'new link',
                        }"
                        @click="linkDialog = true"
                      >
                      </ant-module-tile>

                      <ant-module-tile
                        v-for="link in projectLinks"
                        :key="link.name"
                        :module="link"
                        class="module-tile"
                        @click="openExternalLink(link)"
                      >
                        <template
                          v-if="$can('update', 'project-links')"
                          #module-tile-actions
                        >
                          <div class="link-actions">
                            <v-icon
                              class="ant-icon"
                              dense
                              @click.stop="setupLinkEdit(link)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-divider class="my-2" />
                            <v-icon
                              class="ant-icon"
                              dense
                              @click.stop="setupLinkDelete(link)"
                            >
                              mdi-delete
                            </v-icon>
                          </div>
                        </template>
                      </ant-module-tile>
                    </div>
                    <v-dialog
                      key="create-update-link"
                      v-model="linkDialog"
                      max-width="500px"
                      @click:outside="closeLinkDialogs"
                      @keydown.esc="closeLinkDialogs"
                    >
                      <v-card>
                        <v-card-title>
                          {{ linkItem.id !== undefined ? 'Update' : 'Create' }}
                          Link
                        </v-card-title>
                        <v-form ref="link-form" @submit.prevent>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" md="12" sm="6">
                                  <v-text-field
                                    v-model="linkItem.name"
                                    :rules="[rules.required]"
                                    label="name"
                                    placeholder="name"
                                  />
                                  <v-textarea
                                    v-model="linkItem.link"
                                    :rules="[rules.required]"
                                    filled
                                    label="url"
                                    placeholder="url"
                                  />
                                  <v-color-picker
                                    v-model="linkItem.color"
                                    hide-canvas
                                    hide-inputs
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </v-form>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn color="red" text @click="closeLinkDialogs">
                            Cancel
                          </v-btn>
                          <v-btn color="primary" @click="saveLink">
                            save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <delete-dialog
                      :dialog="linkDeleteDialog"
                      :title="`Are you sure you want to delete link '${linkItem.name}'`"
                      @closeDialog="closeLinkDialogs"
                      @deleteAction="deleteLink"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div v-else class="project-modules-container">
                <ant-module-tile
                  v-for="module in modules.filter((item) =>
                    item.name.toLowerCase().includes(moduleSearch)
                  )"
                  :key="module.id"
                  :module="module"
                  @click="navigateToModule(module)"
                >
                </ant-module-tile>
              </div>
            </div>
          </div>
        </div>
        <div class="right-container">
          <div class="project-general-information mt-9 mb-15">
            <project-card
              :project="project"
              class="project-card-3d"
              data-v-step="ProjectDash0"
              style="
                width: 100%;
                height: 150px;
                pointer-events: none !important;
              "
            />
          </div>
          <div class="d-flex flex-column flex-1">
            <div
              v-if="project.generalPermissions.tasks"
              class="ant-glass-background pa-4 d-flex align-center project-navigation-item mb-4"
              data-v-step="ProjectDash1"
              @click="$router.push({ name: 'tasks-gantt' })"
            >
              <v-icon>mdi-clipboard</v-icon>
              <v-divider class="mx-2" vertical />
              Project Tasks
            </div>
            <div
              v-if="project.generalPermissions.workflows"
              class="ant-glass-background pa-4 d-flex align-center project-navigation-item mb-4"
              data-v-step="ProjectDash4"
              @click="$router.push({ name: 'project-workflows' })"
            >
              <v-icon>mdi-source-repository-multiple</v-icon>
              <v-divider class="mx-2" vertical />
              Workflows
            </div>
            <div
              v-if="project.generalPermissions.tables"
              class="ant-glass-background pa-4 d-flex align-center project-navigation-item mb-4"
              data-v-step="ProjectDash5"
              @click="$router.push({ name: 'tables' })"
            >
              <v-icon>mdi-table-multiple</v-icon>
              <v-divider class="mx-2" vertical />
              Tables
            </div>
            <div
              v-if="project.generalPermissions.sbs"
              class="ant-glass-background pa-4 d-flex align-center project-navigation-item mb-4"
              data-v-step="ProjectDash6"
              @click="$router.push({ name: 'sbs-overview' })"
            >
              <v-icon>mdi-file-tree</v-icon>
              <v-divider class="mx-2" vertical />
              SBS dashboard
            </div>
            <div
              v-if="project.generalPermissions.labels"
              class="ant-glass-background pa-4 d-flex align-center project-navigation-item mb-4"
              data-v-step="ProjectDash7"
              @click="$router.push({ name: 'labels-management' })"
            >
              <v-icon>mdi-label-multiple-outline</v-icon>
              <v-divider class="mx-2" vertical />
              Labels
            </div>
            <v-spacer />
            <div
              v-if="$can('access', 'project-settings')"
              class="ant-glass-background pa-4 d-flex align-center project-navigation-item"
              data-v-step="ProjectDash8"
              @click="$router.push({ name: 'project-settings' })"
            >
              <v-icon>mdi-cog</v-icon>
              <v-divider class="mx-2" vertical />
              Project settings
            </div>
          </div>
        </div>
        <v-tour
          :callbacks="{
            onFinish: saveTourState,
            onSkip: saveTourState,
          }"
          :name="tourName"
          :steps="tourSteps"
        />
      </div>
      <div v-else class="d-flex justify-center align-center flex-grow-1">
        <ant-loading />
      </div>
    </div>
    <v-overlay
      v-if="isOverlayShown"
      key="overlay"
      :value="isOverlayShown"
      absolute
      z-index="20"
    >
      <v-card class="pa-6" light>
        <v-card-text>You not added to this project, connect first</v-card-text>
        <v-card-actions>
          <v-btn
            class="ma-auto"
            color="primary"
            @click="$router.push({ name: 'project-settings' })"
          >
            <v-icon>mdi-cog</v-icon>
            <v-divider class="mx-2" vertical />
            Project settings
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </transition-group>
</template>

<script>
import { mapGetters } from 'vuex';
import ProjectCard from '@/components/Dashboard/ProjectCard';
import { projectDashboardTour } from '@/lang/onboarding';
import moment from 'moment';
import AntLoading from '@/components/AntLoading';
import DeleteDialog from '@/components/DeleteDialog';
import SessionItem from '@/components/Project/SessionItem.vue';
import { getSessionProgress } from '@/utils';
import AntModuleTile from '@/components/AntModuleTile.vue';
import CookieService from '@/services/cookie';

export default {
  name: 'Overview',
  components: {
    AntModuleTile,
    SessionItem,
    DeleteDialog,
    AntLoading,
    ProjectCard,
  },
  data: () => {
    return {
      moduleSearch: null,
      tourName: 'projectOverviewTour',
      tourSteps: projectDashboardTour('en'),
      modulePanels: [],
      taskStatuses: [
        'open',
        'planned',
        'processing',
        'completed',
        'failed',
        'cancelled',
      ],
      linkDialog: false,
      linkDeleteDialog: false,
      linkItem: {},
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'projectTeams',
      'authenticatedUser',
      'isProjectAdmin',
      'links',
      'selectedLicense',
    ]),
    projectLinks() {
      return this.links.map((link) => {
        return {
          id: link.id,
          icon: 'mdi-link',
          name: link.name,
          link: link.link,
        };
      });
    },
    isOverlayShown() {
      return (
        !this.$wait.is('project.get') &&
        !this.project.is_member &&
        this.$can('access', 'project-settings')
      );
    },
    getModuleCategories() {
      return [...new Set(this.modules.map((item) => item.category))];
    },
    modules() {
      return this.project.modules
        .filter((module) => module.accessible)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    filteredSessions() {
      return this.project.sessions
        .filter((session) => {
          if (getSessionProgress(session) === 100) {
            let currentDate = moment();
            return currentDate.diff(moment(session.created_at), 'weeks') <= 2;
          } else {
            return true;
          }
        })
        .sort((a, b) => moment(a.created_at).diff(moment(b.created_at)));
    },
  },
  watch: {
    getModuleCategories: {
      immediate: true,
      handler(value) {
        this.modulePanels = [...this.getModuleCategories].map((k, i) => i);
      },
    },
  },
  mounted() {
    this.$store.dispatch('fetchProject').then(() => {
      if (this.isProjectAdmin) {
        this.$store.dispatch('fetchExternalLinks');
      }
      this.$store.dispatch('fetchProjectTeams');
      if (
        !CookieService._getCookie(this.tourName) &&
        this.selectedLicense.id === '911e928c-47f8-11ee-977b-0242ac120009'
      ) {
        this.$tours[this.tourName].start();
      }
    });
  },
  methods: {
    saveTourState() {
      CookieService._setCookie(this.tourName, 1);
    },
    getSessionProgress(session) {
      let total = session.jobs.length;
      let completed = session.jobs.filter(
        (job) => job.status === 'completed'
      ).length;

      return Math.floor((completed / total) * 100);
    },
    openExternalLink(externalLink) {
      window.open(externalLink.link, '_blank');
    },
    navigateToModule(module) {
      switch (module.category) {
        case 'General':
          this.$router.push({
            path: `/project/${this.project.slug}/${module.route}`,
          });
          break;
        default:
          this.$router.push({
            path: `/project/${this.project.slug}/module/${module.route}`,
          });
          break;
      }
    },
    setupLinkEdit(link) {
      this.linkItem = Object.assign({}, link);
      this.linkDialog = true;
    },
    setupLinkDelete(link) {
      this.linkItem = Object.assign({}, link);
      this.linkDeleteDialog = true;
    },
    closeLinkDialogs() {
      this.linkDialog = false;
      this.linkDeleteDialog = false;
      this.linkItem = Object.assign({}, {});
      this.$refs['link-form'].reset();
    },
    saveLink() {
      if (this.$refs['link-form'].validate()) {
        if (this.linkItem.id !== undefined) {
          // update link
          const body = {
            name: this.linkItem.name,
            link: this.linkItem.link,
            color:
              this.linkItem.color.hex !== undefined
                ? this.linkItem.color.hex
                : this.linkItem.color,
          };

          this.$store
            .dispatch('updateExternalLink', { linkId: this.linkItem.id, body })
            .then(this.closeLinkDialogs);
        } else {
          // create link
          const body = {
            name: this.linkItem.name,
            link: this.linkItem.link,
            color: this.linkItem.color.hex,
          };
          this.$store
            .dispatch('createExternalLink', { body })
            .then(this.closeLinkDialogs);
        }
      }
    },
    deleteLink() {
      this.$store
        .dispatch('deleteExternalLink', { linkId: this.linkItem.id })
        .then(this.closeLinkDialogs);
    },
  },
};
</script>

<style lang="scss" scoped>
.project-card-3d {
  transform-style: preserve-3d;
  border-radius: 32px;
  box-shadow:
    1px 1px 0 1px #f9f9fb,
    -1px 0 28px 0 rgba(34, 33, 81, 0.01),
    28px 28px 28px 0 rgba(34, 33, 81, 0.25);
  animation: flowing-card 10s infinite ease-in-out;
}

@keyframes flowing-card {
  0% {
    transform: rotateX(9deg) rotateZ(2deg);
  }
  50% {
    transform: translate3d(0px, -10px, 0px) rotateX(9deg) rotateZ(2deg);
    box-shadow:
      1px 1px 0 1px #f9f9fb,
      -1px 0 20px 0 rgba(34, 33, 81, 0.01),
      40px 40px 20px -10px rgba(34, 33, 81, 0.15);
  }
  100% {
    transform: rotateX(9deg) rotateZ(2deg);
  }
}

.project-overview-container {
  display: flex;
  flex: 1;
  padding: 20px;

  .left-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .project-sessions {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .sessions-container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        overflow-y: scroll;

        .session-wrapper {
          margin: 10px 0;
          padding: 10px;
          display: flex;
          transition: 200ms ease-out;
          cursor: pointer;

          &:hover {
            color: var(--v-primary-base);
            transform: translateX(10px);
          }
        }
      }
    }
  }

  .middle-container {
    flex: 2;
    margin: 0 20px;
    display: flex;
    flex-direction: column;

    .charts-container {
      display: flex;
      margin-bottom: 20px;

      div {
        margin: 0 10px;
        flex: 1;
      }
    }

    .project-modules {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: scroll;

      .project-modules-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: minmax(100px, auto);
        grid-gap: 1rem;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 15px;
      }
    }
  }

  .module-tile {
    .link-actions {
      position: absolute;
      top: 0;
      right: 5px;
      opacity: 0;
      transition: 200ms;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      width: 30px;
    }

    &:hover {
      .link-actions {
        opacity: 1;
      }
    }
  }

  .right-container {
    display: flex;
    flex: 1;
    flex-direction: column;

    .project-general-information {
      v-img {
        object-fit: cover;
      }

      .card-no-image-container {
        width: 100%;
        height: 100%;
        background: var(--v-primary-base);
        background: linear-gradient(
          45deg,
          var(--v-primary-base) 10%,
          var(--v-primary-lighten2) 70%,
          var(--v-primary-lighten4) 100%
        );
      }
    }

    .project-navigation-item {
      cursor: pointer;
      transition: 200ms ease-out;

      &:hover {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        color: var(--v-primary-base);
        transform: translateX(-7px);
      }
    }
  }
}
</style>
