<template>
  <sidebar :shown="sidebarTasksDisplayed" @close="closeTaskSidebar">
    <div
      class="d-flex flex-column flex-1 pr-5 flex-scroll-width"
      style="width: 450px"
    >
      <div class="d-flex align-center ant-border-bottom px-2">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon class="ant-icon" dense @click="fetchTasks" v-on="on">
              mdi-refresh
            </v-icon>
          </template>
          <span> {{ $t('system.taskOverview.reload') }}</span>
        </v-tooltip>
        <v-subheader
          >Tasks
          <notification-count
            v-if="sidebarTasks.length > 0"
            :count="
              sidebarTasks.filter((task) => task.status === 'open').length
            "
            :measures="{ size: 12, fontSize: 8 }"
            right="0"
            top="-5px"
          />
        </v-subheader>
        <v-chip v-if="project" small>
          {{ project.name }}
        </v-chip>
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              class="ant-icon mr-2"
              dense
              @click="navigateToTaskOverview()"
              v-on="on"
            >
              mdi-calendar-month
            </v-icon>
          </template>
          <span> {{ $t('system.taskOverview.overview') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              class="ant-icon"
              dense
              @click="$refs['add-task-dialog'].displayed = true"
              v-on="on"
            >
              mdi-plus
            </v-icon>
          </template>
          <span>{{ $t('system.tasks.newTaskButton') }}</span>
        </v-tooltip>
      </div>
      <div class="d-flex flex-column pos-rel ma-1 overflow-y-auto flex-1">
        <div v-if="sidebarTasksStatus === 'success'" class="flex-grow-1">
          <div
            v-if="sidebarTasks.length === 0"
            class="d-flex align-center justify-center font-italic"
          >
            No tasks found
          </div>
          <task-v2
            v-for="task in sidebarTasks"
            :key="task.id"
            :task="task"
            @openDetailView="closeTaskSidebar"
          />
        </div>
        <div v-else class="d-flex justify-center align-center flex-grow-1">
          <ant-loading />
        </div>
      </div>
      <add-task-dialog ref="add-task-dialog" />
    </div>
  </sidebar>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationCount from '@/components/NotificationCount';
import AntLoading from '@/components/AntLoading';
import AddTaskDialog from '@/components/Tasks/AddTaskDialog';
import TaskV2 from '@/components/Tasks/TaskV2';
import Sidebar from '@/components/Sidebar.vue';

export default {
  name: 'TaskSidebar',
  components: {
    Sidebar,
    TaskV2,
    AddTaskDialog,
    AntLoading,
    NotificationCount,
  },
  data: () => {
    return {
      displayFilters: false,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'sidebarTasksDisplayed',
      'selectedLicense',
      'sidebarTasks',
      'sidebarTasksStatus',
    ]),
  },
  watch: {
    sidebarTasksDisplayed(bool) {
      if (bool) {
        this.fetchTasks();
      }
    },
  },
  methods: {
    closeTaskSidebar() {
      this.$store.commit('toggle_tasks_sidebar');
    },
    fetchTasks() {
      this.$store.dispatch('fetchSidebarTasks');
    },
    navigateToTaskOverview() {
      this.closeTaskSidebar();
      this.$router.push({ name: 'tasks' });
    },
  },
};
</script>

<style lang="scss" scoped></style>
