import {
  createPermission,
  deletePermission,
  getPermissions,
  updatePermission,
} from '@/services/api/permission.api';
import {
  createColumn,
  deleteColumn,
  getColumns,
  updateColumn,
} from '@/services/api/column.api';
import {
  createTable,
  deleteTable,
  updateTable,
} from '@/services/api/table.api';
import {
  createTableRelation,
  deleteTableRelation,
  getTableRelations,
} from '@/services/api/table-relations.api';
import { getTeams } from '@/services/api/teams.api';
import { waitForVuex } from '@/utils/vuex';

const state = {
  tableSettingsStatus: '',
  tableSettingsColumns: [],
  tableSettingsPermissions: [],
  tableSettingsTeams: [],
  tableSettingsRelations: [],

  tablePermissionsStatus: '',
  tableColumnStatus: '',
  tableRelationsStatus: '',
};
const mutations = {
  table_settings_success(state, [columns, permissions, teams, tableRelations]) {
    state.tableSettingsColumns = columns;
    state.tableSettingsPermissions = permissions;
    state.tableSettingsTeams = teams;
    state.tableSettingsRelations = tableRelations;
    state.tableSettingsStatus = 'success';
  },

  permission_request(state) {
    state.tablePermissionsStatus = 'loading';
  },
  permission_create_success(state, permission) {
    state.tableSettingsPermissions.push(permission);
    state.tablePermissionsStatus = 'success';
  },
  permission_update_success(state, updatedPermission) {
    Object.assign(
      state.tableSettingsPermissions.find(
        (permission) => permission.id === updatedPermission.id
      ),
      updatedPermission
    );
    state.tablePermissionsStatus = 'success';
  },
  permission_delete_success(state, deletedPermissionId) {
    state.tableSettingsPermissions = [
      ...state.tableSettingsPermissions.filter(
        (permission) => permission.id !== deletedPermissionId
      ),
    ];
    state.tablePermissionsStatus = 'success';
  },
  column_request(state) {
    state.tableColumnStatus = 'loading';
  },
  column_create_success(state, column) {
    state.tableSettingsColumns.push(column);
    state.tableColumnStatus = 'success';
  },
  column_update_success(state, updatedColumn) {
    Object.assign(
      state.tableSettingsColumns.find(
        (column) => column.id === updatedColumn.id
      ),
      updatedColumn
    );
    state.tableColumnStatus = 'success';
  },
  column_delete_success(state, deletedColumnId) {
    state.tableSettingsColumns = [
      ...state.tableSettingsColumns.filter(
        (column) => column.id !== deletedColumnId
      ),
    ];
    state.tableSettingsRelations = [
      ...state.tableSettingsRelations.filter(
        (relation) => relation.column?.id !== deletedColumnId
      ),
    ];
    state.tableColumnStatus = 'success';
  },
  relation_request(state) {
    state.tableRelationsStatus = 'loading';
  },
  relation_create_success(state, relation) {
    state.tableSettingsRelations.push(relation);
    state.tableRelationsStatus = 'success';
  },
  relation_delete_success(state, deletedRelationId) {
    state.tableSettingsRelations = [
      ...state.tableSettingsRelations.filter(
        (relation) => relation.id !== deletedRelationId
      ),
    ];
    state.tableRelationsStatus = 'success';
  },
};
const actions = {
  fetchTableSettings({ commit, dispatch, state }, { projectId, tableId }) {
    state.tableSettingsStatus = 'loading';
    return Promise.all([
      getColumns(projectId, tableId).catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }),
      getPermissions(projectId, tableId).catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }),
      getTeams(projectId).catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }),
      getTableRelations(projectId, tableId).catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }),
    ]).then((responses) => {
      commit('table_settings_success', responses);
    });
  },
  createTable: waitForVuex('table.create', async ({ commit }, body) => {
    try {
      const table = await createTable(body);
      commit('create_project_table_success', table);
    } catch (error) {
      commit('showNotification', {
        content: error.message,
        color: 'error',
      });
    }
  }),
  updateTable({ commit }, { tableId, body }) {
    return updateTable(tableId, body)
      .then((table) => {
        this.commit('update_project_table_success', table);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  deleteTable: waitForVuex(
    'table.delete',
    async ({ commit }, { tableId, body }) => {
      try {
        await deleteTable(tableId, body);
        commit('delete_project_table_success', tableId);
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),

  // permissions
  createPermission: waitForVuex(
    'table.permission.create',
    ({ commit }, { body }) => {
      commit('permission_request');
      return createPermission(body)
        .then((response) => {
          commit('permission_create_success', response);
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
  updatePermission: waitForVuex(
    'table.permission.update',
    ({ commit }, { permissionId, body }) => {
      commit('permission_request');
      return updatePermission(permissionId, body)
        .then((response) => {
          commit('permission_update_success', response);
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
  deletePermission({ commit }, { permissionId }) {
    commit('permission_request');
    return deletePermission(permissionId)
      .then((response) => {
        commit('permission_delete_success', permissionId);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },

  // columns
  deleteColumn({ commit }, { columnId, body }) {
    commit('column_request');
    return deleteColumn(columnId, body)
      .then((response) => {
        commit('column_delete_success', columnId);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  updateColumn({ commit }, { columnId, body }) {
    commit('column_request');
    return updateColumn(columnId, body)
      .then((response) => {
        commit('column_update_success', response);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  createColumn({ commit }, { body }) {
    commit('column_request');
    return createColumn(body)
      .then((response) => {
        commit('column_create_success', response);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },

  // relations
  createRelation({ commit }, { projectId, body }) {
    commit('relation_request');
    return createTableRelation(projectId, body)
      .then((response) => {
        commit('relation_create_success', response);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  deleteRelation({ commit }, { projectId, relationId }) {
    commit('relation_request');
    return deleteTableRelation(projectId, relationId)
      .then((response) => {
        commit('relation_delete_success', relationId);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
};
const getters = {
  tableSettingsStatus: (state) => state.tableSettingsStatus,
  tableSettingsColumns: (state) => state.tableSettingsColumns,
  tableSettingsPermissions: (state) => state.tableSettingsPermissions,
  tableSettingsTeams: (state) => state.tableSettingsTeams,
  tableSettingsRelations: (state) => state.tableSettingsRelations,
  tablePermissionsStatus: (state) => state.tablePermissionsStatus,
  tableColumnStatus: (state) => state.tableColumnStatus,
  tableRelationsStatus: (state) => state.tableRelationsStatus,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
