import {
  deleteTaskAppendix,
  queryTasksV2,
  uploadTaskAppendix,
} from '@/services/api/task.api';
import { getRecordDocument } from '@/services/api/record.api';
import { importTasksV2, queryTablesV2 } from '@/services/api/v2/connect_v2';

const state = {
  productionTasks: [],
  focusedTask: null,
  focusedTaskElements: [],
  moduleTableId: null,
  focusedTaskModules: [],
  focusedTaskAssemblyTasks: [],
  focusedTaskElementTypes: [],
  focusedLane: null,
  productionHalls: [],
  productionConfigurations: [],
  productionLocations: [],
  calendar: [],
  requestCounter: 0,
  projectElementAssemblyTasks: [],
  importCounter: 0,
};
const mutations = {
  make_request(state) {
    state.requestCounter++;
  },
  request_success(state) {
    state.requestCounter--;
  },
  production_tasks_success(state, packages) {
    state.productionTasks = packages;
    state.requestCounter--;
  },
  update_production_tasks(state, tasks) {
    tasks.forEach((t1) => {
      Object.assign(
        state.productionTasks.find((t2) => t2.id === t1.id),
        t1
      );
    });

    state.requestCounter--;
  },
  focus_on_task(state, task) {
    state.focusedTask = task;
  },
  focus_on_lane(state, lane) {
    state.focusedLane = lane;
  },
  focused_task_data_success(
    state,
    { tasks, types, moduleTableId, modules, elements }
  ) {
    state.focusedTaskAssemblyTasks = tasks;
    state.focusedTaskElementTypes = types;
    state.moduleTableId = moduleTableId;
    state.focusedTaskModules = modules;
    state.focusedTaskElements = elements;
    state.requestCounter--;
  },
  production_halls_success(
    state,
    { calendar, halls, configurations, locations }
  ) {
    state.calendar = calendar;
    state.productionHalls = halls;
    state.productionConfigurations = configurations;
    state.productionLocations = locations;
    state.requestCounter--;
  },
  project_element_assembly_tasks_success(state, { tasks }) {
    state.projectElementAssemblyTasks = tasks;
  },
};
const actions = {
  async fetchProductionTasks({ commit, rootGetters }) {
    try {
      commit('make_request');
      const response = await queryTasksV2(rootGetters.project.license, [
        {
          column: 'type',
          operator: '=',
          values: ['dhme-work-package'],
        },
      ]);
      commit('production_tasks_success', response.tasks);
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    }
  },
  async fetchProductionHallsAndConfigurations({ commit, rootGetters }) {
    try {
      commit('make_request');
      const { productionHalls, calendar } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_HALLS',
            project: rootGetters.project.id,
            as: 'productionHalls',
            columns: [
              {
                name: 'hall',
                as: 'title',
              },
              {
                name: 'type',
                conditions: [
                  {
                    operator: '=',
                    value: 'production',
                  },
                ],
              },
            ],
          },
          {
            name: 'CFFA_DHME_CALENDAR',
            project: rootGetters.project.id,
            as: 'calendar',
            columns: [
              {
                name: 'label',
              },
              {
                name: 'date',
              },
            ],
          },
        ],
      });
      const { configurations, locations } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_LOCATIONS',
            project: rootGetters.project.id,
            as: 'locations',
            columns: [
              {
                name: 'assembly_hall',
                conditions: productionHalls.records.map((r) => {
                  return {
                    operator: '=',
                    value: r.id,
                    boolean: 'or',
                  };
                }),
              },
              {
                name: 'assembly_lane',
              },
              {
                name: 'assembly_location',
              },
              {
                name: 'description',
              },
              {
                name: 'hall_configuration',
                as: 'configuration',
              },
            ],
          },
          {
            name: 'CFFA_DHME_ASSEMBLY_HALL_CONFIGURATIONS',
            project: rootGetters.project.id,
            as: 'configurations',
            columns: [
              {
                name: 'assembly_hall',
                conditions: productionHalls.records.map((r) => {
                  return {
                    operator: '=',
                    value: r.id,
                    boolean: 'or',
                  };
                }),
              },
              {
                name: 'title',
              },
            ],
          },
        ],
      });

      commit('production_halls_success', {
        calendar: calendar.records,
        halls: productionHalls.records,
        configurations: configurations.records,
        locations: locations.records,
      });
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    }
  },
  async fetchElementsInGroupInOrder({ commit, rootGetters, getters }) {
    try {
      commit('make_request');
      const tasksResponse = await queryTasksV2(rootGetters.project.license, [
        {
          column: 'project',
          operator: '=',
          values: [getters.focusedTask?.task_project.id],
        },
        {
          column: 'type',
          operator: '=',
          values: ['dhme-module-assembly'],
        },
      ]);
      const { typesInGroup } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_RESOURCEGROUP_ELEMENTS',
            project: getters.focusedTask?.task_project.id,
            as: 'typesInGroup',
            sortBy: 'element_type',
            columns: [
              {
                name: 'group_code',
                conditions: [
                  {
                    operator: '=',
                    value: getters.focusedTask.task_type.custom_2,
                  },
                ],
              },
              {
                name: 'element_type',
              },
            ],
          },
        ],
      });
      const { modules, elements } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_MODULES',
            project: getters.focusedTask?.task_project.id,
            as: 'modules',
            columns: [
              {
                name: 'module_id',
              },
              {
                name: '2d_drawing',
              },
            ],
          },
          {
            name: 'CFFA_DHME_ELEMENTS',
            project: getters.focusedTask?.task_project.id,
            as: 'elements',
            columns: [
              {
                name: 'module_id',
                conditions: tasksResponse.tasks.map((t) => {
                  return {
                    operator: '=',
                    value: t.sbscode.code,
                    boolean: 'or',
                  };
                }),
              },
              {
                name: 'element_id',
              },
              {
                name: 'element_type',
                conditions: typesInGroup.records.map((r) => {
                  return {
                    operator: '=',
                    value: r.element_type,
                    boolean: 'or',
                  };
                }),
              },
            ],
          },
        ],
      });

      commit('focused_task_data_success', {
        tasks: tasksResponse.tasks,
        types: typesInGroup.records,
        modules: modules.records,
        moduleTableId: modules.id,
        elements: elements.records,
      });

      const { tasks } = await queryTasksV2(rootGetters.project.license, [
        {
          column: 'type',
          operator: '=',
          values: ['dhme-element-assembly'],
        },
        {
          column: 'project',
          operator: '=',
          values: [getters.focusedTask?.task_project?.id],
        },
      ]);
      commit('project_element_assembly_tasks_success', {
        tasks: tasks,
      });
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    }
  },

  async importProductionTasks({ commit, rootGetters, getters, state }, tasks) {
    try {
      commit('make_request');
      const importedTasks = await importTasksV2(tasks);

      state.importCounter = 0;
      for (const m of getters.focusedTaskModules.filter(
        (m) => m['2d_drawing']
      )) {
        let elements = getters.focusedTaskElements.filter(
          (e) => e.module_id === m.module_id
        );

        for (const e of elements) {
          let task = importedTasks.find(
            (t) => t.sbscode?.code === e.element_id
          );
          let drawing = task?.appendixes.find(
            (a) => a.name === `2D_${m.module_id}`
          );
          if (drawing) {
            await deleteTaskAppendix(task.id, drawing.id);
          }
          let document = await getRecordDocument(
            m['2d_drawing'],
            rootGetters.project.id,
            getters.moduleTableId
          );
          await uploadTaskAppendix(task.id, {
            data: document.file,
            extension: document.extension,
            name: `2D_${m.module_id}`,
          });
        }
        state.importCounter++;
      }

      commit(
        'update_production_tasks',
        importedTasks.filter((t) => t.task_type.type === 'dhme-work-package')
      );
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    }
  },
};
const getters = {
  requestedProductionTasks: (state) =>
    state.productionTasks.filter((t) => t.task_type.custom_1 === 'request'),
  plannedProductionTasks: (state) =>
    state.productionTasks.filter((t) => t.task_type.custom_1 === 'planned'),
  focusedTask: (state) => state.focusedTask,
  focusedLane: (state) => state.focusedLane,
  focusedTaskAssemblyTasks: (state) => state.focusedTaskAssemblyTasks,
  focusedTaskElements: (state) => state.focusedTaskElements,
  moduleTableId: (state) => state.moduleTableId,
  focusedTaskModules: (state) => state.focusedTaskModules,
  focusedTaskElementTypes: (state) => state.focusedTaskElementTypes,
  productionHalls: (state) => state.productionHalls,
  productionConfigurations: (state) => state.productionConfigurations,
  productionLocations: (state) => state.productionLocations,
  vacationCalendar: (state) => state.calendar,
  isLoading: (state) => state.requestCounter > 0,
  projectElementAssemblyTasks: (state) => state.projectElementAssemblyTasks,
  importCounter: (state) => state.importCounter,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
