import {
  deleteProjectWorkflow,
  getProjectWorkflow,
  updateProjectWorkflowName,
} from '@/services/api/project-workflow.api';
import {
  addRoleToNode,
  addRoleToTeam,
  createWorkflowProjectRole,
  deleteProjectWorkflowRole,
  getNodesWithRoles,
  getProjectWorkflowRoles,
  getTeamsWithRoles,
  removeRoleFromNode,
  removeRoleFromTeam,
  updateProjectWorkflowRoleName,
} from '@/services/api/project-workflow-roles.api';

const state = {
  projectWorkflow: undefined,
  projectWorkflowStatus: '',

  projectWorkflowRoles: [],
  projectWorkflowRolesStatus: '',

  projectWorkflowNodes: [],
  projectWorkflowNodesStatus: '',

  projectWorkflowTeams: [],
  projectWorkflowTeamsStatus: '',

  selectedRole: null,

  filteredRole: null,
};
const mutations = {
  // project workflow
  project_workflow_request(state) {
    state.projectWorkflowStatus = 'loading';
  },
  project_workflow_success(state, projectWorkflow) {
    state.projectWorkflow = projectWorkflow;
    state.projectWorkflowStatus = 'success';
  },
  project_workflow_update_success(state, projectWorkflow) {
    state.projectWorkflow = Object.assign({}, projectWorkflow);
  },
  project_workflow_delete_success(state, projectWorkflow) {
    state.projectWorkflow = undefined;
  },

  // project workflow roles
  project_workflow_roles_request(state) {
    state.projectWorkflowRolesStatus = 'loading';
  },
  project_workflow_roles_success(state, roles) {
    state.projectWorkflowRoles = roles;
    state.projectWorkflowRolesStatus = 'success';
  },
  project_workflow_roles_create_success(state, role) {
    state.projectWorkflowRoles.push(role);
    state.projectWorkflowRolesStatus = 'success';
  },
  project_workflow_roles_update_success(state, updatedRole) {
    Object.assign(
      state.projectWorkflowRoles.find((role) => role.id === updatedRole.id),
      updatedRole
    );

    // nodes
    state.projectWorkflowNodes.forEach((node) => {
      if (node.roles.find((role) => role.id === updatedRole.id) !== undefined) {
        Object.assign(
          node.roles.find((role) => role.id === updatedRole.id),
          updatedRole
        );
      }
    });

    // teams
    state.projectWorkflowTeams.forEach((team) => {
      if (team.roles.find((role) => role.id === updatedRole.id) !== undefined) {
        Object.assign(
          team.roles.find((role) => role.id === updatedRole.id),
          updatedRole
        );
      }
    });

    state.projectWorkflowRolesStatus = 'success';
  },
  project_workflow_roles_delete_success(state, roleId) {
    state.projectWorkflowRoles = [
      ...state.projectWorkflowRoles.filter((role) => role.id !== roleId),
    ];
    state.projectWorkflowRolesStatus = 'success';
  },

  // project workflow nodes
  project_workflow_nodes_request(state) {
    state.projectWorkflowNodesStatus = 'loading';
  },
  project_workflow_nodes_success(state, nodes) {
    state.projectWorkflowNodes = nodes;
    state.projectWorkflowNodesStatus = 'success';
  },

  project_workflow_nodes_roles_create_success(state, { role, nodeId }) {
    state.projectWorkflowNodes
      .find((node) => node.id === nodeId)
      .roles.push(role);
    state.projectWorkflowNodesStatus = 'success';
  },
  project_workflow_nodes_roles_remove_success(
    state,
    { deletedRoleId, nodeId }
  ) {
    let nodeRoles = state.projectWorkflowNodes.find(
      (node) => node.id === nodeId
    ).roles;
    state.projectWorkflowNodes.find((node) => node.id === nodeId).roles = [
      ...nodeRoles.filter((role) => role.id !== deletedRoleId),
    ];
    state.projectWorkflowNodesStatus = 'success';
  },

  // project workflow teams
  project_workflow_teams_request(state) {
    state.projectWorkflowTeamsStatus = 'loading';
  },
  project_workflow_teams_success(state, nodes) {
    state.projectWorkflowTeams = nodes;
    state.projectWorkflowTeamsStatus = 'success';
  },

  project_workflow_teams_roles_create_success(state, { role, teamId }) {
    state.projectWorkflowTeams
      .find((team) => team.id === teamId)
      .roles.push(role);
    state.projectWorkflowTeamsStatus = 'success';
  },
  project_workflow_teams_roles_remove_success(
    state,
    { deletedRoleId, teamId }
  ) {
    let teamRoles = state.projectWorkflowTeams.find(
      (node) => node.id === teamId
    ).roles;
    state.projectWorkflowTeams.find((team) => team.id === teamId).roles = [
      ...teamRoles.filter((role) => role.id !== deletedRoleId),
    ];
    state.projectWorkflowTeamsStatus = 'success';
  },

  // filters and selections
  select_role(state, role) {
    state.selectedRole = role;
  },
  remove_selected_role(state) {
    state.selectedRole = null;
  },
  filtered_role(state, role) {
    state.filteredRole = role;
  },
  remove_filtered_role(state) {
    state.filteredRole = null;
  },
};
const actions = {
  fetchProjectWorkflow({ commit }, { projectId, workflowId }) {
    commit('project_workflow_request');
    return getProjectWorkflow(projectId, workflowId)
      .then((projectWorkflow) => {
        commit('project_workflow_success', projectWorkflow);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  updateProjectWorkflow({ commit }, { projectId, workflowId, body }) {
    return updateProjectWorkflowName(projectId, workflowId, body)
      .then((projectWorkflow) => {
        commit('project_workflow_update_success', projectWorkflow);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  deleteProjectWorkflow({ commit }, { projectId, workflowId }) {
    return deleteProjectWorkflow(projectId, workflowId)
      .then((response) => {
        commit('project_workflow_delete_success', workflowId);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },

  fetchProjectWorkflowRoles({ commit }, { projectId, workflowId }) {
    commit('project_workflow_roles_request');
    return getProjectWorkflowRoles(projectId, workflowId)
      .then((roles) => {
        commit('project_workflow_roles_success', roles);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  addRoleToWorkflowProject({ commit }, { projectId, workflowId, body }) {
    commit('project_workflow_roles_request');
    return createWorkflowProjectRole(projectId, workflowId, body)
      .then((role) => {
        commit('project_workflow_roles_create_success', role);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  updateProjectWorkflowRole(
    { commit },
    { projectId, workflowId, roleId, body }
  ) {
    commit('project_workflow_roles_request');
    return updateProjectWorkflowRoleName(projectId, workflowId, roleId, body)
      .then((role) => {
        commit('project_workflow_roles_update_success', role);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  deleteProjectWorkflowRole({ commit }, { projectId, workflowId, roleId }) {
    commit('project_workflow_roles_request');
    return deleteProjectWorkflowRole(projectId, workflowId, roleId)
      .then((response) => {
        commit('project_workflow_roles_delete_success', roleId);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  fetchProjectWorkflowNodes({ commit }, { projectId, workflowId }) {
    commit('project_workflow_nodes_request');
    return getNodesWithRoles(projectId, workflowId)
      .then((nodes) => {
        commit('project_workflow_nodes_success', nodes);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },

  addRoleToNode({ commit }, { projectId, workflowId, role, node }) {
    commit('project_workflow_nodes_request');
    return addRoleToNode(projectId, workflowId, role.id, node)
      .then((response) => {
        commit('project_workflow_nodes_roles_create_success', {
          role,
          nodeId: node.id,
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  removeRoleFromNode({ commit }, { projectId, workflowId, role, node }) {
    commit('project_workflow_nodes_request');
    return removeRoleFromNode(projectId, workflowId, role.id, node)
      .then((response) => {
        commit('project_workflow_nodes_roles_remove_success', {
          deletedRoleId: role.id,
          nodeId: node,
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },

  fetchProjectWorkflowTeams({ commit }, { projectId, workflowId }) {
    commit('project_workflow_teams_request');
    return getTeamsWithRoles(projectId, workflowId)
      .then((teams) => {
        commit('project_workflow_teams_success', teams);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },

  addRoleToTeam({ commit }, { projectId, workflowId, role, team }) {
    commit('project_workflow_teams_request');
    return addRoleToTeam(projectId, workflowId, role.id, team)
      .then((response) => {
        commit('project_workflow_teams_roles_create_success', {
          role,
          teamId: team.id,
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  removeRoleFromTeam({ commit }, { projectId, workflowId, role, teamId }) {
    commit('project_workflow_teams_request');
    return removeRoleFromTeam(projectId, workflowId, role.id, teamId)
      .then((response) => {
        commit('project_workflow_teams_roles_remove_success', {
          deletedRoleId: role.id,
          teamId: teamId,
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },

  selectRole({ commit }, { role }) {
    commit('select_role', role);
  },
  removeSelectedRole({ commit }) {
    commit('remove_selected_role');
  },
  filterRole({ commit }, { role }) {
    commit('filtered_role', role);
  },
  removeFilteredRole({ commit }) {
    commit('remove_filtered_role');
  },
};
const getters = {
  projectWorkflow: (state) => state.projectWorkflow,
  projectWorkflowStatus: (state) => state.projectWorkflowStatus,

  projectWorkflowRoles: (state) => state.projectWorkflowRoles,
  projectWorkflowRolesStatus: (state) => state.projectWorkflowRolesStatus,

  projectWorkflowNodes: (state) => state.projectWorkflowNodes,
  projectWorkflowNodesStatus: (state) => state.projectWorkflowNodesStatus,

  projectWorkflowTeams: (state) => state.projectWorkflowTeams,
  projectWorkflowTeamsStatus: (state) => state.projectWorkflowTeamsStatus,

  selectedRole: (state) => state.selectedRole,
  filteredRole: (state) => state.filteredRole,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
