import { getModuleData } from '@/services/api/module.api';
import { waitForVuex } from '@/utils/vuex';
import { getTeams } from '@/services/api/teams.api';
import { root } from 'vue-tour/.eslintrc';
import moment from 'moment';
import { queryTasksV2 } from '@/services/api/task.api';
import { queryTablesV2 } from '@/services/api/v2/connect_v2';

const state = {
  data: {},
  licenseTasks: [],
  isProjectSidebarDisplayed: false,
  selectedProject: null,
  projectRoles: {},
  projectComments: [],
  projectCommentsTable: null,
};
const mutations = {
  set_module_data(state, data) {
    state.data = data;
  },
  set_tasks(state, data) {
    state.licenseTasks = data;
  },
  set_roles(state, { projectId, data }) {
    state.projectRoles = {
      ...state.projectRoles,
      [projectId]: data,
    };
  },
  set_comments(state, { comments, tableId }) {
    state.projectComments = comments;
    state.projectCommentsTable = tableId;
  },
  set_selected_project(state, project) {
    state.selectedProject = project;
  },
  toggle_project_sidebar(state) {
    state.isProjectSidebarDisplayed = !state.isProjectSidebarDisplayed;
  },
  close_project_sidebar(state) {
    state.isProjectSidebarDisplayed = false;
  },
  open_project_sidebar(state) {
    state.isProjectSidebarDisplayed = true;
  },
};
const actions = {
  fetchModuleData: waitForVuex(
    'module.project-planning.data.get',
    async ({ commit }, { projectId, moduleId, sessionId }) => {
      try {
        const data = await getModuleData(projectId, moduleId, sessionId);
        commit('set_module_data', data);
      } catch (e) {
        commit(
          'showNotification',
          {
            content: e.message,
            color: 'error',
          },
          { root: true }
        );
      }
    }
  ),
  fetchComments: waitForVuex(
    'module.project-planning.comments.get',
    async ({ commit, getters, rootGetters }) => {
      try {
        const { comments } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_DHME_PROJECT_COMMENTS',
              as: 'comments',
              project: rootGetters.project.id,
              sortBy: 'timestamp',
              sortByOrder: 'desc',
              columns: [
                {
                  name: 'project_id',
                  conditions: [
                    {
                      operator: '=',
                      value: getters.selectedProject.id,
                    },
                  ],
                },
                {
                  name: 'comment',
                },
                {
                  name: 'timestamp',
                },
                {
                  name: 'firstname',
                },
                {
                  name: 'lastname',
                },
                {
                  name: 'email',
                },
              ],
            },
          ],
        });

        commit('set_comments', {
          comments: comments.records,
          tableId: comments.id,
        });
      } catch (e) {
        commit(
          'showNotification',
          {
            content: e.message,
            color: 'error',
          },
          { root: true }
        );
      }
    }
  ),
  fetchTasks: waitForVuex(
    'module.project-planning.tasks.get',
    async ({ commit, rootGetters }) => {
      try {
        const response = await queryTasksV2(rootGetters.selectedLicense.id, [
          {
            column: 'parent',
            operator: '=',
            values: [null],
          },
        ]);
        commit('set_tasks', response.tasks);
      } catch (e) {
        commit(
          'showNotification',
          {
            content: e.message,
            color: 'error',
          },
          { root: true }
        );
      }
    }
  ),
  fetchTeams: waitForVuex(
    'module.project-planning.teams.get',
    async ({ commit, getters }) => {
      return getTeams(getters.selectedProject.id, { withUsers: '1' })
        .then((roles) => {
          commit('set_roles', {
            projectId: getters.selectedProject.id,
            data: roles,
          });
        })
        .catch((error) => {
          commit(
            'showNotification',
            {
              content: error.message,
              color: 'error',
            },
            { root: true }
          );
        });
    }
  ),
};
const getters = {
  data: (state) => state.data,
  licenseTasks: (state) => state.licenseTasks,
  isProjectSidebarDisplayed: (state) => state.isProjectSidebarDisplayed,
  selectedProject: (state) => state.selectedProject,
  selectedProjectRoles: (state) =>
    state.projectRoles?.[state.selectedProject?.id] || [],
  selectedProjectComments: (state) =>
    state.projectComments.sort((a, b) => moment.unix(b).diff(moment.unix(a))) ||
    [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
