import { bookOfObjects } from '@/services/bookOf';
import { queryTasksV2 } from '@/services/api/task.api';
import { createRecord, importRecords } from '@/services/api/record.api';
import { importTasksV2, queryTablesV2 } from '@/services/api/v2/connect_v2';

const state = {
  modules: [],
  elements: [],

  assemblyHall: null,

  assemblyLocations: [],
  assemblyLocationStatuses: [],
  assemblyConfigurationSteps: [],
  assemblyLocationStatusesTableId: null,
  assemblyPreparationsModuleAssemblyTasks: [],

  assemblyLocationActivities: [],
  assemblyLocationTypes: [],
  assemblyPhase: null,

  status: null,
};
const mutations = {
  module_assembly_preparations_request(state) {
    state.status = 'loading';
  },

  module_assembly_preparations_success(state) {
    state.status = 'success';
  },

  module_assembly_preparations_get_locations_success(state, locations) {
    state.assemblyLocations = locations.records;
  },

  module_assembly_preparations_get_statuses_success(state, statuses) {
    state.assemblyLocationStatuses = statuses.records;
    state.assemblyLocationStatusesTableId = statuses.id;
  },

  module_assembly_preparations_get_steps_success(state, steps) {
    state.assemblyConfigurationSteps = steps.records;
  },

  module_assembly_preparations_get_activities_success(state, activities) {
    state.assemblyLocationActivities = activities.records;
  },

  module_assembly_preparations_get_types_success(state, types) {
    state.assemblyLocationTypes = types.records;
  },

  module_assembly_preparations_get_modules_success(state, modules) {
    state.modules = modules.records;
  },

  module_assembly_preparations_get_assembly_hall_success(state, hall) {
    state.assemblyHall = hall.records[0];
  },

  module_assembly_preparations_get_elements_success(state, elements) {
    state.elements = elements.records;
  },

  module_assembly_preparations_tasks_success(state, tasks) {
    state.assemblyPreparationsModuleAssemblyTasks = tasks;
  },

  module_assembly_preparations_phase_tasks_success(state, task) {
    state.assemblyPhase = task;
  },

  module_assembly_preparations_location_status_success(state, record) {
    state.assemblyLocationStatuses.push(record);
  },

  module_assembly_preparations_location_statuses_success(state, records) {
    state.assemblyLocationStatuses = [
      ...state.assemblyLocationStatuses,
      ...records,
    ];
  },
};
const actions = {
  async fetchAssemblyPreparationsAssemblyLocations({
    commit,
    state,
    rootGetters,
  }) {
    commit('module_assembly_preparations_request');
    try {
      const { locations, statuses, halls, modules, steps, elements } =
        await queryTablesV2({
          tables: [
            {
              name: 'CFFA_DHME_MODULES',
              project: rootGetters.project.id,
              as: 'modules',
              sortBy: 'assembly_sequence',
              columns: [
                {
                  name: 'module_type',
                },
                {
                  name: 'module_id',
                },
                {
                  name: 'build_nr',
                },
                {
                  name: 'assembly_sequence',
                },
              ],
            },
            {
              name: 'CFFA_DHME_ASSEMBLY_HALLS',
              project: rootGetters.project.master_id,
              as: 'halls',
              records: [state.assemblyPhase?.task_type?.custom_3],
              columns: [
                {
                  name: 'hall',
                },
                {
                  name: 'description',
                },
                {
                  name: 'automated',
                },
              ],
            },
            {
              name: 'CFFA_DHME_ASSEMBLY_PREPARATIONS_STATUS',
              project: rootGetters.project.id,
              as: 'statuses',
              columns: [
                {
                  name: 'assembly_location',
                },
                {
                  name: 'module_id',
                },
                {
                  name: 'status',
                },
              ],
            },
            {
              name: 'CFFA_DHME_ASSEMBLY_LOCATIONS',
              project: rootGetters.project.master_id,
              as: 'locations',
              sortBy: 'order',
              columns: [
                {
                  name: 'assembly_hall',
                },
                {
                  name: 'assembly_lane',
                },
                {
                  name: 'assembly_location',
                },
                {
                  name: 'description',
                },
                {
                  name: 'manual_default',
                },
                {
                  name: 'order',
                },
                {
                  name: 'hall_configuration',
                  conditions: [
                    {
                      operator: '=',
                      value: state.assemblyPhase?.task_type?.custom_4,
                    },
                  ],
                },
              ],
            },
            {
              name: 'CFFA_DHME_ASSEMBLY_STEPS',
              project: rootGetters.project.master_id,
              as: 'steps',
              sortBy: 'order',
              columns: [
                {
                  name: 'configuration',
                  conditions: [
                    {
                      operator: '=',
                      value: state.assemblyPhase?.task_type?.custom_4,
                    },
                  ],
                },
                {
                  name: 'title',
                },
                {
                  name: 'order',
                },
              ],
            },
          ],
        });
      commit('module_assembly_preparations_get_modules_success', modules);
      commit('module_assembly_preparations_get_assembly_hall_success', halls);
      commit('module_assembly_preparations_get_locations_success', locations);
      commit('module_assembly_preparations_get_statuses_success', statuses);
      commit('module_assembly_preparations_get_steps_success', steps);

      const { types, activities } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_LOCATION_ACTIVITIES',
            project: rootGetters.project.master_id,
            as: 'activities',
            sortBy: 'order',
            columns: [
              {
                name: 'assembly_location',
                conditions: locations.records.map((x) => {
                  return {
                    operator: '=',
                    value: x.id,
                    boolean: 'or',
                  };
                }),
              },
              {
                name: 'activity',
              },
              {
                name: 'step',
              },
              {
                name: 'order',
              },
              {
                name: 'leanforms_q_nr',
              },
              {
                name: 'leanforms_remaining_point',
              },
              {
                name: 'btn_options',
              },
              {
                name: 'translations',
              },
            ],
          },
          {
            name: 'CFFA_DHME_ASSEMBLY_LOCATION_TYPES',
            project: rootGetters.project.master_id,
            as: 'types',
            sortBy: 'order',
            columns: [
              {
                name: 'assembly_location',
                conditions: locations.records.map((x) => {
                  return {
                    operator: '=',
                    value: x.id,
                    boolean: 'or',
                  };
                }),
              },
              {
                name: 'type',
              },
              {
                name: 'friendly_name',
              },
              {
                name: 'step',
              },
              {
                name: 'order',
              },
            ],
          },
        ],
      });
      commit('module_assembly_preparations_get_activities_success', activities);
      commit('module_assembly_preparations_get_types_success', types);

      commit('module_assembly_preparations_success');
      return Promise.resolve();
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
      return Promise.reject(error);
    }
  },
  async fetchAssemblyPreparationsProjectAssemblyPhase(
    { commit, getters, dispatch },
    { projectId }
  ) {
    try {
      const response = await queryTasksV2(getters.selectedLicense.id, [
        {
          column: 'type',
          operator: '=',
          values: ['dhme-assembly-phase'],
        },
        {
          column: 'project',
          operator: '=',
          values: [projectId],
        },
      ]);
      commit(
        'module_assembly_preparations_phase_tasks_success',
        response.tasks[0]
      );
      dispatch('fetchAssemblyPreparationsAssemblyLocations');
      return Promise.resolve(response.tasks);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchAssemblyPreparationsModuleAssemblyTasks(
    { commit, getters },
    { projectId }
  ) {
    try {
      const response = await queryTasksV2(getters.selectedLicense.id, [
        {
          column: 'type',
          operator: '=',
          values: ['dhme-module-assembly'],
        },
        {
          column: 'project',
          operator: '=',
          values: [projectId],
        },
      ]);
      commit('module_assembly_preparations_tasks_success', response.tasks);
      return Promise.resolve(response.tasks);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async importAssemblyPreparationsTasks({ commit }, tasks) {
    try {
      const importedTasks = await importTasksV2(tasks);
      return Promise.resolve(importedTasks);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async setLocationStatus({ commit, state, rootGetters }, recordData) {
    try {
      const record = await createRecord({
        project: { id: rootGetters.project.id },
        table: { id: state.assemblyLocationStatusesTableId },
        record: recordData,
      });
      commit('module_assembly_preparations_location_status_success', record);
      commit('showNotification', {
        content: 'Location tasks imported successfully',
        color: 'success',
      });
      return Promise.resolve(record);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async setLocationStatuses({ commit, state, rootGetters }, data) {
    try {
      let book = bookOfObjects('records', data);
      let csv = book.convert('csv', 'string');
      let parsedCsv = btoa(unescape(encodeURIComponent(csv)));

      await importRecords({
        project: {
          id: rootGetters.project.id,
        },
        table: {
          id: state.assemblyLocationStatusesTableId,
        },
        records: parsedCsv,
      });
      commit('module_assembly_preparations_location_statuses_success', data);
      commit('showNotification', {
        content: 'All tasks imported successfully',
        color: 'success',
      });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
const getters = {
  assemblyPreparationsModuleAssemblyTasks: (state) =>
    state.assemblyPreparationsModuleAssemblyTasks,
  assemblyPreparationsModules: (state) => state.modules,
  assemblyPreparationsHall: (state) => state.assemblyHall,
  assemblyPreparationsHallLocations: (state) => {
    if (state.assemblyHall?.automated) {
      return state.assemblyLocations;
    }

    return state.assemblyLocations.filter((record) => record.manual_default);
  },
  assemblyConfigurationSteps: (state) => state.assemblyConfigurationSteps,
  assemblyPreparationsStatus: (state) => state.status,
  assemblyPreparationLocationActivities: (state) =>
    state.assemblyLocationActivities,
  assemblyPreparationLocationTypes: (state) => state.assemblyLocationTypes,
  assemblyPreparationsElements: (state) =>
    state.moduleData.CFFA_DHME_ELEMENTS.records,
  assemblyPreparationsLocationStatuses: (state) =>
    state.assemblyLocationStatuses,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
