import moment from 'moment';
import { queryTasksV2 } from '@/services/api/task.api';
import {
  createRecord,
  deleteRecord,
  importRecords,
  updateRecord,
} from '@/services/api/record.api';
import { queryTablesV2 } from '@/services/api/v2/connect_v2';
import { bookOfObjects } from '@/services/bookOf';
import { searchSbsRecords, updateSbsRecord } from '@/services/api/sbs.api';

const state = {
  moduleData: undefined,
  moduleStatus: '',
  placementTasks: [],

  modules: [],
  modulesTableId: null,
  moduleContents: [],
  moduleContentsTableId: null,
  elementContents: [],
  elementContentsTableId: null,
  objectsRegister: [],
  objectsRegisterTableId: null,

  elements: [],

  elementGenerations: [],
  objectGenerations: [],
  generationsTableId: null,

  elementContentsFilter: null,
  objectsFilter: null,

  requestCounter: 0,
};
const mutations = {
  module_manual_import_request(state) {
    state.requestCounter++;
  },
  module_manual_import_request_success(state) {
    state.requestCounter--;
  },
  module_manual_import_placement_tasks_success(state, data) {
    state.placementTasks = data;
  },
  module_manual_import_modules_success(state, modules) {
    state.modulesTableId = modules.id;
    state.modules = modules.records;
  },
  module_manual_import_module_create_success(state, record) {
    state.modules.unshift(record);
  },
  module_manual_import_module_update_success(state, record) {
    Object.assign(
      state.modules.find((item) => item.id === record.id),
      record
    );
  },
  module_manual_import_module_delete_success(state, deletedId) {
    state.modules = state.modules.filter((item) => item.id !== deletedId);
  },
  module_manual_import_element_generations_success(state, generations) {
    state.elementGenerations = generations.records;
    state.generationsTableId = generations.id;
  },

  module_manual_import_object_generations_success(state, generations) {
    state.objectGenerations = generations.records;
    state.generationsTableId = generations.id;
  },
  module_manual_import_module_contents_success(state, moduleContents) {
    state.moduleContents = moduleContents.records;
    state.moduleContentsTableId = moduleContents.id;
  },
  module_manual_import_module_contents_update_success(state, record) {
    Object.assign(
      state.moduleContents.find((item) => item.id === record.id),
      record
    );
  },
  module_manual_import_module_contents_create_success(state, record) {
    state.moduleContents.push(record);
  },

  module_manual_import_set_new_element_generation(state, record) {
    state.elementGenerations.unshift(record);
  },
  module_manual_import_set_new_object_generation(state, record) {
    state.objectGenerations.unshift(record);
  },
  module_manual_import_element_contents_success(state, elementContents) {
    state.elementContents = elementContents.records;
    state.elementContentsTableId = elementContents.id;
  },
  module_manual_import_element_contents_update_success(state, record) {
    Object.assign(
      state.elementContents.find((item) => item.id === record.id),
      record
    );
  },
  module_manual_import_element_contents_create_success(state, record) {
    state.elementContents.push(record);
  },

  module_manual_import_elements_success(state, elements) {
    state.elements = elements.records;
  },

  module_manual_import_object_register_success(state, objectRegister) {
    state.objectsRegister = objectRegister.records;
    state.objectsRegisterTableId = objectRegister.id;
  },
  module_manual_import_object_create_success(state, record) {
    state.objectsRegister.push(record);
  },
  module_manual_import_object_update_success(state, record) {
    Object.assign(
      state.objectsRegister.find((item) => item.id === record.id),
      record
    );
  },
  module_manual_import_object_delete_success(state, deletedId) {
    state.objectsRegister = state.objectsRegister.filter(
      (item) => item.id !== deletedId
    );
  },
  module_manual_import_update_element_contents_filter(state, category) {
    state.elementContentsFilter = category;
  },
  module_manual_import_update_objects_filter(state, category) {
    state.objectsFilter = category;
  },
};
const actions = {
  setTemporaryGenerationRecord({ commit, rootGetters }, type) {
    if (type === 'elements') {
      commit('module_manual_import_set_new_element_generation', {
        id: '-1',
        user: rootGetters.authenticatedUser.name,
        datetime: moment().unix(),
        type: type,
      });
    } else if (type === 'objects') {
      commit('module_manual_import_set_new_object_generation', {
        id: '-1',
        user: rootGetters.authenticatedUser.name,
        datetime: moment().unix(),
        type: type,
      });
    }
  },
  async fetchPlacementPhases({ commit, rootGetters }) {
    commit('module_manual_import_request');
    const filters = [
      {
        column: 'project',
        operator: '=',
        values: [rootGetters.project.id],
      },
      {
        column: 'type',
        operator: '=',
        values: ['dhme-placement-phase'],
      },
    ];

    let { tasks } = await queryTasksV2(rootGetters.selectedLicense.id, filters);
    commit('module_manual_import_placement_tasks_success', tasks);
    commit('module_manual_import_request_success');
  },
  async fetchModules({ commit, rootGetters }) {
    commit('module_manual_import_request');
    const { modules } = await queryTablesV2({
      tables: [
        {
          name: 'CFFA_DHME_MODULES',
          project: rootGetters.project.id,
          as: 'modules',
          sortBy: 'assembly_sequence',
          columns: ['*'],
        },
      ],
    });
    commit('module_manual_import_modules_success', modules);
    commit('module_manual_import_request_success');
  },
  async manualImportCreateRecord(
    { commit, state, dispatch, rootGetters },
    recordBody
  ) {
    let record = await createRecord({
      project: { id: rootGetters.project.id },
      table: { id: state.modulesTableId },
      record: recordBody,
    });
    commit('module_manual_import_module_create_success', record);
  },
  async manualImportUpdateRecord(
    { commit, state, rootGetters },
    { recordId, recordBody, isModuleIdUpdate, oldModuleId }
  ) {
    let record = await updateRecord(recordId, {
      project: { id: rootGetters.project.id },
      table: { id: state.modulesTableId },
      record: recordBody,
    });

    if (isModuleIdUpdate) {
      try {
        const { elements, objects } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_DHME_ELEMENTS',
              project: rootGetters.project.id,
              as: 'elements',
              columns: [
                {
                  name: 'module_id',
                  conditions: [
                    {
                      operator: '=',
                      value: oldModuleId,
                    },
                  ],
                },
              ],
            },
            {
              name: 'CFFA_DHME_OBJECTS',
              project: rootGetters.project.id,
              as: 'objects',
              columns: [
                {
                  name: 'module_id',
                  conditions: [
                    {
                      operator: '=',
                      value: oldModuleId,
                    },
                  ],
                },
              ],
            },
          ],
        });

        const updatedElements = elements.records.map((element) => {
          return {
            id: element.id,
            module_id: recordBody.module_id,
          };
        });
        let elementsBook = bookOfObjects('records', updatedElements);
        let elementsCsv = elementsBook.convert('csv', 'string');
        let parsedElementsCsv = btoa(unescape(encodeURIComponent(elementsCsv)));

        await importRecords({
          project: { id: rootGetters.project.id },
          table: { id: elements.id },
          records: parsedElementsCsv,
        });
        const updatedObjects = objects.records.map((object) => {
          return {
            id: object.id,
            module_id: recordBody.module_id,
          };
        });

        let objectsBook = bookOfObjects('records', updatedObjects);
        let objectsCsv = objectsBook.convert('csv', 'string');
        let parsedObjectsCsv = btoa(unescape(encodeURIComponent(objectsCsv)));

        await importRecords({
          project: { id: rootGetters.project.id },
          table: { id: objects.id },
          records: parsedObjectsCsv,
        });

        const sbsRecords = await searchSbsRecords(
          rootGetters.project.id,
          record.build_nr,
          []
        );

        const sbs = sbsRecords.find((s) => s.code === record.build_nr);
        await updateSbsRecord(rootGetters.project.id, sbs.id, {
          label: record.module_id,
        });
        commit(
          'showNotification',
          {
            content: 'Module ID has been updated successfully.',
            color: 'success',
          },
          { root: true }
        );
      } catch (e) {
        commit(
          'showNotification',
          {
            content:
              'Something went wrong while updating the elements and objects for this module ID, please contact an administrator.',
            color: 'error',
          },
          { root: true }
        );
      }
    }

    commit('module_manual_import_module_update_success', record);
  },
  async manualImportDeleteRecord({ commit, rootGetters, state }, recordId) {
    await deleteRecord(recordId, {
      project: {
        id: rootGetters.project.id,
      },
      table: {
        id: state.modulesTableId,
      },
    });
    commit('module_manual_import_module_delete_success', recordId);
  },
  async fetchModuleContents({ commit, rootGetters }) {
    commit('module_manual_import_request');
    const { moduleContents, elementGenerations } = await queryTablesV2({
      tables: [
        {
          name: 'CFFA_DHME_MODULE_CONTENTS',
          project: rootGetters.project.id,
          as: 'moduleContents',
          sortBy: 'module_type',
          columns: [
            {
              name: 'module_type',
            },
            {
              name: 'element_type',
            },
            {
              name: 'element_position',
            },
          ],
        },
        {
          name: 'CFFA_DHME_MANUAL_IMPORT_GENERATIONS',
          project: rootGetters.project.id,
          as: 'elementGenerations',
          sortBy: 'datetime',
          sortByOrder: 'desc',
          columns: [
            {
              name: 'user',
            },
            {
              name: 'datetime',
            },
            {
              name: 'type',
              conditions: [
                {
                  value: 'elements',
                  operator: '=',
                },
              ],
            },
          ],
        },
      ],
    });
    commit('module_manual_import_module_contents_success', moduleContents);
    commit(
      'module_manual_import_element_generations_success',
      elementGenerations
    );
    commit('module_manual_import_request_success');
  },
  async manualImportUpdateModuleContentsRecord(
    { commit, state, rootGetters },
    { recordId, recordBody }
  ) {
    let record = await updateRecord(recordId, {
      project: { id: rootGetters.project.id },
      table: { id: state.moduleContentsTableId },
      record: recordBody,
    });
    commit('module_manual_import_module_contents_update_success', record);
  },
  async manualImportCreateModuleContentsRecord(
    { commit, state, rootGetters },
    { recordBody }
  ) {
    let record = await createRecord({
      project: { id: rootGetters.project.id },
      table: { id: state.moduleContentsTableId },
      record: recordBody,
    });
    commit('module_manual_import_module_contents_create_success', record);
  },
  async fetchElementContents({ commit, rootGetters }) {
    commit('module_manual_import_request');
    const { elementContents, objectGenerations } = await queryTablesV2({
      tables: [
        {
          name: 'CFFA_DHME_ELEMENT_CONTENTS',
          project: rootGetters.project.id,
          as: 'elementContents',
          sortBy: 'element_type',
          columns: [
            {
              name: 'object_type',
            },
            {
              name: 'element_type',
            },
            {
              name: 'number',
            },
          ],
        },
        {
          name: 'CFFA_DHME_MANUAL_IMPORT_GENERATIONS',
          project: rootGetters.project.id,
          as: 'objectGenerations',
          sortBy: 'datetime',
          sortByOrder: 'desc',
          columns: [
            {
              name: 'user',
            },
            {
              name: 'datetime',
            },
            {
              name: 'type',
              conditions: [
                {
                  value: 'objects',
                  operator: '=',
                },
              ],
            },
          ],
        },
      ],
    });
    commit('module_manual_import_element_contents_success', elementContents);
    commit(
      'module_manual_import_object_generations_success',
      objectGenerations
    );
    commit('module_manual_import_request_success');
  },

  async fetchElements({ commit, rootGetters }) {
    commit('module_manual_import_request');
    const { elements } = await queryTablesV2({
      tables: [
        {
          name: 'CFFA_DHME_ELEMENTS',
          project: rootGetters.project.id,
          as: 'elements',
          sortBy: 'element_type',
          columns: [
            {
              name: 'element_type',
            },
            {
              name: 'element_category',
            },
          ],
        },
      ],
    });
    commit('module_manual_import_elements_success', elements);
    commit('module_manual_import_request_success');
  },

  async manualImportCreateElementContentsRecord(
    { commit, state, rootGetters },
    { recordBody }
  ) {
    let record = await createRecord({
      project: { id: rootGetters.project.id },
      table: { id: state.elementContentsTableId },
      record: recordBody,
    });
    commit('module_manual_import_element_contents_create_success', record);
  },
  async manualImportUpdateElementContentsRecord(
    { commit, state, rootGetters },
    { recordId, recordBody }
  ) {
    let record = await updateRecord(recordId, {
      project: { id: rootGetters.project.id },
      table: { id: state.elementContentsTableId },
      record: recordBody,
    });
    commit('module_manual_import_element_contents_update_success', record);
  },
  async fetchObjectRegister({ commit, rootGetters }) {
    commit('module_manual_import_request');
    const { objectRegister } = await queryTablesV2({
      tables: [
        {
          name: 'CFFA_DHME_OBJECTS_REGISTER',
          project: rootGetters.project.id,
          as: 'objectRegister',
          sortBy: 'name',
          columns: [
            {
              name: 'object_type',
            },
            {
              name: 'height',
            },
            {
              name: 'length',
            },
            {
              name: 'width',
            },
            {
              name: 'name',
            },
            {
              name: 'steel_quality',
            },
            {
              name: 'category',
            },
          ],
        },
      ],
    });
    commit('module_manual_import_object_register_success', objectRegister);
    commit('module_manual_import_request_success');
  },
  async manualImportCreateObjectRecord(
    { commit, state, rootGetters },
    { recordBody }
  ) {
    let record = await createRecord({
      project: { id: rootGetters.project.id },
      table: { id: state.objectsRegisterTableId },
      record: recordBody,
    });
    commit('module_manual_import_object_create_success', record);
  },
  async manualImportUpdateObjectRecord(
    { commit, state, rootGetters },
    { recordId, recordBody }
  ) {
    let record = await updateRecord(recordId, {
      project: { id: rootGetters.project.id },
      table: { id: state.objectsRegisterTableId },
      record: recordBody,
    });
    commit('module_manual_import_object_update_success', record);
  },
  async manualImportDeleteObjectRecord({ commit, getters }, recordId) {
    await deleteRecord(recordId, {
      project: { id: state.project.id },
      table: {
        id: state.objectsRegisterTableId,
      },
    });

    commit('module_manual_import_object_delete_success', recordId);
  },
};
const getters = {
  isLoading: (state) => state.requestCounter > 0,
  isBim: (state) => state.modules.findIndex((m) => m.model) !== -1,
  modules: (state) => state.modules,
  modulesTable: (state) => state.modulesTableId,
  placementPhases: (state) => state.placementTasks,

  moduleContents: (state) => state.moduleContents,
  moduleContentsTableId: (state) => state.moduleContentsTableId,

  lastElementGeneration: (state) => state.elementGenerations[0] ?? null,
  lastObjectGeneration: (state) => state.objectGenerations[0] ?? null,

  elements: (state) => state.elements,

  elementContents: (state) => state.elementContents,
  elementContentsTableId: (state) => state.elementContentsTableId,

  objectsRegister: (state) => state.objectsRegister,
  objectsRegisterTableId: (state) => state.objectsRegisterTableId,

  manualImportElementContentsFilter: (state) => state.elementContentsFilter,
  manualImportObjectsFilter: (state) => state.objectsFilter,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
