var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"form-container",attrs:{"fill-height":"","fluid":""}},[_c('div',{staticClass:"ant-glass-background login-card"},[_c('div',{staticClass:"login-header"},[_vm._v("ANT")]),_c('v-form',{ref:"forgotForm",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('ant-input',{attrs:{"label":"email"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"dense":"","filled":"","placeholder":"Email","single-line":"","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]},proxy:true}])}),_c('ant-input',{attrs:{"label":_vm.$t('system.profile.newPW'),"top-margin":"mt-0"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":_vm.passwordFieldType === 'password'
                ? 'mdi-eye-outline'
                : 'mdi-eye-off-outline',"placeholder":_vm.$t('system.profile.enterNewPW'),"rules":[_vm.rules.required, _vm.rules.password],"type":_vm.passwordFieldType,"autofocus":"","dense":"","filled":"","single-line":""},on:{"click:append":function($event){_vm.passwordFieldType === 'password'
                ? (_vm.passwordFieldType = 'text')
                : (_vm.passwordFieldType = 'password')}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]},proxy:true}])}),_c('ant-input',{attrs:{"label":_vm.$t('system.profile.repeatNewPW'),"top-margin":"mt-0"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":_vm.passwordFieldType === 'password'
                ? 'mdi-eye-outline'
                : 'mdi-eye-off-outline',"placeholder":_vm.$t('system.profile.enterNewPW'),"rules":[_vm.rules.required, _vm.passwordConfirmationRule],"type":_vm.passwordFieldType,"dense":"","filled":"","single-line":""},on:{"click:append":function($event){_vm.passwordFieldType === 'password'
                ? (_vm.passwordFieldType = 'text')
                : (_vm.passwordFieldType = 'password')}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]},proxy:true}])}),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"d-block flex-grow-1",attrs:{"color":"primary","elevation":"0","small":"","tile":"","type":"submit"}},[_vm._v(" RESET PASSWORD ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"href":"https://docs.antcde.io/","icon":"","target":"_blank"}},on),[_c('v-icon',{staticClass:"ant-icon"},[_vm._v(" mdi-help-circle-outline")])],1)]}}])},[_c('span',[_vm._v("ANT Documentation")])])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }