import httpClient from '../httpClient';

const getTableRelations = (projectId, tableId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/relations`, {
        params: {
          'filter[table]': tableId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createTableRelation = (projectId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/project/${projectId}/relations`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteTableRelation = (projectId, relationId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/project/${projectId}/relations/${relationId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { getTableRelations, createTableRelation, deleteTableRelation };
