import httpClient from '../httpClient';

const getJobs = (projectId, filters) => {
  let params = new URLSearchParams();
  Object.keys(filters).forEach((key, index) => {
    if (filters[key] !== undefined) {
      params.append(`filter[${key}]`, filters[key]);
    }
  });

  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/jobs`, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getJob = (projectId, jobId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/job/${jobId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const assignJob = (projectId, jobId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/project/${projectId}/job/${jobId}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getUsersWithJobRole = (projectId, $jobId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/job/${$jobId}/users`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getExecutableNodeByJob = (projectId, jobId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/job/${jobId}/node`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const finishJob = (projectId, jobId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/project/${projectId}/job/${jobId}/finish`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getJobByTask = (projectId, taskId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/task/${taskId}/job`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export {
  getJobs,
  getJob,
  assignJob,
  getUsersWithJobRole,
  getExecutableNodeByJob,
  finishJob,
  getJobByTask,
};
