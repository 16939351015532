import { getTables } from '@/services/api/table.api';
import { waitForVuex } from '@/utils/vuex';

const state = {
  projectTables: [],
  selectedProjectTable: undefined,
  tablesSidebarShown: true,
};
const mutations = {
  project_tables_success(state, tables) {
    state.projectTables = tables;
  },
  set_project_table(state, table) {
    state.selectedProjectTable = table;
  },
  create_project_table_success(state, table) {
    state.projectTables.push(table);
  },
  update_project_table_success(state, table) {
    state.selectedProjectTable = Object.assign({}, table);
    Object.assign(
      state.projectTables.find((projectTable) => projectTable.id === table.id),
      table
    );
  },
  delete_project_table_success(state, tableId) {
    state.selectedProjectTable = undefined;
    state.projectTables = [
      ...state.projectTables.filter((table) => table.id !== tableId),
    ];
  },
  toggle_tables_sidebar(state) {
    state.tablesSidebarShown = !state.tablesSidebarShown;
  },
  open_tables_sidebar(state) {
    state.tablesSidebarShown = true;
  },
  close_tables_sidebar(state) {
    state.tablesSidebarShown = false;
  },
};
const actions = {
  fetchProjectTables: waitForVuex(
    'project.tables.get',
    async ({ commit }, projectId) => {
      try {
        const tables = await getTables(projectId);
        if (tables) {
          commit('project_tables_success', tables);
        }
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
};
const getters = {
  projectTables: (state) => state.projectTables,
  hasConfigureTables: (state) =>
    state.projectTables.some((t) => t.name.startsWith('CFFA_')),
  selectedProjectTable: (state) => state.selectedProjectTable,
  tablesSidebarShown: (state) => state.tablesSidebarShown,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
