import {
  addModuleToLicense,
  deleteModuleFromLicense,
  getModulesInLicense,
  updateModule,
} from '@/services/api/module.api';
import { waitForVuex } from '@/utils/vuex';

const state = {
  modules: [],
  licenseModules: [],
};

const mutations = {
  set_modules_success(state, modules) {
    state.modules = modules;
  },
  set_license_modules(state, modules) {
    state.licenseModules = modules;
  },
  modules_update_success(state, updatedModule) {
    Object.assign(
      state.modules.find((module) => module.id === updatedModule.id),
      updatedModule
    );
  },
};

const actions = {
  getModulesInLicense: waitForVuex(
    'modules.in.license.get',
    async ({ commit }, licenseId) => {
      try {
        const { modules, modulesLicense } =
          await getModulesInLicense(licenseId);
        commit('set_modules_success', modules);
        commit('set_license_modules', Object.keys(modulesLicense));
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
  addModuleToLicense: waitForVuex(
    'modules.to.license.add',
    async ({ commit }, { moduleId, licenseId }) => {
      try {
        const response = await addModuleToLicense({ moduleId, licenseId });
        commit('showNotification', {
          content: `${response.message}`,
          color: 'success',
        });
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
  deleteModuleFromLicense: waitForVuex(
    'modules.from.license.delete',
    async ({ commit }, { moduleId, licenseId }) => {
      try {
        const response = await deleteModuleFromLicense({ moduleId, licenseId });
        commit('showNotification', {
          content: `${response.message}`,
          color: 'success',
        });
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
  updateModule: waitForVuex(
    'modules.module.update',
    async ({ commit }, { moduleId, body }) => {
      try {
        const updatedModule = await updateModule(moduleId, body);
        commit('modules_update_success', updatedModule);
        commit('showNotification', {
          content: `Successfully updated module ${updatedModule.name}`,
          color: 'success',
        });
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
};

const getters = {
  modules: (state) => state.modules,
  licenseModules: (state) => state.licenseModules,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
