import AutodeskService from '@/services/forge/autodesk';
import { fetchHubProjects } from '@/services/forge/autodesk-construction-cloud';
import { createRecord, updateRecord } from '@/services/api/record.api';

import { queryTablesV2 } from '@/services/api/v2/connect_v2';

const state = {
  initialized: false,

  clients: null,
  clientsTable: null,

  models: [],
  modelsTable: null,

  mapping: [],
  mappingTable: null,

  modulesTable: null,
  nonModulesTable: null,
  elementsTable: null,
  objectsTable: null,

  selectedModel: null,

  autodeskToken: '',
  modelImportModelObjectsStatus: '',
  modelImportModelObjects: [],

  accHubs: [],
  accHubProjects: [],
  accProjectTopFolders: [],

  modelSbsTreeStatus: '',
};

const mutations = {
  set_module_data(
    state,
    { clients, models, mapping, modules, nonModules, elements, objects }
  ) {
    state.clients = clients.records;
    state.clientsTable = clients.id;
    state.models = models.records;
    state.modelsTable = models.id;
    state.mapping = mapping.records;
    state.mappingTable = mapping.id;
    state.modulesTable = modules.id;
    state.nonModulesTable = nonModules.id;
    state.elementsTable = elements.id;
    state.objectsTable = objects.id;
    state.initialized = true;
  },
  set_autodesk_authentication_token(state, token) {
    state.autodeskToken = token;
  },
  module_model_import_objects_request(state) {
    state.modelImportModelObjectsStatus = 'loading';
  },
  module_model_import_objects_success(state, properties) {
    state.modelImportModelObjects = properties;
    state.modelImportModelObjectsStatus = 'success';
  },
  module_model_import_acc_hub_projects_success(state, projects) {
    state.accHubProjects = projects;
  },
  module_model_import_add_model_success(state, record) {
    state.models.push(record);
  },
  module_model_import_mapping_create_success(state, record) {
    state.mapping.push(record);
  },
  module_model_import_mapping_update_success(state, updatedRecord) {
    Object.assign(
      state.mapping.find((record) => record.id === updatedRecord.id),
      updatedRecord
    );
  },
  module_model_import_model_update_success(state, updatedRecord) {
    Object.assign(
      state.models.find((record) => record.id === updatedRecord.id)
    );
    if (state.selectedModel.id === updatedRecord.id) {
      state.selectedModel = updatedRecord;
    }
  },
  module_model_import_set_model(state, model) {
    state.selectedModel = model;
  },
};

const actions = {
  async loadModelImportData({ commit, getters }) {
    const { clients, models, mapping, modules, nonModules, elements, objects } =
      await queryTablesV2({
        tables: [
          {
            name: 'CFFA_FORGE_CLIENT',
            project: getters.project.id,
            as: 'clients',
            columns: ['*'],
          },
          {
            name: 'CFFA_FORGE_MODELS',
            project: getters.project.id,
            as: 'models',
            columns: ['*'],
          },
          {
            name: 'CFFA_DHME_MODEL_MAPPING',
            project: getters.project.id,
            as: 'mapping',
            columns: ['*'],
          },
          {
            name: 'CFFA_DHME_MODULES',
            project: getters.project.id,
            as: 'modules',
          },
          {
            name: 'CFFA_DHME_NON_MODULES',
            project: getters.project.id,
            as: 'nonModules',
          },
          {
            name: 'CFFA_DHME_ELEMENTS',
            project: getters.project.id,
            as: 'elements',
          },
          {
            name: 'CFFA_DHME_OBJECTS',
            project: getters.project.id,
            as: 'objects',
          },
        ],
      });

    commit('set_module_data', {
      clients,
      models,
      mapping,
      modules,
      nonModules,
      elements,
      objects,
    });
  },
  async fetchModuleImportModelModelProperties({ commit, getters }) {
    commit('module_model_import_objects_request');
    const objects = await AutodeskService.getModelProperties(
      getters.modelImportSelectedModel.urn,
      getters.modelImportSelectedModel.from_acc
        ? getters.accAccessToken
        : getters.modelImportAutodeskAccessToken,
      getters.modelImportModuleClient.server_region
    );
    commit(
      'module_model_import_objects_success',
      objects.filter((obj) =>
        Object.keys(obj.properties).some((k) =>
          k.startsWith(getters.modelImportSelectedModel.property_directory)
        )
      )
    );
  },
  async fetchAccHubProjects({ commit, getters }) {
    const projects = await fetchHubProjects(
      getters.accAccessToken,
      getters.modelImportModuleClient.acc_hub
    );
    commit('module_model_import_acc_hub_projects_success', projects);
  },
  async addModelToProject({ commit, getters }, record) {
    // add to project
    let modelRecord = await createRecord({
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.modelImportModuleModelsTable,
      },
      record,
    });
    commit('module_model_import_add_model_success', modelRecord);

    let mappingRecord = await createRecord({
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.modelImportModuleModelMappingTable,
      },
      record: {
        model: modelRecord.id,
      },
    });
    commit('module_model_import_mapping_create_success', mappingRecord);
  },
  modelImportUpdateMapping({ commit, getters }, record) {
    return updateRecord(getters.modelImportModuleModelMapping.id, {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.modelImportModuleModelMappingTable,
      },
      record,
    })
      .then((record) => {
        commit('module_model_import_mapping_update_success', record);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  updateModelPropertyDirectory(
    { commit, getters, dispatch },
    { recordId, propertyDirectory }
  ) {
    return updateRecord(recordId, {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.modelImportModuleModelsTable,
      },
      record: {
        property_directory: propertyDirectory,
      },
    })
      .then((record) => {
        commit('module_model_import_model_update_success', record);
        dispatch('fetchModuleImportModelModelProperties');
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
};

const getters = {
  modelImportModuleInitialized: (state) => state.initialized,
  modelImportModuleModels: (state) => state.models.filter((m) => m.from_acc),
  modelImportModuleModelsTable: (state) => state.modelsTable,
  modelImportModuleClient: (state) => state.clients[0],
  modelImportModuleModelMapping: (state) =>
    state.mapping.find((m) => m.model === state.selectedModel.id) || {},
  modelImportModuleModelMappingTable: (state) => state.mappingTable,
  modelImportAutodeskAccessToken: (state) => state.autodeskToken,
  modelImportModelObjects: (state) => state.modelImportModelObjects,
  modelImportModelObjectsStatus: (state) => state.modelImportModelObjectsStatus,
  modelImportModelAccHubProjects: (state) => state.accHubProjects,
  modelImportModelAccProjectTopFolders: (state) => state.accProjectTopFolders,
  modelImportSelectedModel: (state) => state.selectedModel,

  modelImportModuleObjectsTable: (state) => state.objectsTable,
  modelImportModuleElementsTable: (state) => state.elementsTable,
  modelImportModuleModulesTable: (state) => state.modulesTable,
  modelImportModuleNonModulesTable: (state) => state.nonModulesTable,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
